.Manager-Dashboard {
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    &-time {
      color: #2e384d;
      font-size: 20px;
      font-weight: 300;
      margin: 25px 0px 0px 10px;
    }

    &-action {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > * {
        margin-left: 10px !important;
      }
    }
  }

  .Chart__body {
    margin-left: -15px;
  }

  &__agents-performance {
    .Card__head {
    }

    .Card__body {
      max-height: 460px;
      height: auto;
      overflow-x: hidden;
      overflow-y: auto;
    }

    table {
      width: calc(100%);
      display: table;
      box-sizing: border-box;
      border-collapse: separate;

      thead {
        display: table-header-group;
        table-layout: fixed;
        width: 100%;
        position: sticky;
        background-color: white;
        top: 0;
        z-index: 2;

        th {
          padding: 2px 12px;
          height: 45px;
          position: sticky;
          align-items: center;
          top: 0;
          background-color: white;
          z-index: 2;
        }
      }

      tbody {
        display: table-row-group;
        overflow: auto;

        tr {
          display: table-row;
          width: 100%;
          table-layout: fixed;

          &:last-child {
            border-bottom: 1px solid transparent;
          }
        }
      }

      th {
        @include tableHeader();
        border-bottom: 2px solid #dbe5f3;
        border-collapse: separate;

        &:last-child {
          border-right: 1px solid transparent;
        }
      }

      td {        
        @include tableColumn();
        font-family: 'MaisonNeue', sans-serif;
        text-align: left;
        border-bottom: 1px solid transparent !important;
      }

      .Ratio {
        margin: 0 -12px;
      }
    }

    &-header-column {
      @include itemsCenter();
      justify-content: flex-start;
    }
  }

  &__chart {
    .Card__body {
      height: 290px;
    }
  }

  &__upcoming-calls {
    .Card__body {
      height: 290px;
    }

    &-item {
      border-bottom: 1px solid #dcddde;
      padding: 4px 16px;

      &-time {
        @include itemsCenter();
        justify-content: flex-start;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 500;
        padding: 4px 0px;

        img {
          margin-right: 10px;
        }
      }

      span {
        display: block;
      }

      &-text {
        color: #333333;
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        padding: 4px 0px;
      }

      &-name {
        color: #808080;
        font-size: 13px;
        line-height: 15px;
        padding: 4px 0px;
      }
    }
  }

  &__call-count {
    .Card__body {
      height: 290px;
      @include itemsCenter();
    }

    &-ratio {
      width: 230px;
    }
  }
}

.DashboardHeader {
  display: flex;
  align-items: center;
  flex-direction: row;

  .rightContents {
    display: flex;
    margin-left: auto;
  }
}

/////////////////////
// Components
/////////////////////
.Ratio {
  height: 20px;
  display: block;

  &__bar {
    &Text {
      color: #1b295b;
      font-size: 12px;
      margin-left: 5px;
    }
    border-radius: 0 3px 3px 0;
    background-color: #e5f0fd;
    display: flex;
    flex-direction: row;
    height: 20px;
    align-items: center;
    transition: width 0.3s ease;
    max-width: 95%;
    min-width: 0%;
  }
}

.DetailedDashboard {
  &__dateDivider {
    justify-content: center;
    margin-left: 200px;
    width: 216.5px;
    border-right: 1px solid #d1d2d4;
    padding: 10px;
  }
}
