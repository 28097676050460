.DealsRollupTabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 3;
    margin: 0px 20px;
    margin-top: 12px;
    border: 1px solid #e5e7eb;
    border-bottom: none;
    border-radius: 5px 5px 0px 0px;
    min-height: 50px;
    background-color: rgba(239, 246, 255, 0.5);

    &__activeTile {
        position: relative;
        background-color: #fff !important;
        border-radius: 5px;
        box-shadow: 6px 0px 20px 0px rgba(0,0,0,0.1);
        &::before {
            position: absolute;
            height: 4px;
            width: 100%;
            left: 0%;
            background-color: #2680EB;
            border-radius: 0px 0px 5px 5px;
            transform: rotate(180deg);
            content: "";
            top: 0px;
        }

        &-label {
            color: #2680EB !important;
        }
    }

    &__tile {
        display: flex;
        flex-direction: row;
        padding: 4px 4px 4px 16px;
        padding-top: 12px;
        min-height: 50px;
        justify-content: flex-start;
        align-items: flex-start;
        border-right: 1px solid #e5e7eb;
        cursor: pointer;
        @include scaled2("min-width", 200px);
        position: relative;

        &:hover {
            .DealsRollupTabs__tileMenu {
                visibility: visible;
            }
        }

        &Menu {
            display: flex;
            visibility: hidden;
            margin-left: auto;
            margin-top: -4px;
            margin-right: 8px;
        }

        &-label {
            font-weight: 500;
            color: #6b778c;
            flex-wrap: nowrap;
            white-space: nowrap;
            @include scaled2("font-size", 14px);
            @include scaled2("line-height", 17px);
            margin-bottom: 4px;
            overflow: hidden;
            max-width: 100px;
            text-overflow: ellipsis;
            margin-right: 4px;
        }

        &-number {
            font-weight: 500;
            color: #6b778c;
            flex-wrap: nowrap;
            white-space: nowrap;
            @include scaled2("font-size", 14px);
            @include scaled2("line-height", 17px);
            margin-bottom: 4px;
              
        }

        &-value {
            font-weight: 600;
            color: #111827;
            @include scaled2("font-size", 14px);
            @include scaled2("line-height", 17px);
        }

        &-plusSign {
            font-weight: 500;
            color: #6b778c;
            flex-wrap: nowrap;
            white-space: nowrap;
            @include scaled2("font-size", 24px);
            @include scaled2("line-height", 24px);
            margin-bottom: 2px;
            margin-right: 8px;
        }

        &-addNew {
            font-weight: 600;
            color: #6b7280;
            @include scaled2("font-size", 16px);
            @include scaled2("line-height", 19px);
        }

        &-warning {
            &Div {
                background: #fef2f2;
                border-radius: 4px;
                margin-left: 8px;
                margin-top: 2px;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 2px 4px;
            }

            &Icon {
                @include scaled2("height", 12px);
                @include scaled2("width", 12px);
                margin-right: 4px;
            }

            &Num {
                font-weight: 600;
                @include scaled2("font-size", 12px);
                @include scaled2("line-height", 15px);
                color: #dc2626;
            }
        }
    }
}
