.Waveform {
  @include itemsCenter();
  width: 100%;
  cursor: pointer;

  &__progress {
    width: 100%;
    height: 30px;
    position: relative;

    &-spectrum {
      position: absolute;
      bottom: calc(50% + 10px);
      height: 7px;
      width: 100%;
      background-color: $secondary;

      &-opportunity {
        position: absolute;
        background-color: #f2752e;
        top: 0%;
      }

      &-agent {
        position: absolute;
        background-color: #0058d0;
        top: 0%;
      }

      &-waveform {
        height: 7px;
        width: 100px;
        display: inline-flex;
      }
    }

    &__CardsShown {
      position: absolute;
      bottom: -10px;
      width: calc(90vw);
      left: calc(-15vw - 20px);
      z-index: 10;

      &__cardIconDiv {
        position: absolute;

        .cardDetails {
          position: absolute;
          z-index: 2147483638;
          padding: 5px;
          border: 1px solid #e1e8ff;
          border-radius: 4px;
          background-color: #ffffff;
          height: 60px;
          width: 200px;
          box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
          top: calc(-60px);
          left: -100px;
        }

        .battlecardIcon {
          border: 1px solid #e1e8ff;
          color: $primary;
          background-color: $primary;
          color: $primary;
          line-height: 10px;
          font-size: 10px;
          height: 10px;
          cursor: pointer;
        }

        .behaviourCardIcon {
          border: 1px solid #e1e8ff;
          color: red;
          background-color: red;
          line-height: 10px;
          font-size: 10px;
          height: 10px;
          cursor: pointer;
        }
      }
    }

    &-background {
      @include absoluteCenter("");
      width: 100%;
      height: 6.67px;
      border-radius: 3px;
      background-color: #d4d3d3;
    }

    &-cont {
      width: 100%;
      height: 100%;
      @include absoluteCenter("y");

      &-bar {
        width: 100%;
        height: 6.67px;
        border-radius: 3px;
        background-color: #5b6ddc;
        @include absoluteCenter("y");
      }
    }

    &-handle {
      margin-left: -4px;
      height: 10px;
      width: 10px;
      border: 2px solid #ffffff;
      border-radius: 7.5px;
      background-color: #5b6ddc;
      box-shadow: 0 2px 5px 0 rgba(46, 91, 255, 0.4);
      @include absoluteCenter("y");
      transition: opacity 150ms;
      opacity: 0;
    }

    &:hover &-handle {
      opacity: 1;
    }
  }

  &__playing-time {
    color: #2e384d;
    font-size: 12px;
    font-weight: 500;
    width: 40px;
  }
}
