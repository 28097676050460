.KeywordsTracking {
	&__container {
		margin-top: 10px;
		display: flex;
		padding-bottom: 10px;
		align-items: center;
		justify-content: space-between;
	}

	&__chipContainer {
		margin: 5px;
		align-items: center;
		margin-left: 20px;
		height: auto;
		display: inline-block;
		flex-wrap: wrap;
	}

	&__chip {
		margin: 5px;
		display: inline-block;
	}

	&__chip &__subSection {
		font-size: 14px;
		margin-left: 20px;
		@include primaryColor();
	}

	&__subSectionTitle {
		font-size: 15px;
		font-weight: 450;
		@include primaryColor();
		margin-left: 20px;
	}

	&__info {
		height: 12px;
		width: 12px;
		margin-left: 2px;
		cursor: pointer;
	}

	&__subSectionContainer {
		margin-top: 20px !important;
	}

	&__categoryName {
		font-weight: 400;
		font-size: 16px;
		letter-spacing: 1.2px;
		line-height: 22px;
		text-transform: uppercase;

		&__appliesTo {
			font-size: 13px;
			font-weight: 400;
		}
	}

	&__addCategoryButton {
		margin-right: 20px;
	}

	&__addCategory {
		font-size: 20px;
		font-weight: bold;
		color: black;
		width: 55vw;
		letter-spacing: 0.02em;
		margin-left: 20px !important;
	}

	&__addPhrase {
		width: 300px;
		margin-left: 10px !important;
	}

	&__categoryNameContainer {
		flex-direction: row;
		display: flex;
		align-items: flex-start;
		padding-left: 20px;
		margin: 15px 0px;
	}

	&__deleteContainer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	&__addCategoryButton {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	&__delete {
		font-size: 20px;
	}

	&__Card {
		margin-top: 10px;
		width: 98%;
		margin-left: 5px;
		padding-bottom: 10px;
	}

	&__anime {
		transition: opacity 2s;
	}
}