.Slider {
	&__container {
		width: 60%;
		margin-left: 10px;
	}

	&__labelContainer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
}

.NumberRange {
	&__container {
		display: flex;
		position: relative;
		flex-direction: row;
	}

	&__input {
		width: 100px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&__toIcon {
		font-size: 30px;
		font-weight: bold;
		margin: 0px 10px;
	}
}
