.EditRollupModal {
    &__container {
        max-height: 85vh;
        width: 65vw;
        max-width: 600px;
        display: flex;
        padding: 10px 20px;
        flex-direction: column;
        margin: 25vh auto;
        background-color: white;
        border-radius: 4px;
        border: 0px solid white !important;
        outline: none !important;
        animation-name: appear;
        animation-duration: 1s;

        &:focus {
            outline: none !important;
            border: 0px solid white !important;
        }
    }

    &__filterField {
        display: flex;
        margin-right: 8px;
    }

    &__filterRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        width: fit-content;
        padding: 2px 0px;
        margin: 1px 5px 1px 0px;
    }

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0px 10px 10px 10px;

        &Title {
            font-weight: 600;
            @include scaled2("font-size", 24px);
            @include scaled2("line-height", 27px);
            color: #2d3958;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        margin: 10px 10px;
    }

    &__label {
        font-weight: 500;
        @include scaled2("font-size", 14px);
        @include scaled2("line-height", 17px);
        color: #334154;
        margin-bottom: 4px;
    }
}
