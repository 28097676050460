.AgentCalls {
	display: flex;
	flex-direction: column;
	background-color: white;
	padding: 10px;
	height: 100%;
	border: none;

	&__header {
		display:flex;
		flex-direction: row;
		align-items: center;

		&_titleDiv {
			display:flex;
			flex-direction: column;
			margin: 10px 0px;
		}

		.text {
			font-size: 19px;
			font-weight: 700;
			color: $primary;
			margin: 5px;
		}

		.rightDiv {
			display: flex;
			margin-left: auto;
		}
	}

	&__footer {
		display:flex;
		margin-top: auto;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	&__modal {
		position: absolute;
		right:0;
		width: 50%;
		height: 100%;
		z-index: 100000;
		top:0;
		overflow-y: auto;
	}
}