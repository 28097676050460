$loginFormWidth: 400px;

.Login-Page {

  &__content {
    display: flex;
    align-items: center;
    height: 100%;
    width: 50%;
  }

  &__centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__top-centered {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &__right {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }

  &__textField {
    width: 288px;
  }

  &__error {
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.google {
  background-color: #fff;
  border: 0;
  width: 100% !important;
  justify-content: center !important;
}

.LoginForm {
  display: flex;
  flex-direction: column;
  width: $loginFormWidth;
  margin: 10px;

  .inputField {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 7px;
    height: 60px;
  }

  .buttonOptions {
    display: flex;
    flex-direction: column;
    margin: 5px 0px;
    width: 100%;
    align-items: center;
  }

  .googleLogin {
    margin: 5px 0px;
    padding-top: 10px;
  }

  &__disclaimer {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #6b7280;
    width: 80%;
  }

  .specialDivider {
    position: relative;
    text-align: center;
    width: 100%;
    color: #9ca3af;
    font-weight: bold;
    font-size: 12px;
    margin: 7px 0px;

    &::before {
      content: "";
      background-color: #d1d5db;
      height: 1.5px;
      width: 43%;
      top: 6px;
      left: 0px;
      position: absolute;
      margin: 0px 2px;
    }
    &::after {
      content: "";
      position: absolute;
      background-color: #d1d5db;
      height: 1.5px;
      right: 0px;
      top: 6px;
      width: 43%;
      margin: 0px 2px;
    }
  }

  .error {
    margin: 10px 0px;
  }

  .CopyLarge {
    color: #2e384d;
    font-size: 28px;
    font-weight: 300;
  }

  .CopySmall {
    color: #8798ad;
    font-size: 12px;
    margin-bottom: 10px;
  }
}
