.Card {
  padding: 0;

  &__head {
    display: flex;
    min-height: 60px;
    padding: 0 12px;
    align-items: center;
    justify-content: space-between;

    .title {
      @include cardTitle();
    }
  }

  &__body {
    height: auto;
    min-height: 200px;
    overflow: hidden;
    color: #808080;
  }
}

.CardShort {
  padding: 0;
  margin: 5px 0px;

  &__head {
    display: flex;
    min-height: 60px;
    padding: 0 12px;
    align-items: center;
    justify-content: space-between;

    .title {
      @include cardTitle();
    }

  }

  &__body {
    height: auto;
    overflow: hidden;
    color: #808080;
  }

}

.NoCallsWider {
  display: flex;
  justify-content: center;
  padding: 0em 0em;
  align-items: center;

  &__Icon {
    height: 174px;
    width: 136px;
  }
}


.NoCalls {
  display: flex;
  justify-content: center;
  padding: 0em 0em;
  align-items: center;

  &__Icon {
    height: 174px;
    width: 136px;
  }
}

.NoOpps {
  display: flex;
  margin: -2em -2em;
  justify-content: center;
  width: calc(100vw - 60px);
  height: calc(100vh - 60px);
  align-items: center;
  background: URL('app/styles/assets/images/FlameWhite.png');
  background-repeat: repeat-x;
  background-size: calc(100vw - 60px) calc(100vh - 60px);
  overflow: hidden;

  &__Icon {
    height: 85px;
    width: 136px;
  }
}

.WaitingIcon {
  height: 100px;
  width: 100px;
}