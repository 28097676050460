.headerCall {
  max-height: 70px !important;
  min-height: 70px !important;
  display: flex !important;
  align-items: center;

  &__outerDiv {
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  &__titleDiv {
    display: flex;
    flex-direction: row;
  }

  &__title {
    margin-right: 10px;
    font-weight: 500;
    @include scaled("font-size", 18px);
    @include scaled("line-height", 25px);
    line-height: 27px;
    color: #2d3958;
  }

  .BackButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: 40px;
    width: 50px;
    border-radius: 0px 4px 4px 0px;
    background-color: #E5F0FD;
    margin-left: -10px;
    cursor: pointer;

    .label {
      color: $primary;
      font-size: 16px;
      font-weight: 500;
      line-height: 32px;
    }
  }
}

.header {
  min-height: $header_height;
  max-height: $header_height;

  width: 100%;
  background: $white;
  border-bottom: 1px solid #e4e8f0;
  display: flex;
  align-items: center;
  z-index: 11;

  &__link-home {
    height: 100%;
    display: inline-flex;
    padding: 0 24px;
    align-items: center;
  }

  &__right {
    display: flex;
    height: 100%;
    padding: 10px 24px;
    margin-left: auto;
    align-items: center;
  }

  &__middle {
    display: flex;

    height: 100%;
    padding: 10px 24px;
    margin-right: auto;
    margin-left: auto;
  }

  &__left {
    display: flex;
    height: 100%;
    padding: 10px 5px;
    margin-right: auto;
    align-items: center;
  }

  &__logo {
    height: $header_height * 5 / 7;
  }
}

.headerGuest {
  display: flex;
  min-height: 50px;
  max-height: 50px;
  width: 100%;
  background: $white;
  border-bottom: 1px solid #e4e8f0;
  align-items: center;
  padding: 0px 0px;
  z-index: 11;
}

.headerProduct {
  display: flex;
  min-height: $header_height;
  max-height: $header_height;
  width: 100%;
  background: $white;
  border-bottom: 1px solid #e4e8f0;
  align-items: center;
  padding: 0px 0px;
  z-index: 11;

  &__title {
    color: #333333;
    font-size: 16px;
    margin-left: 10px;
    width: 90%;
  }

  &__button {
    margin-left: 8px;
    cursor: pointer;

    &Disabled {
      filter: grayscale(1) opacity(0.4) invert(0.2);
    }

    &Icon {
      @include scaled("height", 2.25rem);
      @include scaled("width", 2.25rem);
      @include scaled("border-radius", 5px);
    }
  }

  &__div {
    margin: 0 10px;
  }

  &__right {
    display: flex;
    height: 100%;
    margin-left: auto;
  }

  &__middle {
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  &__left {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    margin-right: auto;

    &_logo {
      height: 40px;
      width: 40px;
      margin: 0px 10px;
    }
  }

  &__right {
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    display: flex;
  }

  &__copy {
    cursor: pointer;
  }

  &__copyDull {
    color: #2680EB;
    font-weight: 600;
    align-items: center;
    display: inline-flex;

    &:hover {
      color: $primary;
    }
  }

  &__copyColor {
    display: inline-flex;
    color: $primary;
  }
}

.hamburger-png {
  height: 23px;
  width: 25px;
}

.search-png {
  height: 25px;
  width: 26px;
}

.notification-png {
  height: 26px;
  width: 24px;
}

.profile-png {
  height: 42px;
  width: 42px;
  border-radius: 21px;
  background-color: #f2f4f8;
}

.back-png {
  height: 18px;
  width: 25px;
}