.ContactCard {
    &__container {
        height: 100px;
        margin: 2px;
        width: 21vw;
        border-radius: 6px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    }

    &__innerContainer {
        padding: 20px;
        display: grid;
        grid-row-gap: 5px;
        grid-column-gap: 2px;
        align-items: center;
    }

    &__header {
        display: grid;
        grid-template-columns: 70% 30%;
        align-items: center;

        &_title {
            color: #535353;
            font-size: 15px;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &_right {
            color: #818181;
            font-size: 12px;
            font-style: italic;
            font-weight: 500;
            text-align: right;
        }
    }

    &__subHeader {
        color: #8D8D8D;
        font-size: 12px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__highlightedInfo {
        align-content: center;
        align-self: flex-end;
        display: grid;
        border-radius: 3px;
        height: 26px;
        background-color: #FF5964;
        color: #FFFFFF;
        font-size: 13px;
        font-style: italic;
        padding-left: 3px;

        &_text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }


    &__triangleContainer {
        position: relative;
    }

    &__triangle {
        position: absolute;
        width: 30px;
        height: 30px;
        left: 20px;
        background: black;
        transform: rotate(45deg);
        border-radius: 6px;
    }

    &__triangleCover {
        position: absolute;
        height: 30px;
        width: 30px;
        margin: -15px;
        overflow: hidden;
        transform: rotate(45deg);
    }
}