@-webkit-keyframes moving-gradient {
  0% {
    background-position: -250px 0;
  }

  100% {
    background-position: 250px 0;
  }
}

.Loading {
  table {
    width: 100%;

    tr {
      td {
        height: 50px;
        vertical-align: middle;
        padding: 8px;

        span {
          display: block;
        }

        &.td-1 {
          width: 20px;

          span {
            width: 20px;
            height: 20px;
          }
        }

        &.td-2 {
          width: 50px;

          span {
            background-color: rgba(0, 0, 0, .15);
            width: 50px;
            height: 50px;
          }
        }

        &.td-3 {
          width: 50px;
          border-bottom: 0;

          // padding-right: 100px;
          span {
            height: 30px;
            background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
            background-size: 500px 100px;
            animation-name: moving-gradient;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
          }
        }

        &.td-6 {
          width: 100px;
          border-bottom: 0;

          // padding-right: 100px;
          span {
            height: 30px;
            background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
            background-size: 500px 100px;
            animation-name: moving-gradient;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
          }
        }

        &.td-4 {}

        &.td-5 {
          width: 100px;

          span {
            background-color: rgba(0, 0, 0, .15);
            width: 100%;
            height: 30px
          }
        }
      }
    }
  }
}