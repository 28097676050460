.ProfileDropdown {
  position: relative;
  margin-top: 10px;
  min-width: 85px;
  padding: 5px !important;

  &__editIcon {
    position: relative;
    z-index: 5;
  }

  &__profileIcon {
    font-size: 18px;
    height: 40px;
    width: 40px;
    color: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    margin-right: 10px;
    margin-bottom: 5px;
    border-radius: 20px;
    background-color: #e7e7e8;
  }

  &__menuItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 120px;
    margin: -5px;
    height: 40px;
    color: #2e384d;

    &:hover {
      background-color: #e9eeff;
      color: #3038b7;

      .icon {
        filter: invert(0);
      }
    }

    .icon {
      height: 13px;
      width: 13px;
      margin: 10px;
      filter: invert(0.3) brightness(0.6);
    }

    .label {
      font-size: 13px;
    }
  }
}

.ProfileDropdown:after,
.ProfileDropdown:before {
  content: "";
  position: absolute;
  top: -11px;
  left: 58%;
  width: 0;
  height: 0;
  border: 11px solid transparent;
  border-top-color: #e1e8ff;
  border-bottom: 0;
  margin-left: 12px;
  margin-bottom: -11px;
  transform: rotate(180deg);
  z-index: 1;
}

.ProfileDropdown:before {
  top: -16px;
  left: 61%;
  z-index: 2;
  border: 8px solid transparent;
  border-top-color: #fff;
}