.CustomAction {
  @include itemsCenter();
  flex-direction: column;
  border-left: 1px solid rgba(173, 177, 187, 0.4);
  position: relative;
  width: 30px;
  height: 30px;

  .icon {
    height: 20px;
    width: 20px;
  }

  .tooltip {
    position: absolute;
    top: -28px;
    left: -35px;
  }
}

.CustomActions {
  &__container {
    position: relative;
    background-color: #fff;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 30px;
    width: fit-content;
    border: 1px solid rgba(173, 177, 187, 0.4);
    border-radius: 3px;
  }
}

.CustomAction:first-child {
  border-left: 0px solid white;
}
