$gametapes_sidebar_width: 240px;

.GameTapes__survey {
  &Card {
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 10px;
    position: absolute;
    right: 20px;
    top: 100px;
    background: #f4f7ff;
    border-radius: 16px;
    width: calc(20% - 50px);
  }

  &Modal {
    position: relative;
    padding: 15px;
    width: 60vw;
    height: 80vh;
    overflow-y: auto;
    margin-top: 10vh;
    margin-left: 20vw;
    border-radius: 5px;
    border: 0px white solid;
    background-color: #ffffff;

    &:focus {
      outline: none !important;
    }
  }

  &Header {
    display: flex;
    flex-direction: row;
    align-items: center;

    &Icon {
      @include scaled2("height", 40px);
      @include scaled2("width", 40px);
    }

    &Text {
      font-weight: 600;
      @include scaled2("font-size", 16px);
      @include scaled2("line-height", 20px);
      color: #2680EB;
      margin-left: 8px;
    }
  }

  &Body {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    @include scaled2("font-size", 14px);
    @include scaled2("line-height", 20px);
    color: #6b7280;
    margin-top: 8px;
    padding: 0px 4px;
  }

  &Button {
    background: #2680EB;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    color: white;
    margin-top: 8px;
    padding: 4px 0px;
    font-weight: 700;
  }
}

.GameTapes {
  &__container {
    display: flex;
    flex-direction: row;
    margin: -1em -2em;
    height: calc(100vh - 60px);
    background-color: #fff;
  }

  &__sidebar {
    min-width: $gametapes_sidebar_width;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    border-right: 1px solid #e0e6ef;

    &__header {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 8px;
      border-top: 1.2px solid #ccd6e7;
      border-bottom: 1.2px solid #ccd6e7;
      padding: 8px 10px;

      &Icon {
        @include scaled2("height", 20px);
        @include scaled2("width", 20px);
        margin: 0px 4px;
      }

      &Title {
        font-weight: 600;
        @include scaled2("font-size", 20px);
        @include scaled2("line-height", 24px);
        color: #2d3958;
        margin-left: 4px;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      height: calc(100%);
      overflow-y: auto;
      overflow-x: hidden;
    }

    &__tape {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      cursor: pointer;
      padding: 8px 10px;
      position: relative;

      &:hover {
        .GameTapes__sidebar__tapeMenu {
          display: flex;
        }
      }

      &Menu {
        display: none;
        position: absolute;
        right: 10px;

        &Dropdown {
          border: 1px solid #f0f1f2;
          box-sizing: border-box;
          border-radius: 4px;
          padding: 4px 12px 4px 4px;

          >div {
            padding: 4px;
          }

          &Item {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 20px;
            padding: 8px;

            &:hover {
              .GameTapes__sidebar__tapeMenuDropdownItem-Icon {
                filter: grayscale(1) contrast(0) brightness(0);
              }

              .GameTapes__sidebar__tapeMenuDropdownItem-Label {
                filter: grayscale(1) contrast(0) brightness(0);
              }
            }

            &-Icon {
              @include scaled2("height", 16px);
              @include scaled2("width", 16px);
              margin: 0px 8px 0px 0px;
              filter: grayscale(1) invert(0.5);
            }

            &-Label {
              @include scaled2("font-size", 15px);
              @include scaled2("line-height", 24px);
              color: #304156;
              filter: grayscale(1) invert(0.5);
            }
          }
        }

        &Icon {
          @include scaled2("height", 15px);
          @include scaled2("width", 20px);
        }
      }

      &Icon {
        @include scaled2("height", 20px);
        @include scaled2("width", 25px);
        margin: 0px 6px;
        filter: grayscale(0.8);
      }

      &Label {
        font-weight: 500;
        @include scaled2("font-size", 17px);
        @include scaled2("line-height", 19px);
        color: #8d98a9;
        text-transform: capitalize;
      }
    }

    &__tape[data_selected="true"] {
      cursor: pointer;
      background: #f0f3ff !important;

      .GameTapes__sidebar__tapeIcon {
        filter: grayscale(0) !important;
      }

      .GameTapes__sidebar__tapeLabel {
        color: #2680EB !important;
      }
    }
  }

  &__body {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0px 16px;
    padding-right: 20%;
    overflow-y: auto;
    position: relative;

    &__header {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: -webkit-sticky;
      /* Safari */
      position: sticky;
      top: 0;
      padding: 16px 0px;
      z-index: 2;
      background-color: #fff;

      &Right {
        margin-left: auto;
      }

      &Title {
        font-weight: 600;
        @include scaled2("font-size", 26px);
        @include scaled2("line-height", 31px);
        color: #2d3958;
      }
    }

    &__tapeList {
      display: flex;
      flex-direction: column;
    }
  }

  &__emptyState {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    height: 100%;
    width: 100%;

    &Icon {
      height: 200px;
      width: 300px;
      margin-bottom: 20px;
    }

    &Heading {
      @include scaled("font-size", 22px);
      @include scaled("line-height", 25px);
      font-weight: 600;
      margin-bottom: 5px;
    }

    &SubHeading {
      @include scaled("font-size", 14px);
      @include scaled("line-height", 20px);
      margin-bottom: 10px;
    }
  }
}

.CallSnippet {
  &__container {
    position: fixed;
    z-index: 101;
    margin: calc(50vh - 200px) calc(50vw - 250px);
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: -7px 8px 16px rgba(58, 70, 93, 0.1);
    border-radius: 4px;
    height: 400px;
    width: 500px;
    padding: 10px 10px;
    border: 0px solid white !important;
    outline: none !important;
    animation-name: appear;
    animation-duration: 1s;

    &:focus {
      outline: none !important;
      border: 0px solid white !important;
    }
  }

  &__tag {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #f9f8ff;
    border-radius: 10px 0px 0px 0px;
    padding: 8px 8px;

    &Icon {
      @include scaled2("height", 16px);
      @include scaled2("width", 16px);
      margin: 0px 5px;
    }

    &Copy {
      font-weight: 600;
      @include scaled2("font-size", 16px);
      @include scaled2("line-height", 19px);
      text-align: right;
      text-transform: capitalize;
      color: #5f2eea;
    }
  }

  &__callDetails {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;

    &__TitleContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__meetingTitle {
      font-weight: 600;
      @include scaled2("font-size", 22px);
      @include scaled2("line-height", 26px);
      color: #2d3958;
    }

    &__subTitle {
      @include scaled2("font-size", 16px);
      @include scaled2("line-height", 18px);
      color: #8d98a9;
    }

    &__rightContainer {
      display: flex;
      margin-left: auto;
      align-items: center;
      margin-right: 10px;
    }
  }

  &__videoContainer {
    display: flex;
    align-items: center;
    margin: 15px 0px;
    border-radius: 2px;
    min-height: 250px;
    background-color: black;
  }
}

.GameTapeCard {
  &__container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-top: 30px;
    border-radius: 10px;
    position: relative;
    background: #ffffff;
    border: 1px solid #e0e6ef;
    margin: 5px 0px;

    &:hover {
      .GameTapeCard__menu {
        display: flex;
      }
    }
  }

  &__menu {
    position: absolute;
    top: 10px;
    right: 10px;
    display: none;

    &Icon {
      height: 14px;
      width: 14px;
    }
  }

  &__tag {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #f9f8ff;
    border-radius: 10px 0px 0px 0px;
    padding: 8px 8px;

    &Icon {
      @include scaled2("height", 14px);
      @include scaled2("width", 14px);
      margin: 0px 5px;
    }

    &Copy {
      font-weight: 600;
      @include scaled2("font-size", 14px);
      @include scaled2("line-height", 17px);
      text-align: right;
      text-transform: capitalize;
      color: #5f2eea;
    }
  }

  &__callDetails {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__TitleContainer {
      display: flex;
      flex-direction: row;
      margin: 5px 10px;
      align-items: center;
      width: 80%;
    }

    &__meetingTitle {
      font-weight: 600;
      @include scaled2("font-size", 20px);
      @include scaled2("line-height", 24px);
      color: #2d3958;
    }

    &__subTitle {
      @include scaled2("font-size", 14px);
      @include scaled2("line-height", 16px);
      color: #8d98a9;
    }

    &__rightContainer {
      display: flex;
      margin-left: auto;
      align-items: center;
      margin-right: 50px;
    }
  }

  &__note {
    background: #f7faff;
    border-radius: 4px;
    padding: 10px 5px;
    font-weight: 500;
    margin: 5px;
    font-style: italic;
    @include scaled2("font-size", 16px);
    @include scaled2("line-height", 19px);
    color: #304156;
    border-left: 2px solid #2680EB;
  }

  &__addedBy {
    display: flex;
    flex-direction: column;
    margin: 5px 10px;

    &__row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__copy {
      font-weight: 500;
      @include scaled2("font-size", 14px);
      @include scaled2("line-height", 17px);
      color: #8798ad;
      margin-left: 5px;
    }

    &__date {
      font-weight: 500;
      @include scaled2("font-size", 14px);
      @include scaled2("line-height", 17px);
      color: #8798ad;
    }

    &__name {
      font-weight: 600;
      @include scaled2("font-size", 16px);
      @include scaled2("line-height", 19px);
      color: #2d3958;
      margin-left: 5px;
    }

    &__imageAndName {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0px 5px;
    }
  }
}