.modal {
	position: fixed;
	z-index: 100;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(255, 255, 255, 0.5);
	animation-name: appear;
	animation-duration: 1s;

	&__error {
		margin-top: 100px;
		font-size: 24px;
		color: red;
	}

	&__content {
		padding-top: 150px;
		@include itemsCenter();
	}
}
