.KeyboardShortcuts {
    &__trigger {
        display: flex;
        position: relative;
        margin: 0px 20px;
        cursor: pointer;

        &Icon {
            @include scaled("height", 24px);
        }
    }

    &__container {
        position: absolute;
        display: flex;
        width: 270px;
        bottom: 35px;
        left: -30px;
        flex-direction: column;
        background: white;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.33);
        border-radius: 8px;
        padding: 10px 15px;
        z-index: 10;
        animation-name: appear;
        animation-duration: 300ms;
    }

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;

        &Text {
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
            color: #2680EB;
        }

        &Close {
            height: 25px;
            width: 25px;
            margin-left: auto;
            cursor: pointer;
        }
    }

    &__row {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 0px;
        width: 100%;
    }

    &__commandText {
        font-size: 12px;
        line-height: 15px;
        color: #6B7280;
        margin-right: auto;
    }

    &__neuButton {
        background: #F3F4F6;
        border-radius: 4px;
        padding: 5px 10px 5px 5px;
        align-items: center;
        flex-direction: row;
        display: flex;

        &Icon {
            height: 15px;
            width: 15px;
            margin-right: 5px;

            &Text {
                color: #2680EB;
                font-size: 14px;
                margin: 0px 5px;
                font-weight: 700;
            }
        }

        &Label {
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            line-height: 13px;
            color: #2680EB;
        }
    }
}