@-webkit-keyframes slide {
    100% {
        margin-left: 0;
    }
}

@keyframes slide {
    100% {
        margin-left: 0;
    }
}

.Filter {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background: #ffffff;
    border: 1px solid #e1e8ff;
    box-shadow: 0px 10px 20px rgba(46, 91, 255, 0.07);
    border-radius: 2px;
    padding: 10px 20px;
    padding-bottom: 15px;
    width: 100%;
    margin: 5px 0px;

    &__savedFilterButton {
        cursor: pointer;
        height: 36px;
        width: 36px;
        margin: 0px 5px;
    }

    &__header {
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 100%;
        margin: 5px;

        &Text {
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: #304156;
            margin-right: 10px;
        }

        &__clear {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #117dce;
            cursor: pointer;
        }

        &__button {
            display: flex;
            margin: 0px 10px;
            position: relative;
        }

        &__rightContent {
            margin-left: auto;
        }
    }

    &__dropdown {
        display: flex;
        flex-direction: column;
        border: 1px solid #e1e8ff;
        border-radius: 1px;
        background-color: #ffffff;
        box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
        padding: 0px !important;
        max-height: 60vh;
        overflow-y: auto;

        &__item {
            min-width: max-content;
            cursor: pointer;
            padding: 5px 10px !important;
            font-weight: normal;
            font-size: 13px;
            line-height: 24px;
            color: #304156;

            &:hover {
                background-color: $secondary;
            }
        }

        &__filterIcon {
            @include scaled("height", 22px);
            @include scaled("width", 22px);
        }

        &__trigger {
            display: flex;
            align-items: center;
            cursor: pointer;
            border: 1.5px solid #2680EB;
            background-color: #ffffff;
            border-radius: 4px;
            height: 36px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding: 0px 10px;

            &Text {
                @include scaled("font-size", 17px);
                @include scaled("line-height", 16px);
                font-weight: 600;
            }

            &Icon {
                margin-left: 10px;
            }
        }
    }

    &__fieldsContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
    }

    &__field {
        &Container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            margin: 5px 10px;
        }
    }

    &__crmFilterContainer {
        margin: 5px 0px 10px 0px;

        &_inner {
            margin: 5px 0px;
        }
    }

    &__crmFieldRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__applyContainer {
        display: flex;
    }
}

.CategoryHeader {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #304156;
    margin-bottom: 3px;
}

.MoreFieldsPopup {

    &__categoryContainer {
        display: flex;
        flex-direction: column;
        margin: 10px 10px;
    }

    &__fieldsContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__category {
        font-weight: 500;
        @include scaled("font-size", 15px);
        @include scaled("line-height", 17px);
        color: #828d99;
        border-bottom: 1px solid #f0f1f2;
        margin: 5px 0px;
        height: 18px;
        min-width: 250px;
    }

}

.FilterField {
    &__container {
        display: flex;
        height: 36px;
        border: 1px solid #e1e5e9;
        box-sizing: border-box;
        border-radius: 4px;
        position: relative;
        width: inherit;
        background-color: #fff;
    }

    &__valueContainerDropdown {
        &::after {
            content: URL("app/styles/assets/images/icons/filter/Arrow.svg");
            width: 10px;
            color: grey;
            font-size: 14px;
            right: 10px;
            margin-left: 10px;
            margin-bottom: 4px;
        }
    }

    &__valueContainerDropdownSmall {
        &::after {
            content: URL("app/styles/assets/images/icons/filter/Arrow.svg");
            width: 10px;
            color: grey;
            font-size: 14px;
            right: 10px;
            margin-left: 10px;
            margin-top: -3px;
        }
    }

    &__valueContainer {
        display: flex;
        width: inherit;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 6px 10px;
        cursor: pointer;
        position: relative;
        max-width: 40vw;

        &__label {
            text-overflow: ellipsis;
            font-size: 14px;
            line-height: 24px;
            color: #304156;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;

            &Small {
                font-size: 12px;
                line-height: 15px;
            }
        }
    }

    &__removeIcon {
        height: 20px;
        width: 20px;
        position: absolute;
        top: -12px;
        right: -5px;
    }

    &__popup {
        padding: 6px 10px;
        min-width: 150px;
        border: 1px solid #f0f1f2;
        background-color: #fff;
        box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
        box-sizing: border-box;
        border-radius: 4px;
        z-index: 9999;
        display: flex;
        flex-direction: column;
    }
}

.DateFilter {
    display: flex;
    width: 100%;
    flex-direction: column;

    &__menuContainer {
        display: flex;
        flex-direction: column;
        margin: 0px 0px;
        width: 100%;
    }

    &__menuItem {
        display: flex;
        flex-direction: row;
        padding: 5px 10px;
        cursor: pointer;

        &:hover {
            background-color: $secondary;
        }

        &Active {
            background-color: $primary;

            &Text {
                color: white !important;
            }

            &:hover {
                background-color: $primary  !important;
            }
        }

        &Text {
            color: #304156;
            font-size: 13px;
            font-weight: 500;
            margin: 0px 10px;
        }
    }

    &__calendarContainer {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;

        &Left {
            max-width: 250px !important;
            z-index: 100;
            border: 1px solid $secondary;
            border-radius: 4px;
        }

        &Right {
            max-width: 250px !important;
            z-index: 100;
            border: 1px solid $secondary;
            border-radius: 4px;
        }
    }

    &__picker {
        width: "100px";
        border: 1px solid #f0f1f2;
        border-radius: 4px;
        margin: 10px 0px;
    }
}

.Tile {
    &__activeTile {
        background: #2680EB;
        border-radius: 4px;
        padding: 2px 10px;
        cursor: pointer;

        &Label {
            font-weight: 500;
            font-size: 12px;
            line-height: 13px;
            width: fit-content;
            color: #ffffff;
        }
    }

    &__inactiveTile {
        padding: 2px 10px;
        cursor: pointer;

        &Label {
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            color: #304156;
            width: fit-content;
        }
    }
}

.RangeInput {
    display: flex;
    flex-direction: column;

    &__row {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 2px;
        width: max-content;
    }

    &__label {
        padding: 5px;
    }

    &__dropdown {
        display: flex;
        flex-direction: column;
        border: 1px solid #e1e8ff;
        border-radius: 1px;
        background-color: #ffffff;
        box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
        padding: 0px !important;

        &__item {
            min-width: 80px !important;
            cursor: pointer;
            padding: 10px !important;
            font-size: 12px !important;
            display: flex;
            justify-content: space-around;

            &:hover {
                background-color: $secondary;
            }
        }

        &__trigger {
            line-height: 14px;
            border-bottom: 1px black solid;
            cursor: pointer;
            width: 100px;
            padding: 2px 5px;
            margin: 0px 2px;
        }
    }
}