.CrmContextContainer {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 15%;
    background-color: white;
    border-right: 1px solid #e0e6ef;
    margin: 0px;
    height: calc(100vh - 70px);
    min-width: 200px;
    overflow-y: auto;

    &__contact {
        &Container {
            position: relative;
            display: flex;
            flex-direction: column;
            margin-top: 5px;
            margin-bottom: 10px;
            justify-content: center;
        }

        &AllNameContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &Icon {
            @include scaled("height", 25px);
            @include scaled("width", 25px);
            margin-right: 5px;
        }

        &NameContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &ViewAllContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0px 10px;
            min-height: 30px;
        }

        &ViewAll {
            @include scaled("font-size", 14px);
            font-weight: 600;
            @include scaled("line-height", 16px);
            color: #2680EB;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        &Plus {
            font-weight: 500;
            @include scaled("font-size", 11px);
            @include scaled("line-height", 18px);
            color: #828d99;
        }

        &Name {
            font-weight: 600;
            @include scaled("font-size", 12px);
            @include scaled("line-height", 14px);
            color: #2D3958;
        }

        &Label {
            @include scaled("font-size", 13px);
            @include scaled("line-height", 20px);
            color: #828d99;
        }

        &Job {
            @include scaled("font-size", 11px);
            @include scaled("line-height", 13px);
            color: #8798AD;
        }
    }

    &__domRep {
        background: #fff3f8;
        border-radius: 2px;
        border-left: 2px solid #ed2e7e;
        padding: 3px 7px;
        margin-bottom: 8px;
        width: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;

        &Text {
            font-weight: 600;
            @include scaled("font-size", 12px);
            @include scaled("line-height", 13px);
            color: #ed2e7e;
        }
    }

    &__header:first-child {
        border-top: none;
    }

    &__header {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        border-top: 1px solid #e0e6ef;
        @include scaled("padding-left", 25px);

        &-crmLogo {
            height: 20px;
            width: 20px;
        }

        &-title {
            font-weight: 600;
            text-transform: uppercase;
            @include scaled("font-size", 12px);
            @include scaled("line-height", 15px);
            letter-spacing: 1px;
            color: #8798ad;
            margin: 0px 5px;
        }
    }

    &__callDetails {
        &__attendees {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 2px 0px;
            cursor: pointer;
            width: fit-content;

            &More {
                margin: 0px 3px;
                cursor: pointer;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: #2680EB;
                background-color: #f0f3ff;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &__tooltip {
            display: flex;
            flex-direction: column;
            position: absolute;
            max-height: 50vh;
            overflow-y: auto;
            border: 1px solid #e1e8ff;
            border-radius: 4px;
            background-color: #ffffff;
            box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
            top: 100px;
            left: 140px;
            padding: 0px 10px 10px 10px;
            width: fit-content;
            min-width: 150px;
            z-index: 20;

            &Container {
                padding: 5px 0px 10px 0px;
            }

            &Profile {
                margin-right: 15px;
            }

            &Row {
                display: flex;
                align-items: center;
                padding: 10px 10px;
                border-bottom: solid 1px #e0e6ef;
            }

            &Section {
                display: flex;
                flex-direction: column;
            }

            &Name {
                font-weight: 600;
                @include scaled2("font-size", 14px);
                @include scaled2("line-height", 17px);
                color: #2d3958;
            }

            &Email {
                font-weight: 500;
                @include scaled2("font-size", 12px);
                @include scaled2("line-height", 15px);
                color: #8798ad;
            }
        }

        &__metric {
            display: flex;
            flex-direction: column;
            margin: 2px 5px 12px 5px;

            &InfoIcon {
                @include scaled("height", 13px);
                @include scaled("width", 13px);
                margin-left: 5px;
            }

            &Label {
                font-weight: 500;
                @include scaled2("font-size", 12px);
                @include scaled2("line-height", 15px);
                color: #8798ad;
                margin-bottom: 1px;

                &Container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
            }

            &Value {
                font-weight: 600;
                @include scaled2("font-size", 14px);
                @include scaled2("line-height", 17px);
                color: #2d3958;
                margin-left: 0.3px;
            }
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        padding: 0px 15px;
        @include scaled("padding-left", 25px);

        .MuiCircularProgress-indeterminate {
            margin-right: 0 !important;
        }
    }

    &__label {
        color: #8798ad;
        font-size: 12px;
        letter-spacing: 1.12px;
        line-height: 14px;
        margin-top: 10px;
    }

    &__value {
        color: #6c6c6c;
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 2px;
        vertical-align: middle;
        word-wrap: break-word;

        &-tag {
            font-size: 10px;
            color: grey;
            border-radius: 2px;
            padding: 2px;
            margin: 0px 2px;
        }

        &-link {
            font-size: 13px;
            line-height: 20px;
            margin-bottom: 2px;
            word-wrap: break-word;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}