.RichTextEditor {
  svg.fr-svg {
    height: 20px !important;
    width: 20px !important;
    margin: 3px !important;
  }

  .fr-toolbar .fr-command.fr-btn,
  .fr-popup .fr-command.fr-btn,
  .fr-modal .fr-command.fr-btn {
    height: 30px !important;
  }

  .fr-toolbar .fr-more-toolbar.fr-expanded {
    height: 35px !important;
  }

  .fr-view {
    ol {
      list-style-type: decimal;
    }
    ul {
      list-style-type: circle;
    }
    p {
      padding: 5px;
      margin: 0px;
    }

    ul {
      margin: 0px;
      li {
        color: #424141;
        font-size: 12px;
        padding: 10px 10px;
        border-bottom: 1px solid #e8ebf5;
      }
    }

    ol {
      margin: 0px;
      padding: 0px;

      li {
        color: #424141;
        font-size: 12px;
        padding: 10px 10px;
        border-bottom: 0.5px solid #e8ebf5;
      }
    }
  }
}
