.IntegrationCard {
  display: flex;
  flex-direction: column;
  width: 325px;
  margin: 5px 15px;
  background-color: white;
  padding: 5px;

  &__header {
    display: flex;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid #d4dfff;
    margin-bottom: 20px;

    &__title {
      color: #304156;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 0.92px;
      line-height: 14px;
      text-transform: uppercase;
    }
  }

  &__body {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    border: 0px solid #e0e7ff;
    padding: 5px;

    &__iconDiv {
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 60px;
      min-height: 60px;
      max-width: 60px;
      min-width: 60px;
      border-radius: 30px;
      background-color: #f2f4f7;
    }

    &__icon {
      height: 25px;
      width: 25px;
    }
  }

  .titleDiv {
    display: flex;
    flex-direction: column;
    margin: 5px;

    .mainHeader {
      color: #2e384d;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 22px;
    }

    .copy {
      color: #8798ad;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 22px;
    }
  }

  .footer {
    height: 50px;
    display: flex;
    margin-top: auto;
    align-items: center;
    justify-content: center;
  }

   .copyButton{
    height: 100%;
  border: none;
  background: white;
  color: #3150D2;
  font-weight: 900;
  }
   .viewSecret{
    height: 50%;
    margin-top: 8px;
  }

  .DocumentationLink{
    margin-left: auto;
    margin-right: 0;
  }
}
