.EditReminderEmailModal {
    &__container {
        width: 65vw;
        max-width: 900px;
        display: flex;
        flex-direction: column;
        margin: 5vh auto;
        background-color: white;
        border-radius: 4px;
        border: 0px solid white !important;
        outline: none !important;
        animation-name: appear;
        animation-duration: 1s;

        &:focus {
            outline: none !important;
            border: 0px solid white !important;
        }
    }

    &__body {
        display: flex;
        flex-direction: row;
        margin: 10px 10px;
    }

    &__email {
        &Promo {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            padding: 24px 8px;
            border-top: 1.4px solid #e5e7eb;
            border-bottom: 1.4px solid #e5e7eb;
        }
    }

    &__editSection {
        display: flex;
        flex-direction: column;
        width: 40%;
    }
}

.EditReminderEmailModal__body .react-colorful {
    height: 120px;
    width: 100%;
}

.EditReminderEmailModal__body input {
    display: flex;
    width: 90px;
    padding: 6px;
    background: #ffffff;
    border: 1px solid #e0e6ef;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 4px auto;
    outline: none;
    font: inherit;
    text-transform: uppercase;
    text-align: center;
}
