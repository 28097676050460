.CallCard {
    padding: 0px !important;
    margin: 5px;

    &__dull {
        opacity: 0.7;
    }

    &__menuContainer {
        margin-left: auto;
        position: relative;
    }

    &__menu {
        opacity: 1;
        padding: 3px;
    }

    &__timeIcon {
        height: 14px;
        width: 14px;
    }

    &__durationIcon {
        margin-left: 15px;
        height: 14px;
        width: 10px;
    }

    &__container {
        display: flex;
        position: relative;
        flex-direction: column;
        height: 100%;
        align-items: center;
        overflow: hidden;
        min-height: 70px;
        width: 300px;
        overflow: unset;
        padding: 0px !important;
    }

    &__footer {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-top: auto;
        background-color: rgba(225, 232, 255, 0.24);
        width: 100%;
        padding: 0px 10px;
        height: 30px;

        .rightContents {
            display: flex;
            align-items: center;
            margin-left: auto;
        }

        .leftContents {
            display: flex;
            align-items: center;
            margin-right: auto;
        }
    }

    &__timeText {
        color: #2E384D;
        font-size: 12px;
        font-weight: 500;
        padding: 0px 5px;
    }

    &__durationText {
        color: #2E384D;
        font-weight: 500;
        font-size: 12px;
        padding: 0px 5px;
    }

    &__tinyText {
        color: #8798AD;
        font-size: 8px;
        color: #8798AD;
        text-align: left;
    }

    &__title {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 20px 5px;
    }

    &__meetingTitle {
        word-wrap: break-word;
        overflow: auto;
        color: #2E384D;
        font-size: 15px;
        text-align: left;
    }
}