.Form {
	&__ButtonIcon {
		height: 16px;
		margin-right: 10px;
	}

	&__outerContainer {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__label {
		font-size: 12px;
		flex-grow: 1;
		margin-bottom: 2px;
	}

	&__container {
		padding: 20px;
		overflow-y: auto;
		border-radius: 5px;
		background-color: #ffffff;

		&:focus {
			outline: none;
		}
	}

	&__header {
		&__container {
			margin: 10px 0px;
		}

		&__boldText {
			color: #000;
			font-size: 18px;
			font-weight: bold;
		}

		&__text {
			color: #000;
			font-size: 18px;
		}

		&__cancelContainer {
			cursor: pointer;
			display: inline;
			float: right;
		}
	}

	&__helper {
		color: #b4b4b4;
		font-size: 10px;
		margin: 5px 0px;
	}

	&__categoryAndTriggerContainer {
		display: flex;
		flex-direction: row;
		margin: 15px 0px;
	}

	&__triggerContainer {
		display: flex;
		flex-direction: column;
		width: 70%;
		margin-right: 10px;

		&__triggerInput {}
	}

	&__tpContainer {
		display: flex;
		flex-direction: row;

		&__inputRow {
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		&__input {
			display: flex;
			flex-grow: 1;
			flex-direction: column;

			&__warningContainer {
				display: flex;
				float: right;
			}
		}
	}

	&__tpInputContainer {
		display: flex;
		flex-direction: column;
		margin-right: 10px;
		width: 100%;

		&__triggerInput {}
	}

	&__horCon {
		display: flex;
		flex-direction: row;
	}

	&__verCon {
		display: flex;
		flex-direction: column;
	}

	&__tpAddContainer {
		width: 25%;
		display: flex;
		align-items: center;
	}

	&__footerContainer {
		margin: 20px 0px 0px 0px;
		display: flex;
		align-items: flex-end;

		.leftButton {
			justify-content: flex-start;
		}

		.rightButton {
			justify-content: flex-end;
			margin-left: auto;
		}
	}

	&__tp {
		display: flex;
		padding: 7px 10px;
		align-items: center;
		justify-content: flex-start;
		border-bottom: 0.3px solid #dfe3e9;
		cursor: pointer;
		transition: height 200ms;

		&__text {
			color: #7f8fa4;
			font-size: 12px;
			font-weight: 500;
			flex-grow: 1;
		}

		&__controls {
			display: flex;
			justify-self: flex-end;
		}
	}

	&__inputContainer {
		height: 36px;
		border: 1px solid #dfe3e9;
		border-radius: 4px;
		padding-left: 10px;
	}

	&__smallerInputContainer {
		height: 30px;
		color: #304156;
		border: 1px solid #dfe3e9;
		border-radius: 4px;
		padding-left: 10px;
	}

	&__largeInputContainer {
		min-height: 60px;
		border: 1px solid #dfe3e9;
		border-radius: 4px;
		padding-left: 10px;
		overflow-y: auto;
	}

	&__errorInputContainer {
		height: 36px;
		border: 1px solid #ee4266;
		border-radius: 4px;
		padding-left: 10px;
	}

	&__errorMessage {
		color: #ee4266;
		font-size: 12px;
	}

	&__disabledInputContainer {
		height: 36px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 4px;
		padding-left: 10px;
		background-color: #f3f5f7;
	}

	&__input {
		width: 90%;
		height: 100%;
		align-items: center !important;
	}

	&__warning {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		width: 100px;
		height: 13px;
		margin-top: -13px;
		text-align: right;
		flex-grow: 1;
		color: #ee4266;
		font-size: 10px;

		&-icon {
			margin-right: 2px;
			height: 9px;
			width: 9px;
		}
	}

	&__info {
		display: inline-block;
		width: 100px;
		height: 13px;
		margin-top: -13px;
		text-align: right;
		flex-grow: 1;
		color: #868686;
		font-size: 10px;
	}
}