.TeamDashboard {
  &__LeaderboardHeader {
    padding: 20px;
    padding-bottom: 8px;
    display: flex;
    align-items: center;

    &Title {
      @include scaled("font-size", 18px);
      @include scaled("line-height", 20px);
    }
  }

  &__sectionHeader {
    display: flex;
    margin: 10px 0px;

    &__text {
      font-weight: 600;
      @include scaled2("font-size", 26px);
      @include scaled2("line-height", 32px);
      color: #304156;
    }
  }

  &__tabHeading {
    @include scaled("font-size", 22px);
    @include scaled("line-height", 28px);
    font-weight: 600;
    color: #304156;
  }

  &__agentsTable {
    &__agentNameIconColumn {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__agentNameColumn {
      display: flex;
      flex-direction: column;
      margin-left: 8px;
    }

    &__value {
      font-weight: normal;
      @include scaled("font-size", 15px);
      @include scaled("line-height", 19px);
      color: #191c23;
      margin-left: 15px;
    }

    &__name {
      @include scaled("font-size", 14px);
      @include scaled("line-height", 17px);
      font-weight: 600;
      color: #191c23;
    }

    &__email {
      @include scaled("font-size", 13px);
      @include scaled("line-height", 16px);
      color: #2680EB;
    }
  }

  &__Trackers {
    &Heading {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      @include scaled("font-size", 18px);
      @include scaled("line-height", 20px);
      z-index: 2;
      text-transform: capitalize;
    }

    &Avg {
      font-size: 12px;
      float: right;
      margin-left: auto;

      &Line {
        position: absolute;
        height: calc(100% - 25px);
        width: 0px;
        border-radius: 5px;
        border-left: 1.5px dashed #a4b0dd;
        opacity: 1;
        z-index: 0;
        top: 25px;

        &Value {
          position: absolute;
          color: $primary;
          font-weight: 600;
          font-size: 11px;
          top: calc(100%);
        }
      }
    }
  }

  &__LeaderboardEntry {
    display: flex;
    margin: 13px 5px;

    &Name {
      flex-grow: 1;
      margin: 0 10px;
    }

    &Calls {
      color: grey;
      font-size: 12px;
    }
  }

  &__LeaderboardContent {
    max-height: calc(100vh - 350px);
    overflow-y: auto;
    padding: 5px 20px;
  }

  &__CarouselDot {
    border: none;
    background: none;
    color: #e3e8eb;
    font-size: 20px;
  }

  &__CarouselDot.carousel__dot--selected {
    color: #fd5181;
  }

  &__LinearChartItem {
    height: 8px;
  }

  &__CompetitorLegend {
    display: flex;
    flex-direction: row;
    flex: 1;
    flex-wrap: wrap;

    &-chart {
      margin-top: 20px;
      margin-left: -30px;
    }

    &-item {
      display: flex;
      flex-direction: row;
      margin: 10px 0px;
      width: 25%;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    &-color {
      height: 9px;
      width: 9px;
      border-radius: 50%;
      margin-right: 8px;
    }

    &-heading {
      @include scaled("font-size", 20px);
      @include scaled("line-height", 24px);
      margin-bottom: 10px;
      font-weight: 600;
    }

    &-name {
      @include scaled("font-size", 14px);
      @include scaled("line-height", 16px);
      font-weight: 600;
      text-transform: capitalize;
    }

    &-value {
      @include scaled("font-size", 14px);
      @include scaled("line-height", 16px);
      color: rgb(172, 169, 169);
    }
  }
}

div.carousel {
  position: relative;
}

button.carousel__back-button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border: none;
  background: none;
}

button.carousel__next-button {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  border: none;
  background: none;
}

.CallDispositionTooltip {
  display: flex;
  flex-direction: column;

  &__heading {
    font-weight: 600;
    @include scaled2("font-size", 20px);
    @include scaled2("line-height", 24px);
    margin-bottom: 8px;
    color: #191c23;
  }

  &__label {
    @include scaled2("font-size", 14px);
    @include scaled2("line-height", 16px);
    color: #95969b;
  }

  &__dispositionColumn {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
  }

  &__value {
    font-weight: 600;
    @include scaled2("font-size", 18px);
    @include scaled2("line-height", 22px);
    color: #191c23;

    &Row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &Circle {
      @include scaled2("height", 13px);
      @include scaled2("width", 13px);
      @include scaled2("border-radius", 6.5px);
      margin-right: 4px;
      background-color: red;
    }
  }
}