.PeopleTag {
	max-width: 100%;

	&__container {
		display: flex;
		height: 25px;
		align-items: center;
		margin: 2px 0px;
	}

	&__icon {
		height: 14px;
		width: 16px;
		margin: 0px 2px;
	}

	&__text {
		color: #333333;
		font-size: 12px;
		margin: 0px 2px;
		@include ellipsis();
	}
}
