.NotificationsContainer {
  display: flex;
  flex-direction: column;
  position: relative;

  &__icon {
    height: 25px;
    width: 25px;
  }

  &__modal {
    position: absolute;
    max-height: 450px;
    width: 450px;
    overflow-y: auto;
    top: 50px;
    right: -30px;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 5px 5px 20px 0 rgba(7, 16, 49, 0.1);
    z-index: 11;

    &Body {
      display: flex;
      flex-direction: column;
      padding: 0px 10px 1px 10px;
      max-height: 350px;
      overflow-y: auto;
    }

    &Footer {
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      border-top: 1px $secondary solid;
      padding: 5px 0px;
    }
  }

  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &Icon {
      height: 220px;
      width: 297.08px;
    }

    &Title {
      color: #060730;
      font-size: 20px;
      font-weight: bold;
      line-height: 20px;
      text-align: center;
      margin-top: 10px;
    }

    &Copy {
      color: #8798ad;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      margin: 5px 0px;
    }
  }
}

.NotificationsIconShake {
  animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97);
  animation-iteration-count: 5;
  animation-play-state: running;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
    transform: rotate(5deg);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
    transform: rotate(-6deg);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-1px, 0, 0);
    transform: rotate(10deg);
  }

  40%,
  60% {
    transform: translate3d(2px, 0, 0);
    transform: rotate(-11deg);
  }
}

.ClubbedNotification {
  &__commentsExpansion {
    border: none !important;
    box-shadow: none !important;
    width: 100%;
    padding: 0px 5px !important;
    background-color: transparent !important;

    &::before {
      display: none;
    }

    &Summary {
      width: fit-content !important;
      min-height: 30px !important;
      max-height: 30px !important;
      padding: 0px 5px !important;
    }

    &Details {
      display: flex;
      flex-direction: column !important;
      padding: 0px 5px !important;
    }
  }
}

.CommentNotificationLarge {
  .CommentNotification__title {
    font-size: 13px;

    &Clubbed {
      font-size: 15px;
    }
  }

  .CommentNotification__subtitle {
    font-size: 15px;
  }

  .CommentNotification__time {
    font-size: 12px;
  }

  .CommentNotification__bodyText {
    font-size: 14px;
  }

  .CommentNotification__icon {
    margin-right: 25px;
    margin-left: 10px;
  }

  .CommentNotification__commentorImage {
    display: flex;
    margin: 0px;
    margin-right: 5px;
  }

  .CommentNotification__main {
    width: auto;
  }

  .CommentNotification__iconShowLine {
    &::after {
      content: "";
      background-color: #e7edf3;
      transform: rotate(0deg);
      height: calc(100% - 20px);
      width: 1.5px;
      top: 45px;
      left: 32px;
      position: absolute;
      margin: 0px 2px;
      z-index: 1;
    }
  }
}

.CommentNotification {
  width: 100%;
  cursor: pointer;

  &__commentorImage {
    display: flex;
    margin: 0px;
    margin-right: 3px;
  }

  &__unread {
    background-color: #E5f0fd;
  }

  display: flex;
  flex-direction: row;
  padding: 10px 5px;
  margin: 5px 0px;
  align-items: center;

  &__main {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__icon {
    &Container {
      position: relative;
      margin-top: -7px;
      margin-right: 5px;
    }

    &ShowLine {
      &::after {
        content: "";
        background-color: #e7edf3;
        transform: rotate(0deg);
        height: calc(100% - 8px);
        width: 1.5px;
        top: 32px;
        left: 15px;
        position: absolute;
        margin: 0px 2px;
        z-index: 1;
      }
    }

    &Circle {
      height: 10px;
      width: 10px;
      background-color: green;
      border-radius: 5px;
      border: 2px solid white;
      z-index: 2;
      box-shadow: -2px 2px 6px 0 rgba(58, 70, 93, 0.12);
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
  }

  &__titleContainer {
    display: flex;
    flex-direction: column;
  }

  &__title {
    color: #304156;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;

    &Clubbed {
      font-size: 13.5px;
    }
  }

  &__subtitle {
    color: #117dce;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
  }

  &__time {
    color: #828d99;
    font-size: 10px;
    line-height: 20px;
    margin-left: auto;
  }

  &__body {
    display: flex;
    border-radius: 4px;
    background-color: #f3f6ff;
    padding: 7px 15px;

    &Text {
      color: #304156;
      font-size: 12px;
      line-height: 20px;
    }
  }
}

.NotificationsPage {
  display: flex;
  height: 100%;

  &__container {
    border: 1px solid #e1e8ff;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
    margin: 10px 2rem;
    width: 75%;
    height: calc(100vh - 150px);
  }

  &__header {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 2px;
    background-color: #7db3f3;
    padding: 5px 15px;
    justify-content: space-between;

    &Title {
      color: #2e384d;
      font-size: 16px;
      font-weight: bold;
      line-height: 32px;
      margin-right: auto;
    }
  }

  &__body {
    display: flex;
    padding: 20px;
    flex-direction: column;
    height: calc(100vh - 220px);
    overflow-y: auto;
  }
}