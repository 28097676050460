.DealsSettings {
    &__Overlay {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(3px);
        z-index: 20;
        top: 0;
        left: 0;
    }

    &__Container {
        display: flex;
        flex-direction: column;
        width: 50vw;
        height: 90vh;
        box-shadow: 0px 4px 40px 6px rgba(0, 0, 0, 0.06);
        border-radius: 10px;
        background: #fff;
        overflow: hidden;
        position: relative;
    }

    &__Header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgba(244, 246, 252, 1);
        padding: 20px 30px;

        &Text {
            @include scaled2("font-size", 26px);
            @include scaled2("line-height", 30px);
            font-weight: 500;
        }

        &Close {
            @include scaled2("width", 30px);
            @include scaled2("height", 30px);
            cursor: pointer;
        }
    }

    &__Menu {
        &ActiveBar {
            background-color: #2680EB;
            width: 100px;
            height: 16px;
            border-radius: 25px;
            position: absolute;
            left: 0px;
            margin-left: 20px;
            bottom: -10px;
            transition: margin-left 200ms ease;
        }
    }

    &__Body {
        display: flex;
        flex-direction: column;
        padding: 20px 30px;
        padding-top: 10px;
        overflow: auto;
        position: relative;
        height: 70%;
        z-index: 1;

        &Title {
            @include scaled2("font-size", 16px);
            @include scaled2("line-height", 20px);
            font-weight: 500;
            margin-bottom: 4px;
            color: rgba(45, 57, 88, 1);
            display: flex;
            align-items: center;

            &Dropdown {
                &::after {
                    content: URL("app/styles/assets/images/icons/dealView/ColumnDropdownIcon.svg");
                    width: 10px;
                    color: black;
                    font-size: 14px;
                    right: 10px;
                    margin-left: 10px;
                }
            }
        }

        &Column {
            display: flex;
            border-radius: 8px;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            border: 1px solid rgba(229, 231, 235, 1);
            margin: 16px 0px;
            cursor: pointer;

            &Disabled {
                pointer-events: none;
                background-color: #ececec;
            }

            &LabelContainer {
                display: flex;
                align-items: center;
            }

            &Drag {
                margin-right: 20px;
            }

            &Label {
                color: #6b7280;
                @include scaled2("font-size", 16px);
                @include scaled2("line-height", 20px);
                font-weight: 500;

                &Disabled {
                    color: #9e9e9e !important;
                }
            }

            &Remove {
                opacity: 0.4;
                @include scaled2("width", 25px);
                @include scaled2("height", 25px);
                cursor: pointer;
            }

            &Dropdown {
                display: flex;
                flex-direction: column;
                border: 1px solid #e1e8ff;
                border-radius: 1px;
                background-color: #ffffff;
                min-width: 80px;
                max-height: 200px;
                box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
                margin-left: -20px;
                padding: 0px !important;

                &__item {
                    min-width: 80px !important;
                    cursor: pointer;
                    padding: 5px 12px !important;
                    height: 30px !important;
                    width: 100%;
                    white-space: nowrap;
                    flex: 1;
                    @include scaled2("font-size", 14px);
                    @include scaled2("line-height", 17px);
                    display: flex;
                    align-items: center;

                    &:hover {
                        background-color: $secondary;
                    }
                }
            }
        }

        &Warning {
            display: flex;
            align-items: center;
            margin: 24px 0px;

            &Checkbox {
                margin-top: -16px;
            }

            &TextContainer {
                display: flex;
                flex-direction: column;
            }

            &Label {
                @include scaled2("font-size", 14px);
                @include scaled2("line-height", 17px);
                color: #111827;
                font-weight: 500;
                padding-bottom: 8px;
            }

            &ValueContainer {
                display: flex;
                align-items: center;
                @include scaled2("font-size", 14px);
                @include scaled2("line-height", 17px);
                color: #6b7280;
                font-weight: 500;
            }

            &Dropdown {
                display: flex;
                flex-direction: column;
                border: 1px solid #e1e8ff;
                border-radius: 1px;
                background-color: #ffffff;
                min-width: 80px;
                box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
                margin: 0px 5px;
                padding: 0px !important;

                &__item {
                    min-width: 80px !important;
                    cursor: pointer;
                    padding: 5px 12px !important;
                    height: 30px !important;
                    width: 100%;
                    white-space: nowrap;
                    flex: 1;
                    display: flex;
                    align-items: center;

                    &:hover {
                        background-color: $secondary;
                    }
                }
            }

            &Value {
                padding: 2px 12px;
                margin: 0px 5px;
                margin-top: -10px;
                border: 1px solid #e1e5e9;
                background-color: #ffffff;
                @include scaled2("font-size", 16px);
                @include scaled2("line-height", 19px);
                color: #1f2937;
                border-radius: 4px;
                cursor: pointer;

                &::after {
                    content: URL("app/styles/assets/images/icons/filter/Arrow.svg");
                    width: 10px;
                    color: grey;
                    font-size: 14px;
                    right: 10px;
                    margin-left: 10px;
                }
            }
        }
    }

    &__Button {
        display: flex;
        flex-direction: row;
        align-items: center;
        z-index: 3;
        border: 0.5px solid #aaaaaa;
        border-radius: 4px;
        padding: 6px 10px;
        margin: 25px 30px 5px 0px;

        &Icon {
            @include scaled2("width", 22px);
            @include scaled2("height", 22px);
            margin-right: 4px;
        }

        &Text {
            @include scaled2("font-size", 16px);
            @include scaled2("line-height", 20px);
            font-weight: 600;
        }

        &:hover {
            cursor: pointer;
            background-color: $secondary;
        }
    }
}

.DealsAlerts {
    &__Button {
        display: flex;
        flex-direction: row;
        align-items: center;
        z-index: 3;
        border: 0.5px solid #aaaaaa;
        border-radius: 4px;
        padding: 6px 10px;
        margin: 25px 30px 5px auto;

        &Icon {
            @include scaled2("width", 22px);
            @include scaled2("height", 22px);
            margin-right: 8px;
        }

        &Text {
            @include scaled2("font-size", 16px);
            @include scaled2("line-height", 20px);
            font-weight: 600;

            &Active {
                color: $primary;
            }
        }

        &:hover {
            cursor: pointer;
            background-color: $secondary;
        }
    }

    &__Editor {
        &Text {
            @include scaled2("font-size", 16px);
            @include scaled2("line-height", 19px);
            color: #111827;
            font-weight: 500;
            margin-top: 2px;
        }

        &LightText {
            @include scaled2("font-size", 16px);
            @include scaled2("line-height", 19px);
            color: #6b7280;
            font-weight: 500;
        }
    }

    &__Header {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-top: 4px;
    }

    &__Text {
        @include scaled2("font-size", 14px);
        @include scaled2("line-height", 17px);
        color: #111827;
        font-weight: 500;
        margin-top: 2px;
    }

    &__Section {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 4px 0px;

        &Icon {
            @include scaled2("height", 24px);
            @include scaled2("width", 24px);
            margin-right: 8px;
        }

        &Text {
            @include scaled2("font-size", 18px);
            @include scaled2("line-height", 24px);
            font-weight: 500;
            color: #2d3958;
            margin-top: 2px;
        }
    }

    &__Row {
        display: flex;
        align-items: center;
        padding: 8px 0px 8px 30px;
        justify-content: space-between;
    }

    &__LightText {
        @include scaled2("font-size", 14px);
        @include scaled2("line-height", 17px);
        color: #6b7280;
        font-weight: 500;
    }

    &__Menu {
        display: flex;
        flex-direction: column;
        margin: 0px 30px;
        background-color: #fcfcfd;
        border: solid 1px #e5e7eb;
        border-radius: 6px;

        &Centered {
            align-items: center !important;
        }

        &Container {
            display: flex;
            flex-direction: column;
        }

        &Primary {
            display: flex;
            align-items: center;
        }

        &Secondary {
            margin-left: calc(1.7em + 18px);
            margin-top: -8px;
        }

        &Row {
            display: flex;
        }

        &Item {
            display: flex;
            align-items: flex-start;
            width: 100%;
            padding: 2px 20px;
        }

        &Body {
            border-top: solid 1px #e5e7eb;
            padding-bottom: 8px;
        }

        &Rollup {
            display: flex;
            align-items: center;
            width: 100%;
        }
    }
}

.DealCardTable {
    &::-webkit-scrollbar {
        width: 3px !important;
        height: 10px !important;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1 !important;
        border-radius: 3px !important;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888 !important;
        border-radius: 3px !important;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555 !important;
        border-radius: 3px !important;
    }
}

.ActivityTooltip {
    display: flex;
    flex-direction: column;

    &__heading {
        font-weight: 600;
        @include scaled2("font-size", 16px);
        @include scaled2("line-height", 20px);
        padding: 8px 0px;
        color: #191c23;
        background: #f9fafb;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e0e6ef;

        &Text {
            padding: 0px 8px;
        }
    }

    &__body {
        display: flex;
    }

    &__item {
        padding: 5px;
        padding-top: 10px;
        min-width: 120px;

        &:nth-child(2) {
            border-left: 1px solid #e0e6ef;
        }
    }

    &__label {
        @include scaled2("font-size", 12px);
        @include scaled2("line-height", 16px);
        margin-left: 5px;
        color: #6b7280;

        &Dark {
            color: #686871;
        }

        &Small {
            @include scaled2("font-size", 10px);
            @include scaled2("line-height", 14px);
            max-width: 40px;
        }
    }

    &__score {
        font-weight: 600;
        @include scaled2("font-size", 14px);
        @include scaled2("line-height", 18px);
        padding: 4px 6px;
        width: fit-content;
        margin-top: 4px;
        border-radius: 4px;

        &None {
            color: #6b7280;
            background-color: #efefef;
        }

        &Low {
            color: #fe3c3c;
            background-color: #ffd7d7;
        }

        &Medium {
            color: #fe993c;
            background-color: #fff3ea;
        }

        &High {
            color: #065f46;
            background-color: #d1fae5;
        }
    }

    &__icon {
        width: 22px;
    }

    &__activityColumn {
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;
    }

    &__value {
        font-weight: 600;
        @include scaled2("font-size", 16px);
        @include scaled2("line-height", 22px);
        color: #191c23;

        &Right {
            position: absolute;
            right: 10px;
        }

        &Row {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &Circle {
            @include scaled2("height", 8px);
            @include scaled2("width", 8px);
            @include scaled2("border-radius", 8px);
        }

        &CallsColor {
            background-color: $callsColor;
        }

        &OutgoingColor {
            background-color: $outgoingEmailsColor;
        }

        &IncomingColor {
            background-color: $incomingEmailsColor;
        }

        &RepColor {
            background: $repActivityColor;
        }

        &CustomerColor {
            background: $customerActivityColor;
        }
    }
}

.DealsView {
    &__rightDiv {
        display: flex;
        margin-left: auto;
        @include scaled("width", 190px);
    }

    &__pages {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;

        &Arrow {
            cursor: pointer;

            &Left {
                transform: rotate(180deg);
            }

            &Inactive {
                opacity: 0.5;
            }
        }

        &Overflow {
            display: flex;
        }

        &Item {
            font-weight: 400;
            @include scaled2("font-size", 14px);
            @include scaled2("line-height", 20px);
            border-radius: 3px;
            color: #42526e;
            padding: 6px 12px;
            margin: 0px 2px;
            display: flex;
            border: solid 1px transparent;

            &:hover {
                border: solid 1px #e5e7eb;
                cursor: pointer;
            }

            &Active {
                background-color: #2680EB;
                color: #f4f5f7;
            }
        }
    }

    &__dropdown {
        display: flex;
        flex-direction: column;
        border: 1px solid #e1e8ff;
        border-radius: 1px;
        background-color: #ffffff;
        min-width: 80px;
        box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
        padding: 0px !important;
        top: 28px;
        position: absolute;

        &Item {
            cursor: pointer;
            padding: 5px 10px !important;
            height: 30px !important;
            @include scaled("min-width", 180px);
            width: max-content;
            flex: 1;
            display: flex;

            &:hover {
                background-color: $secondary;
            }
        }
    }
}