.DealEventTag {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    width: 100%;
    margin: 0px auto;
    background-color: $secondary;

    &__name {
        font-weight: 600;
        color: #2680EB;
        @include scaled2("font-size", 13px);
        @include scaled2("line-height", 15px);
    }
}

.DealSelector {
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    z-index: 3;
    width: 100%;
    background: #fff;
    border: 0.3px solid $secondary;
    padding: 8px 20px;
    height: 120px;
    cursor: pointer;

    &Small {
        height: 50px !important;
    }

    &WithDropdown {
        &::after {
            content: URL("app/styles/assets/images/icons/bigChevronDown.svg");
            width: 20px;
            right: 20px;
            position: absolute;
            top: 15px;
        }
    }

    &__header {
        padding: 7px 20px;
        border-bottom: 1px solid #f0f1f2;

        &Text {
            font-weight: 600;
            font-size: 16px;
            line-height: 30px;
            color: #304156;
        }
    }

    &__dropdown {
        position: absolute;
        z-index: 50;
        background: #ffffff;
        border: 1px solid #e0e6ef;
        box-shadow: 0px 30px 60px rgba(92, 99, 105, 0.15);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        top: 40px;
        width: fit-content;
        max-height: 50vh;
        overflow-y: auto;
        left: 80%;
    }

    &__item {
        display: flex;
        align-items: center;
        padding: 10px 10px;
        border-bottom: 1px solid $secondary;
        cursor: pointer;

        &:hover {
            background-color: $secondary;
        }
    }

    &__radio {
        margin-right: 10px;
    }

    &__row {
        display: flex;
        flex-direction: column;
        width: 50%;
    }

    &__column {
        display: flex;
        flex-direction: row;
        width: 100%;
        // justify-content: space-around;
    }

    &__fieldsContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }

    &__name {
        display: block;
        width: 90%;
        font-weight: 600;
        padding: 5px 0px;
        @include scaled2("font-size", 16px);
        @include scaled2("line-height", 20px);
        line-height: 20px;
        color: #2680EB;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &__owner {
        &Container {
            display: flex;
            flex-direction: column;
            padding: 5px 15px;
            height: 55px;
            border-left: 0.5px solid #e1e6ed;
        }

        &NameIconDiv {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &Name {
            font-weight: 600;
            @include scaled2("font-size", 12px);
            @include scaled2("line-height", 15px);
            color: #304156;
            margin-left: 5px;
        }

        &Label {
            @include scaled2("font-size", 10px);
            @include scaled2("line-height", 12px);
            color: #828d99;
            margin-bottom: 3px;
        }
    }
}

.DealItem {
    &__name {
        font-weight: 600;
        margin-bottom: 5px;
        @include scaled2("font-size", 14px);
        @include scaled2("line-height", 17px);
        color: #2680EB;
    }
}

.DealField {
    &Div {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin: 5px 0px;
        min-width: 90px;
        width: 100%;
    }

    &Icon {
        @include scaled2("width", 30px);
        @include scaled2("height", 30px);
        margin-right: 5px;
    }

    &LabelValueDiv {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-right: 15px;
        width: 80%;
    }
}