.EmailVerificationPending {
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #f3f6fe;

    .header {
        height: 50px;
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        margin-bottom: 15px;

        .logo {
            height: 40px;
            width: 190px;
        }
    }

    .bigIcon {
        height: 175px;
        width: 212px;
    }

    .bigFontBold {
        color: #31394D;
        font-size: 28px;
        font-weight: 500;
        line-height: 40px;
        text-align: center;
    }

    .bigFont {
        color: #31394D;

        font-size: 28px;
        line-height: 40px;
        text-align: center;
    }

    .smallFont {
        color: #31394D;

        font-size: 18px;
        line-height: 25px;
        text-align: center;
    }

    .bottomDiv {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 50px;
    }

    .bottomFont {
        color: #31394D;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        text-align: center;
    }

    .bottomFontWarn {
        color: #D63649;
        font-size: 12px;
        line-height: 19px;
        text-align: center;
    }

    .footerText {
        color: #000;
        font-size: 16px;
        line-height: 27px;
        text-align: center;
        margin-top: 50px;
    }
}