.Error {
	&__tag {
		display: inline;
		flex-direction: row;
		align-items: center;
		font-size: inherit;
		line-height: inherit;
		color: #e30623;
		margin: 0px 4px;
		background-color: #ffe6e6;
		padding: 2px 10px;
		width: fit-content;
		border-radius: 3px;
		&::before {
			position: relative;
			content: "⚠";
			margin-right: 4px;
		}
	}

	&__container {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	&__page {
		&__icon {
			height: 200px;
			width: 200px;
		}

		&__header {
			font-size: 30px;
			font-weight: bold;
			color: black;
			text-align: center;
			margin: 10px;
		}

		&__copy {
			font-size: 18px;
			font-weight: 400;
			color: #020202;
			text-align: center;
			margin: 15px;
		}
	}

	&__card {
		&__icon {
			height: 100px;
			width: 100px;
		}

		&__header {
			font-size: 16px;
			font-weight: 450;
			color: black;
			text-align: center;
			margin: 5px;
		}

		&__copy {
			font-size: 12px;
			font-weight: 400;
			color: #020202;
			text-align: center;
			margin: 10px;
		}
	}
}
