.AddToGameTapeForm {
    &__container {
        max-height: 90vh;
        width: 60vw;
        max-width: 900px;
        display: flex;
        height: 90vh;
        padding: 10px 20px;
        flex-direction: column;
        overflow-y: auto;
        margin: 5vh auto;
        background-color: white;
        border-radius: 4px;
        border: 0px solid white !important;
        outline: none !important;
        animation-name: appear;
        animation-duration: 1s;

        &:focus {
            outline: none !important;
            border: 0px solid white !important;
        }
    }

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 10px 10px 20px 10px;

        &Title {
            font-weight: 600;
            @include scaled2("font-size", 24px);
            @include scaled2("line-height", 27px);
            color: #2d3958;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        margin: 10px 10px;
    }

    &__footer {
        display: flex;
        flex-direction: column;
        margin: auto 10px 10px auto;
    }

    &__label {
        font-weight: 600;
        @include scaled2("font-size", 16px);
        @include scaled2("line-height", 19px);
        color: #8d98a9;
        margin-bottom: 4px;
    }

    &__saved {
        background: #5f2eea;
        border-radius: 8px;
        padding: 2px 8px;
        color: #fff;
        @include scaled("font-size", 13px);
        @include scaled("line-height", 15px);
        font-weight: 600;
        margin-left: auto;
    }

    &__button {
        background: #ffffff;
        border: 1px solid #2680EB;
        box-sizing: border-box;
        border-radius: 60px;
        padding: 2px 8px;
        margin-left: auto;
        color: #2680EB;
        @include scaled("font-size", 13px);
        @include scaled("line-height", 15px);
        font-weight: 600;
        min-width: 100px;
        align-items: center;
        display: flex;
        justify-content: center;

        &Remove {
            color: #ed2e7e;
            border: 1px solid #ed2e7e;
        }
    }

    &__newCategory {
        display: flex;
        flex-direction: row;
        align-items: center;

        &Input {
            border: 1px solid #e1e5e8;
            box-sizing: border-box;
            border-radius: 4px;
            margin-right: 10px;
            padding: 0px 4px;

            input {
                &::placeholder {
                    @include scaled("font-size", 15px);
                    @include scaled("line-height", 24px);
                }
            }
        }
    }

    &__notesInput {
        height: 80px;
        background: #f7f7ff;
        border-radius: 4px;
        border-left: 2px solid #2680EB;
        margin-bottom: 30px;
        padding: 12px 8px !important;

        textarea {
            height: 100% !important;
            @include scaled("font-size", 16px);
            @include scaled("line-height", 18px);
        }
    }

    &__existingCategories {
        display: flex;
        flex-direction: column;
        margin: 4px 0px;
        margin-bottom: 20px;
        width: 40%;
    }

    &__category {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 6px 12px;
        @include scaled2("max-width", 450px);
        margin: 2px 0px;
        cursor: pointer;

        &Icon {
            @include scaled2("height", 20px);
            @include scaled2("width", 20px);
            filter: grayscale(1);

            &Saved {
                @include scaled2("height", 20px);
                @include scaled2("width", 20px);
                filter: hue-rotate(30deg);
            }
        }

        &Name {
            font-weight: 500;
            @include scaled("font-size", 16px);
            @include scaled("line-height", 19px);
            color: #8d98a9;
            margin-left: 10px;

            &Saved {
                font-weight: 500;
                @include scaled("font-size", 16px);
                @include scaled("line-height", 19px);
                color: #5f2eea;
                margin-left: 10px;
            }
        }
    }
}