.EmptyState {
	&__container {
		display: flex;
		flex-direction: column;
	}

	&__subHeader {
		color: #2E384D;
		font-size: 20px;
		font-weight: 700;
		line-height: 32px;
		margin: 15px; 
	}

	&__CompleteIntegrations {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-items: flex-start;
		width:100%;

		._subLine {
			color: #6D86B9;
			font-size: 16px;
			font-weight: 700;
			line-height: 22px;
			text-align: center;
			margin: 15px 0px;
		}

	}

	&__noCallsContainer {
		display: flex;
		flex-direction: column;
		border: 1px solid #E1E8FF;
		border-radius: 4px;
		background-color: #EAEEFF;
		box-shadow: 0 10px 20px 0 rgba(46,91,255,0.07);
		align-items: center;
		justify-content: center;
		padding: 20px 0px;
		margin: 20px 0px;

		.mainLine {
			color: #0043C2;
			font-size: 26px;
			font-weight: 500;
			line-height: 36px;
			text-align: center;
			margin: 5px 0px;
		}

		.subLine {
			color: #6D86B9;
			font-size: 14px;
			line-height: 22px;
			text-align: center;
			margin: 5px 0px;
		}
	}

	&__explainerContainer {
		display:flex;
		align-items: center;
		flex-direction: column;
		margin: 10px 0px;

		&-imageContainer {
			display:flex;
			align-items: center;
			flex-direction: row;	
		}

		.singleContent{
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 5px 10px;
			padding: 10px;
			width: 28vw;

			.graphic {
				height: 250px;
			}

			.mainLine{
				height: 40px;
				color: #2E384D;
				font-size: 16px;
				font-weight: 700;
				line-height: 27px;
				text-align: center;
			}
			.subLine{
				display: flex;
				flex-direction: column;
				align-items: center;
				height: 150px;
				color: #2E384D;
				font-size: 14px;
				font-weight: 300;
				line-height: 27px;
				text-align: center;
			}
		}

	}
}