	.ActivityDashboard {
		display: flex;
		flex-direction: column;
		width: 80%;

		&__table {
			height: calc(100vh - 230px);
			overflow-y: auto;
			padding: 0px !important;
		}

		&__totalRow {
			position: sticky;
			background-color: white !important;
			z-index: 10;
		}

		&__totalColumn {
			position: sticky;
			bottom: 0;
			background-color: white !important;
			border-top: 2px solid $secondary;
			z-index: 2;
		}

		&__dropdown {
			display: flex;
			flex-direction: column;
			border: 1px solid #e1e8ff;
			border-radius: 1px;
			background-color: #ffffff;
			min-width: 100px;
			box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
			padding: 0px !important;

			&__item {
				min-width: 100px !important;
				overflow: hidden;
				white-space: nowrap;
				word-wrap: none;
				cursor: pointer;
				padding: 5px !important;
				height: 30px !important;
				font-size: 15px !important;

				&:hover {
					background-color: $secondary;
				}
			}

			&__trigger {
				margin: 0px 5px;
				text-decoration: underline;
				cursor: pointer;
			}
		}

		&__header {
			display: flex;
			flex-direction: row;
			align-items: flex-end;
			width: 100%;
			margin: 10px 0px;

			&Text {
				font-size: 22px;
			}

			&SelectUsers {
				margin-left: auto;
				width: 30%;
				z-index: 5;
			}
		}
	}