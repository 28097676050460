.InputWithAutoComplete {
    &__optionsDiv {
        display: flex;
        flex-direction: column;
        position: absolute;
        padding: 4px 8px;
        border: 1px solid $secondary;
        box-shadow: 1px;
        top: calc(100% + 10px);
        background-color: #fff;
        max-height: 200px;
        overflow-y: auto;
    }

    &__optionsItem {
        @include scaled2("font-size", 14px);
        @include scaled2("line-height", 20px);
        padding: 8px;
        color: #304156;

        &:hover {
            background-color: $secondary;
        }
    }
}
