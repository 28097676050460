.Explainer {

	display: flex;
	flex-direction: column;
	background-color: white;
	padding: 20px;

	&__title {
		color: #4F4071;
		font-size: 18px;
		font-weight: bold;
	}

	&__firstRow {
		display: flex;
		flex-direction: row;
		min-height: 150px;
		margin: 20px 0px;
	}


	&__secondRow {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: white;
		min-height: 500px;
	}

	&__AboutPage {
		display: flex;
		flex-direction: column;
		margin: 0px 40px 0px 20px;
		width: 35%;

		&Name {
			color: #2D2D2D;
			font-size: 16px;
			font-weight: bold;
		}

		&Content {
			color: #7C7C7C;
			font-size: 14px;
		}

		&Icon {
			height: 20px;
			width: 20px;
			margin-right: 20px;
		}
	}

	&__GetStarted {
		display: flex;
		flex-direction: column;
		margin: 0px 40px 0px 20px;

		&Content {
			color: #7C7C7C;
			font-size: 14px;
		}
	}

	&__imageAndTextRow {
		display: flex;
		align-items: center;
		margin: 10px 0px;
	}

	&__explainerImage {
		width: 1000px;
		height: 500px;
	}
}