@font-face {
  font-family: "MaisonNeue";
  src: url(../../../fonts/MaisonNeue-Book.woff2) format("woff2"), url(../../../fonts/MaisonNeue-Book.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "MaisonNeue";
  src: url(../../../fonts/MaisonNeue-BookItalic.woff2) format('woff2'), url(../../../fonts/MaisonNeue-BookItalic.woff) format('woff');
  font-weight: 400;
  font-style: italic;
}


@font-face {
  font-family: "MaisonNeue";
  src: url(../../../fonts/MaisonNeue-Medium.woff2) format('woff2'), url(../../../fonts/MaisonNeue-Medium.woff) format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "MaisonNeue";
  src: url(../../../fonts/MaisonNeue-MediumItalic.woff2) format('woff2'), url(../../../fonts/MaisonNeue-MediumItalic.woff) format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "MaisonNeue";
  src: url(../../../fonts/MaisonNeue-Bold.woff2) format('woff2'), url(../../../fonts/MaisonNeue-Bold.woff) format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "MaisonNeue";
  src: url(../../../fonts/MaisonNeue-BoldItalic.woff2) format('woff2'), url(../../../fonts/MaisonNeue-BoldItalic.woff) format('woff');
  font-weight: 700;
  font-style: italic;
}

html,
body {
  font-weight: 400;
  font-family: "MaisonNeue";
  // @include scaled("font-size", 14px);
  margin: 0;
  padding: 0;
  @include size(100%);
  background: #fafafa;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
}

a {
  color: $blue;
  text-decoration: none;
  cursor: pointer;
}

button {
  cursor: pointer;
}

input,
select,
textarea,
a,
button {

  &:hover,
  &:active,
  &:focus {
    outline: 0;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0px 9999px white;
}

table {
  border-collapse: collapse;
  display: table;
  font-family: 'MaisonNeue', sans-serif;
}

html,
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
img {
  margin: 0;
  padding: 0;
}

@each $heading,
$properties in $headings {
  #{$heading} {
    font-size: nth($properties, 1) * 1rem;
    line-height: nth($properties, 2) * 1rem * $leading;
    margin-bottom: nth($properties, 4) * 1rem * $leading;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.02em;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

h1+h2,
h2+h3,
h3+h4,
h4+h5,
h5+h6 {
  margin-top: 0.5rem * $leading;
}

p {
  margin: 0 0 1rem * $leading;
}

.clearfix {
  @include clearfix();
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.align-left {
  text-align: left !important;
}

.align-right {
  text-align: right !important;
}

.align-center {
  text-align: center !important;
}

.hidden {
  display: none;
}

#root {
  @include size(100%);
}

.py4 {
  padding: 4px 0px !important;
}

/*
|--------------------------------------------------
| Collapse
|--------------------------------------------------
 */
.collapse {
  max-height: 0px;
  display: block;
  overflow: hidden;
  transition: max-height 0.4s ease-out;

  &.in {
    max-height: 50rem;
    transition: max-height 0.5s ease-in;
  }
}

/*
|--------------------------------------------------
| Rotate
|--------------------------------------------------
*/
.rotate135 {
  transform: rotate(135deg);
}

/*
|--------------------------------------------------
| Modal
|--------------------------------------------------
*/

.GenericModal {
  &__container {
    position: fixed;
    z-index: 101;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: -7px 8px 16px rgba(58, 70, 93, 0.1);
    border-radius: 4px;
    padding: 10px 20px;
    border: 0px solid white !important;
    outline: none !important;
    animation-name: appear;
    animation-duration: 1s;

    &:focus {
      outline: none !important;
      border: 0px solid white !important;
    }
  }

  &__closeIcon {
    @include scaled("height", 22px);
    @include scaled("width", 22px);
    cursor: pointer;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;

    &Right {
      display: flex;
      margin-left: auto;
    }

    &Title {
      font-weight: 600;
      @include scaled2("font-size", 22px);
      @include scaled2("line-height", 26px);
      color: #2d3958;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    margin: 15px 0px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: auto;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: 10px;
  }
}

/*
|--------------------------------------------------
| Button
|--------------------------------------------------
*/

.AnotherNewButton {
  @include scaled("height", 35px);
  background: #2680EB;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  cursor: pointer;
  min-width: 80px;
  width: fit-content;

  &Transparent {
    background: transparent !important;
  }

  &:hover {}

  &__label {
    font-weight: 500;
    @include scaled("font-size", 15px);
    @include scaled("line-height", 17px);
    color: #fff;
  }

  &__endAdornment {
    @include scaled("height", 25px);
    @include scaled("width", 25px);
    margin-left: 10px;
  }

  &__icon {
    @include scaled("height", 25px);
    @include scaled("width", 25px);
    margin-right: 10px;
  }

  &__startAdornment {
    @include scaled("height", 25px);
    @include scaled("width", 25px);
    margin-right: 10px;
  }
}


.NewButton {
  @include scaled("height", 35px);
  background: #E5F0FD;
  background-blend-mode: soft-light, normal;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  cursor: pointer;
  min-width: 80px;

  &__disabled {
    cursor: pointer;
    filter: grayscale(1) opacity(0.4) invert(0.2);
  }




  &__icon {
    @include scaled("height", 25px);
    @include scaled("width", 25px);
    margin-right: 10px;
  }

  &Transparent {
    background: transparent !important;
  }

  &:hover {
    color: #2680EB;
  }

  &__label {
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: #6B7280;
  }

  &__endAdornment {
    height: 15px;
    width: 15px;
    margin-left: 10px;
  }

  &__startPlayerAdornment {
    height: 24px;
    width: 24px;
    margin-right: 5px;
  }

  &__startAdornment {
    height: 15px;
    width: 15px;
    margin-right: 10px;
  }
}

.ButtonWithImage {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__image {
    height: 20px;
    width: 20px;

    &Div {
      margin-right: 10px;
      display: flex;
      align-content: center;
    }
  }
}

.button-transparent {
  background: transparent;
  border: 0;
}

.video-react {
  background-color: #fff !important;

  &:focus {
    outline: none !important;
  }
}

.video-react-video {
  background-color: #fff !important;

  &:focus {
    outline: none !important;
  }
}

.NotSelectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Link {
  font-size: 12px;
  text-decoration: underline;
  color: #2680EB !important;
  margin: 0px 5px;
  cursor: pointer;
}

.LinkWithoutLine {
  font-size: 12px;
  font-weight: 600;
  word-wrap: none;
  white-space: nowrap;
  text-transform: uppercase;
  color: #2680EB !important;
  margin: 0px 5px;
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

.rowWithMargins {
  display: flex;
  margin: 10px;
  align-items: center;
}

/*
|--------------------------------------------------
| Table
|--------------------------------------------------
*/

.row-centered {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.col-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row-topcentered {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.row-tcentered {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.row-start {
  display: flex;
  flex-direction: row;
}

.col-topcentered {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.EditedBy {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;

  &__label {
    font-weight: 500;
    @include scaled2("font-size", 14px);
    @include scaled2("line-height", 20px);
    color: #6b7280;
    margin-right: 4px;
  }

  &__userName {
    font-weight: 500;
    @include scaled2("font-size", 14px);
    @include scaled2("line-height", 20px);
    color: #2d3958;
    margin-left: 2px;
  }

  &__date {
    @include scaled2("font-size", 14px);
    @include scaled2("line-height", 20px);
    color: #6b7280;
    margin-left: 4px;
  }
}

.Disclaimer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  background-color: #dbeafe;

  &__icon {
    @include scaled2("height", 16px);
    @include scaled2("width", 16px);
    margin: 0px 4px;
  }

  &__copy {
    @include scaled2("font-size", 14px);
    @include scaled2("line-height", 17px);
    color: $primary;
  }
}

.col-startcentered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.stretch {
  max-height: 40vh;
  max-width: 45vw;
  padding: 10% 20%;
}

.row_30 {
  width: 30%;
}

.row_35 {
  width: 35%;
}

.row_20 {
  width: 20%;
}

.row_40 {
  width: 40%;
}

.row-right {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

/*
|--------------------------------------------------
| Calendar
|--------------------------------------------------
*/

.Calendar__picker {
  width: 135px;
  z-index: 2;

  .react-date-picker__wrapper {
    border-radius: 4px;
    border: 1px solid $secondary;
    padding: 8px;
  }
}

/*
|--------------------------------------------------
| General
|--------------------------------------------------
*/

.pointer {
  cursor: pointer;
}

.error {
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-width {
  width: 100%;
  min-height: 200px;
}

.loading {
  width: 80px;
  height: 80px;
}

.loadingFullScreen {
  width: 80px;
  height: 80px;
  position: absolute;
  z-index: 1000;
  top: 40%;
  left: 47%;
}

.centered {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.AudioRecorder {
  background-color: transparent;
  border: 0px;

  &-button {
    background-color: transparent;
    border: 0px;
  }

  &-remove {
    background-color: transparent;
    border: 0px;
  }

  &-download {
    background-color: transparent;
    border: 0px;
  }
}

.spinnerContainer {
  position: relative;
}

.spinner {
  position: absolute;
  margin: auto 0;
  width: 70px;
  text-align: center;
  z-index: 100;
}

.spinner>div {
  width: 18px;
  height: 18px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.animate {
  transition: opacity 500ms;
}

.Typography {
  &__small {
    font-size: 16px;
    color: #304156;
    font-weight: 500;
    margin: 5px 0px;
  }
}

.TooltipText {
  position: absolute;
  @include itemsCenter();
  height: 23px;
  padding: 0px 10px;
  width: max-content;
  border: 0.3px solid rgba(46, 56, 77, 0.4);
  background-color: #2e384d;
  border-radius: 3px;
  box-shadow: 0 5px 10px 0 rgba(46, 91, 255, 0.14);

  .text {
    color: #e1e3f4;
    font-size: 11px;
    line-height: 15px;
  }
}

.TooltipText:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: #2e384d;
  border-bottom: 0;
  margin-left: -5px;
  margin-bottom: -5px;
}

.Placeholder {
  font-size: 13px;
  color: #b4b4b4;
}

.TooltipTextHorizontal {
  position: absolute;
  @include itemsCenter();
  height: 23px;
  padding: 0px 10px;
  width: max-content;
  border: 0.3px solid rgba(46, 56, 77, 0.4);
  background-color: #2e384d;
  border-radius: 3px;
  box-shadow: 0 5px 10px 0 rgba(46, 91, 255, 0.14);
  margin-left: 40%;
  margin-top: 10px;

  .text {
    color: #e1e3f4;
    font-size: 11px;
    line-height: 15px;
  }
}

.ProfileImage {
  &__Avatar {
    background-color: #26324f !important;
    color: white !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin: 0px 0.5px;
  }

  &__overlap {
    border: white solid 2px;
    margin-left: -7px;
  }
}

.ProfileImageAndName {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 3px;

  &__icon {
    margin-right: 5px !important;
  }
}

.TrialEnd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  height: 300px;
  max-height: 90vh;
  max-width: 70vw;
  width: 420px;
  position: fixed;
  top: calc(50vh - 150px);
  right: calc(50vw - 210px);
  background-color: $primary;
  border-radius: 5px;
  box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
  z-index: 100;

  &__header {
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    margin-top: 25px;
  }

  &__hideDiv {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  &__subheader {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #fff;
    margin: 5px 0px;
    margin-left: 15px;
    text-align: center;
  }
}

.TooltipTextHorizontal:after {
  content: "";
  position: absolute;
  left: 0%;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: #2e384d;
  border-bottom: 0;
  margin-left: -7px;
  margin-bottom: -5px;
  transform: rotate(90deg);
}

.speech-bubble {
  position: relative;
  background: #d8d5d5;
  border-radius: 0.4em;
}

.speech-bubble:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 82%;
  width: 0;
  height: 0;
  border: 11px solid transparent;
  border-top-color: #f3eaea;
  border-bottom: 0;
  margin-left: -11px;
  margin-bottom: -11px;
}

.verCon {
  display: flex;
  flex-direction: column;
}

.horCon {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.horConSpreadAround {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.appearDiv {
  animation-name: appear;
  animation-duration: 500ms;
}

.Tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0px;
  border-bottom: 2px solid #3455cf;
  width: fit-content;

  .InactiveTab {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #3455cf;
    font-size: 15px;
    height: 30px;
    cursor: pointer;
    padding: 8px 20px;
  }

  .ActiveTab {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #3455cf;
    color: #fff;
    border-radius: 10px 10px 0px 0px;
    font-size: 15px;
    height: 30px;
    font-weight: 500;
    cursor: pointer;
    padding: 8px 20px;
  }
}

.NewDataTooltip {


  animation: slide-up 0.5s ease;

}

@keyframes slide-up {
  0% {
    opacity: 0.5;
    transform: translateY(120px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.LabelWithExplanation {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__label {
    @include scaled("font-size", 20px);
    @include scaled("line-height", 22px);
    font-weight: 600;
    text-transform: capitalize;
  }

  &__infoIcon {
    @include scaled("height", 13px);
    @include scaled("width", 13px);
    margin: 0px 5px;
  }
}

.video-react-video {
  border-radius: 7.5px;
}

//Animations:
@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}