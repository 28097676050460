.SearchSuggestions {
  &__heading {
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.38);
    margin: 10px 0px;
  }

  &__subheading {
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.38);
    margin-top: 5px;
    margin-left: 5px;
  }

  &__container {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    border: 1px solid #e1e8ff;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
    width: 100%;
    min-height: 100px;
    top: 40px;
    left: 0px;
    cursor: default;
  }

  &__oppsContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    border-bottom: 1px solid #d8d8d8;
  }

  &__fieldsContainer {
    display: flex;
    flex-direction: column;
    padding-left: 5px;
  }

  &__result {
    &Container {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 5px;
      margin: 3px;
      cursor: pointer;

      &.active {
        background-color: #E5F0FD;
      }

      &Highlighted {
        display: flex;
        align-items: center;
        background: #f0f3ff;
        border-radius: 2px;
        padding: 8px;

        &:hover {
          background-color: #f0f3ff;
        }
      }
    }

    &Loading {
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    &Icon {
      @include scaled("height", 25px);
      @include scaled("width", 25px);
    }

    &IconSmall {
      @include scaled("height", 20px);
      @include scaled("width", 20px);
    }

    &NameDiv {
      display: flex;
      flex-direction: column;
    }

    &Title {
      @include scaled("font-size", 14px);
      @include scaled("line-height", 17px);
      color: #304156;
      font-weight: 400;
      margin-left: 10px;
    }

    &TitleBold {
      @include scaled("font-size", 14px);
      @include scaled("line-height", 17px);
      color: #1E66BC;
      font-weight: 700;
    }

    &Subtitle {
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #304156;
      margin-left: 10px;
    }
  }
}

.Search {
  &__outerContainer {
    margin: 5px !important;
  }

  &__resultsContainer {
    display: flex;
  }

  &__results {
    &Header {
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &Title {
      color: #304156;
      font-size: 16px;
      font-weight: 500;
      margin: 5px;
    }

    &Right {
      display: flex;
      margin-left: auto;
    }
  }

  &__header {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    &_title {
      color: #5b5b5b;
      font-size: 18px;
      font-weight: 500;
    }
  }

  &__container {
    margin-top: 10px;
    display: flex;
    padding-bottom: 10px;
    align-items: center;
  }

  &__addCategory {
    font-size: 20px;
    font-weight: bold;
    color: black;
    width: 89vw;
    letter-spacing: 0.02em;
  }

  &__icon {
    height: 25px;
    width: 26px;
    margin-left: 15px;
    margin-right: 15px;
  }

  &__delete {
    font-size: 20px;
  }

  &__tabSelector {
    height: 35px;
    width: 250px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 1px 2px 0 rgba(52, 64, 132, 0.49);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0px 10px;
    margin-left: 30px;
    cursor: pointer;
  }

  &__activeTab {
    border-bottom: 1px solid #4a1058;
    height: 100%;
    align-items: center;
    display: flex;

    &_label {
      color: #4a1058;

      font-size: 13px;
      font-weight: bold;
      line-height: 18px;
    }
  }

  &__tab {
    &_label {
      color: #5b5b5b;

      font-size: 13px;
      line-height: 18px;
    }
  }

  &__legend {
    height: 35px;
    width: 353px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 2px;
    background-color: #e4edf6;
    box-shadow: 0 1px 2px 0 rgba(52, 64, 132, 0.49);

    &_item {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &_circle {
      height: 16px;
      width: 16px;
      border-radius: 8px;
      background-color: #3aac77;
      margin: 2px;
    }

    &_text {
      width: 56px;
      color: #5b5b5b;

      font-size: 13px;
      font-weight: 500;
    }
  }
}

.SearchBar {
  position: relative;
  display: flex;
  align-items: center;

  &__input {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    border-radius: 8px;
    height: 40px;
    width: 500px;
    border: 1px solid $secondary;

    &:focus-within {
      border: 1px solid $primary !important;
    }

    input {
      &::placeholder {
        color: gray;
        font-size: 13px;
        letter-spacing: 0.2px;
        opacity: 1;
      }
    }
  }

  &__results {
    display: flex;
    background-color: white;
    border: 1px solid #e1e8ff;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 10000;
    position: absolute;
    width: 80vw;
    top: 40px;
    left: calc(250px - 40vw);
    height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}