.Snackbar {
	&__msg {
		display: flex;
		align-items: center;
	}

	&__icon {
		width: 20px;
		height: 20px;
		color: white;
		margin: 5px 10px;
	}

	&__SUCCESS {
		background-color: #43a047 !important;
	}

	&__ERROR {
		background-color: #d32f2f !important;
	}

	&__INFO {
		background-color: #1976d2 !important;
	}

	&__WARNING {
		background-color: #ffa000 !important;
	}

	&__LOADING {
		background-color: $primary !important;
	}
}