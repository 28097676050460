.CustomIconButton {
  @include itemsCenter();
  flex-direction: column;
  
  img {
    @include size(100%);
    max-width: 20px;
    max-height: 20px;
  }

  span {
    color: #808080;
    font-size: 12px;
    margin-top: 3px;
  }
}

.RoundButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    filter: hue-rotate(345deg) drop-shadow(2px 2px 2px rgba(46, 91, 255, 0.07));
  }
}
