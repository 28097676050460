@mixin size($value) {
  $width: nth($value, 1);
  $height: $width;

  @if length($value) >1 {
    $height: nth($value, 2);
  }

  @if is-size($height) {
    height: $height;
  }

  @if is-size($width) {
    width: $width;
  }
}

@mixin triangle($size, $color, $direction) {
  $width: nth($size, 1);
  $height: nth($size, length($size));
  $foreground-color: nth($color, 1);
  $background-color: if(length($color) ==2, nth($color, 2), transparent);
  height: 0;
  width: 0;

  @if ($direction==up) or ($direction==down) or ($direction==right) or ($direction==left) {
    $width: $width / 2;
    $height: if(length($size) > 1, $height, $height/2);

    @if $direction==up {
      border-bottom: $height solid $foreground-color;
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
    } @else if $direction==right {
      border-bottom: $width solid $background-color;
      border-left: $height solid $foreground-color;
      border-top: $width solid $background-color;
    } @else if $direction==down {
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
      border-top: $height solid $foreground-color;
    } @else if $direction==left {
      border-bottom: $width solid $background-color;
      border-right: $height solid $foreground-color;
      border-top: $width solid $background-color;
    }
  } @else if ($direction==up-right) or ($direction==up-left) {
    border-top: $height solid $foreground-color;

    @if $direction==up-right {
      border-left: $width solid $background-color;
    } @else if $direction==up-left {
      border-right: $width solid $background-color;
    }
  } @else if ($direction==down-right) or ($direction==down-left) {
    border-bottom: $height solid $foreground-color;

    @if $direction==down-right {
      border-left: $width solid $background-color;
    } @else if $direction==down-left {
      border-right: $width solid $background-color;
    }
  } @else if ($direction==inset-up) {
    border-color: $background-color $background-color $foreground-color;
    border-style: solid;
    border-width: $height $width;
  } @else if ($direction==inset-down) {
    border-color: $foreground-color $background-color $background-color;
    border-style: solid;
    border-width: $height $width;
  } @else if ($direction==inset-right) {
    border-color: $background-color $background-color $background-color $foreground-color;
    border-style: solid;
    border-width: $width $height;
  } @else if ($direction==inset-left) {
    border-color: $background-color $foreground-color $background-color $background-color;
    border-style: solid;
    border-width: $width $height;
  }
}

@mixin word-wrap($wrap: break-word) {
  overflow-wrap: $wrap;
  word-wrap: $wrap;

  @if $wrap==break-word {
    word-break: break-all;
  } @else {
    word-break: $wrap;
  }
}

@mixin position($position: relative, $coordinates: null null null null) {
  @if type-of($position) ==list {
    $coordinates: $position;
    $position: relative;
  }

  position: $position;
  top: nth($coordinates, 1);
  right: nth($coordinates, 2);
  bottom: nth($coordinates, 3);
  left: nth($coordinates, 4);
}

@mixin clearfix {
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}

@mixin ellipsis($width: 100%) {
  display: inline-block;
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

@mixin scaled($property, $size: 14px) {
  #{$property}: calc(
    #{$size * 0.8} + #{strip-unit($size * 0.4)} * ((100vw - 1100px) / #{strip-unit(1920px - 800px)})
  ) !important;
}

@mixin scaled2($property, $size: 14px) {
  #{$property}: calc(
    #{$size * 0.7} + #{strip-unit($size * 0.4)} * ((100vw - 1100px) / #{strip-unit(1920px - 800px)})
  ) !important;
}

@mixin multiLineEllipsis($lineHeight: 14px, $lineCount: 1, $bgColor: white){
  overflow: hidden;
  position: relative;
  @include scaled2("line-height", $lineHeight);  
  max-height: $lineHeight * $lineCount; 
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  padding-right: 0em;

  &:before {
    content: "...";
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}

@mixin absoluteCenter($dir) {
  @if $dir== "x" {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  } @else if $dir== "y" {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  } @else {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@mixin gradientHorizontal {
  background: #53aed1;
  background: -moz-linear-gradient(left, #53aed1 0%, #68bc86 100%);
  background: -webkit-linear-gradient(left, #53aed1 0%, #68bc86 100%);
  background: linear-gradient(to right, #53aed1 0%, #68bc86 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#53aed1', endColorstr='#68bc86', GradientType=1);
}

@mixin gradientDiagonal {
  background: #53aed1;
  background: -moz-linear-gradient(-45deg, #53aed1 0%, #68bc86 100%);
  background: -webkit-linear-gradient(-45deg, #53aed1 0%, #68bc86 100%);
  background: linear-gradient(135deg, #53aed1 0%, #68bc86 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#53aed1', endColorstr='#68bc86', GradientType=1);
}

@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $color;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $color;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $color;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: $color;
  }
}

@mixin primaryColor {
  color: #3150d2;
}

@mixin cardTitle {
  color: rgb(50, 74, 104);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 1.21px;
  text-transform: uppercase;
}

@mixin tableColumn {
  color: #2e384d;
  font-size: 15px;
}

@mixin pageHeader {
  color: #2e384d;
  font-size: 28px;
  font-weight: 300;
}

@mixin tableHeader {
  color: #8798ad;
  font-size: 13px;
  font-weight: 400;
}

@mixin buttonText {
  font-size: 15px;
  font-weight: 500;
}

@mixin linkText {
  color: #2e5bff;
  font-size: 15px;
  font-weight: 500;
}

@mixin overflowLine($lineHeight, $numLine) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $numLine;
  /* number of lines to show */
  line-height: $lineHeight;
  /* fallback */
  max-height: $lineHeight * $numLine;
  /* fallback */
}

@mixin zDepth-1 {
  -webkit-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -o-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
}

@mixin tooltip($dir, $width, $message) {
  $bg_color: #646363;
  position: relative;

  &:before,
  &:after {
    clear: both;
    content: "";
    transition: cubic-bezier(0.23, 1, 0.32, 1) 450ms;
    visibility: hidden;
    opacity: 0;
    z-index: 10;
  }

  &:after {
    content: $message;
    width: $width;
    padding: 6px 10px;
    border-radius: 5px;
    background: $bg_color;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
  }

  &:hover {
    &:before,
    &:after {
      opacity: 1;
      visibility: initial;
    }
  }

  @if ($dir==left) {
    &:after,
    &:before {
      @include absoluteCenter("y");
      right: 0;
    }

    &:before {
      @include triangle(16px, $bg_color, right);
    }

    &:hover {
      &:after {
        right: calc(100% + 8px);
      }

      &:before {
        right: 100%;
      }
    }
  } @else if $dir==right {
    &:after,
    &:before {
      @include absoluteCenter("y");
      left: 0;
    }

    &:before {
      @include triangle(16px, $bg_color, left);
    }

    &:hover {
      &:after {
        left: calc(100% + 8px);
      }

      &:before {
        left: 100%;
      }
    }
  } @else if $dir==top {
    &:after,
    &:before {
      @include absoluteCenter("x");
      bottom: 0;
    }

    &:before {
      @include triangle(16px, $bg_color, down);
    }

    &:hover {
      &:after {
        bottom: calc(100% + 8px);
      }

      &:before {
        bottom: 100%;
      }
    }
  } @else if $dir==bottom {
    &:after,
    &:before {
      @include absoluteCenter("x");
      top: 0;
    }

    &:before {
      @include triangle(16px, $bg_color, up);
    }

    &:hover {
      &:after {
        top: calc(100% + 8px);
      }

      &:before {
        top: 100%;
      }
    }
  }
}

@mixin itemsCenter() {
  display: flex;
  align-items: center;
  justify-content: center;
}
