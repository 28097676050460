.FooterWarning {
    &__container {
        position: fixed;
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 80%;
        max-width: 1100px;
        height: 70px;
        background-color: #dc2943;
        border-radius: 5px;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%, 0);
        padding: 5px 20px;
        padding-right: 50px;
        margin-bottom: 2px;
        z-index: 3;
    }

    &__closeContainer {
        position: absolute;
        cursor: pointer;
        top: 5px;
        right: 10px;
    }

    &__headingContainer {
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    &__textContainer {
        display: flex;
        width: 75%;
        justify-content: flex-start;
        height: 100%;
        margin-top: 10px;
        flex-direction: column;
    }

    &__buttonContainer {
        margin-left: 20px;
        margin-top: 5px;
    }

    &__icon {
        height: 22px;
        width: 22px;
    }

    &__heading {
        font-size: 20px;
        line-height: 22px;
        color: white;
        font-weight: 700;
    }

    &__subheading {
        font-size: 13px;
        color: white;
        margin-left: 5px;
    }

    &__link {
        font-size: 16px;
        line-height: 22px;
        white-space: nowrap;
        color: white;
        padding: 3px 5px;
        border-radius: 3px;
        font-weight: 600;
        border: 1.5px solid white;
        cursor: pointer;
    }
}
