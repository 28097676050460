.HistogramChart {
    &__container {
        margin: 10px;
    }

    &__sortButton {
        cursor: pointer;
        display: inline;
        padding: 5px;
        border-radius: 50%;
        background-color: 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

        :hover {
            background-color: rgba(0, 0, 0, 0.08);
            border-radius: 50%;
        }
    }

    &__viewMore {
        color: #4a1058;
        font-size: 10px;
        padding: 2px;
    }

    &__sortIcon {
        height: 12px;
        width: 12px;
    }

    &__chartHeaders {
        height: 20px;
        margin-bottom: 5px;
        font-size: 13px;

        &__left {
            text-transform: uppercase;
            float: left;
            color: #96a2ac;
        }

        &__right {
            text-transform: uppercase;
            float: right;
            color: #3a4b59;
        }
    }

    &__valueIcon {
        height: 15px;
        width: 15px;
    }

    &__head {
        display: flex;
        align-items: center;
        padding: 2px;
        margin-bottom: 5px;
        width: 100%;

        &-title {
            @include cardTitle();
        }

        &-avg {
            @include itemsCenter();
            flex-direction: row;
            margin-right: auto;
            margin-left: auto;

            &-number {
                font-size: 12px;
                font-weight: 700;
            }

            &-string {
                font-size: 12px;
                font-weight: 500;
                line-height: 10px;
            }
        }

        &-change {
            height: 20px;
            margin: 5px 0px;
            @include itemsCenter();
            border-radius: 10px;
            border: 1px solid #cccccc;

            &-percent {
                margin: 2px 2px 2px 8px;
                color: #333333;
                font-size: 12px;
                font-weight: 500;
            }

            img {
                margin: 2px 8px 2px 2px;
            }
        }
    }
}

.HistogramBar {
    &__container {
        margin: 12px 0px;
    }

    &__outerBar {
        height: 15px;
        width: 100%;
        border-radius: 5px;
        background-color: #f5f6f7;
    }

    &__innerBar {
        height: 15px;
        transition: 500ms all;
        opacity: 0.4;
        border-radius: 0 3px 3px 0;
        z-index: 10;

        &__label {
            color: white;
            justify-content: flex-end;
            padding: 2.5px 5px;
            display: flex;
            align-content: center;
            font-size: 12px;
        }
    }

    &__labels {
        height: 20px;
        font-size: 12px;

        &__left {
            float: left;
            color: #3a4b59;
        }

        &__right {
            float: right;
            color: #3a4b59;
        }
    }
}
