.SPaper {
  padding: 30px 20px 0px 20px;
  background-color: #ffffff;

  .wingman__rulerContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
  }

  hr {
    width: 100%;
    border-top: 1px solid #e0e0e0;
    margin-top: 30px;
  }
}

.SPaper:last-child {
  padding-bottom: 30px;

  hr {
    display: none;
  }
}

.SPaperNew {
  padding: 5px;
  border: 0.2px solid #e1e8ff;
  border-radius: 1px;
  background-color: #ffffff;
}