/*
|--------------------------------------------------
| Colors
|--------------------------------------------------
*/
$white: #ffffff;

$blue: #006dc8;

$gray: #999999;

$green: #00be28;

$light-red: #e26a73;

$orange: #ff931e;

$yellow: #e6e550;

$purple: #6d3d76;


$primary: #2680EB;
$secondary: #e5f0fd;
$warning: #e23b3b;

/*
|--------------------------------------------------
| Sizes
|--------------------------------------------------
*/
$header_height: 60px;
$sidebar_width: 60px;
$player_height: 85px;
$player_width: 100vw;


$leading : 1;
// Headings Options
// [ h1: size (in rem), line height (* leading), margin top (* leading), margin bottom (* leading) ]
$headings: (h1 : (2.5, 2, 4, 1),
  h2 : (1.6875, 1.5, 2.5, 1),
  h3 : (1.375, 1.25, 2, 1),
  h4 : (1.2, 1.2, 1.5, 1),
  h5 : (1, 1, 2.5, 1),
  h6 : (1, 1, 2.5, 1)) !default;