.Settings {
  &__row {
    display: flex;
    flex-direction: row;
  }

  &__outerContainer {
    display: flex;
    flex-direction: row;
  }

  &__innerContainer {
    width: calc(100% - 220px);
    margin-left: 220px;
    padding-left: 20px;
    background-color: white;

    &-heading {
      color: #2e384d;
      font-size: 28px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 32px;
      margin-bottom: 25px;
    }
  }

  &__sidebar {
    min-width: 220px;
    position: fixed;
    overflow-y: auto;
    background-color: white;
    padding-left: 15px;
    height: calc(100vh - 60px);

    &-section {
      display: flex;
      flex-direction: column;
      margin: 10px 0px;

      &ExpansionIcon {
        font-size: 15px;
        color: black;
        margin-left: auto;
        margin-right: 10px;
      }

      &Header {
        display: flex;
        align-items: center;
        flex-direction: row;
        height: 30px;
        cursor: pointer;

        &Icon {
          height: 15px;
          width: 15px;
          margin: 0px 5px;
        }
      }

      &Heading {
        color: #2e384d;
        font-size: 14px;
        font-weight: 700;
        line-height: 25px;
        margin-left: 5px;
      }
    }

    &Button {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin: 5px 0px;
      padding: 0px;
      height: 30px;
      border-radius: 0 100px 100px 0;

      &Active {
        cursor: pointer;
        margin: 5px 0px;
        border-left: 4px solid #2680EB;
        padding: 0px -4px;
      }
    }

    &Icon {
      height: 13px;
      width: 13px;
      margin: 10px;
      filter: hue-rotate(300deg) contrast(0.1);

      &Active {
        filter: invert(0);
        height: 15px;
        width: 15px;
      }
    }

    &Text {
      color: #8f98a8;
      font-size: 14px;

      &Active {
        font-size: 14px;
      }
    }
  }

  &__integrations {
    &Container {
      display: flex;
      flex-direction: column;
      background-color: white;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      margin: 5px 0px;
      padding: 20px;
      border-bottom: 2px solid #e4e8f0;
    }

    &__row {
      margin: 10px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
    }
  }

  &__voiceContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    .button {
      margin: 10px 10px;
    }
  }

  &__profileContainer {
    background-color: white;
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
    padding: 10px 30px;
    border-bottom: 2px solid #e4e8f0;

    .row {
      margin: 10px 0px;
      align-items: center;
    }

    .col {
      margin: 0px 3% 0px 0px;
      width: 30%;
    }

    .left {
      align-items: flex-start;
      justify-content: flex-start;
    }

    .noPadding {
      padding: 0px !important;
    }

    .loMargin {
      margin: 4px 0px !important;
    }

    .noMargin {
      margin: 0px 0px !important;
    }

    .footer {
      margin-left: auto;
    }

    .imageContainer {
      flex: 1 1 0;
      display: flex;
      flex-direction: column;
      margin: 0px 20px;
      border-left: 1px solid #d4dfff;
      align-items: center;

      &__companyLogo {
        align-items: left;
        border-left: unset;
      }
    }

    .edit {
      position: absolute;
      top: 0%;
      background-color: rgb(72, 72, 72);
      border-radius: 100px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 15px;
      right: 0%;

      &logo {
        right: unset;
        left: 130px;
      }
    }

    &button {
      padding: 0px 15px;
      display: flex;
      align-items: center;
      height: 32px;
      margin: 3px;
      border-radius: 4px;
      min-width: 100px;
      justify-content: center;
      border: 1px solid #d4dfff;
      color: #304156;
      @include scaled2("font-size", 16px);
      @include scaled2("line-height", 19px);
      font-weight: 600;
      letter-spacing: 0;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
          0px 4px 5px 0px rgba(0, 0, 0, 0.14),
          0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
          0px 4px 5px 0px rgba(0, 0, 0, 0.14),
          0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        background-color: #d5d5d5;
      }
    }

    &noBorder {
      border: none;

      &:hover {
        box-shadow: unset;
        background-color: unset;
      }
    }
  }

  &__label {
    color: #304156;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
  }

  &__explanation {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
  }

  &__header {
    color: #1e1e1e;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;

    &Explanation {
      color: #494949;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
      margin-top: -15px;
      margin-bottom: 10px;
    }
  }

  &__notifications {
    display: flex;
    flex-direction: column;
    margin: 0px 5px;
    margin-bottom: 20px;

    &-typeHeader {
      color: #304156;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 30px;
    }

    &-type {
      color: #304156;
      font-size: 15px;
      letter-spacing: 0;

      &Container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 3px 0px;
      }

      &Content {
        display: flex;
        flex-direction: column;
      }

      &Explanation {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 15px;
      }
    }
  }

  &__crmContainer {
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 15px 30px;
    border-bottom: 2px solid #e4e8f0;
    height: fit-content;

    .documentSection {
      .label {
        display: flex;
        color: #7f8fa4;
        font-size: 14px;
        font-weight: 500;
        margin: 10px 0px;
        text-transform: capitalize;
      }
    }

    .inputContainer {
      display: flex;
      flex-direction: row;
      margin: 5px 0px;

      .Select {
        display: flex;

        &__control {
          width: 300px;
          margin-right: 5px;
        }
      }
    }

    .tagsContainer {
      margin: 10px 0px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .tag {
        height: 36px;
        min-width: 207px;
        border: 1px solid #dfe3e9;
        border-radius: 4px;
        background-color: #f3f5f7;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 2px 2px;
      }
    }
  }
}

.RecorderDropdown {
  display: flex;
  flex-direction: column;
  border: 1px solid #e1e8ff;
  border-radius: 1px;
  background-color: #ffffff;
  min-width: 80px;
  box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
  padding: 0px !important;

  &__item {
    min-width: 80px !important;
    cursor: pointer;
    padding: 5px !important;
    height: 30px !important;
    width: max-content !important;
    flex: 1;
    display: flex;

    &:hover {
      background-color: $secondary;
    }
  }

  &__trigger {
    padding: 2px 12px;
    margin: 0px 5px;
    margin-top: -10px;
    border: 1px solid #e1e5e9;
    background-color: #ffffff;
    border-radius: 4px;
    cursor: pointer;

    &::after {
      content: URL("app/styles/assets/images/icons/filter/Arrow.svg");
      width: 10px;
      color: grey;
      font-size: 14px;
      right: 10px;
      margin-left: 10px;
    }
  }
}

.FilterAlerts {
  &__filterModal {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    width: 200px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #e1e5e8;
    top: 20px;
    right: 0px;
  }

  &__row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 5px 10px;
  }

  &__heading {
    font-size: 14px;
    font-weight: 700;
    color: #304156;
  }

  &__name {
    font-size: 13px;
    font-weight: 600;
    color: #304156;
    padding: 5px;
  }

  &__savedFilterButton {
    height: 25px;
    width: 25px;
    cursor: pointer;
  }
}

.CompanyLogo {
  &__header {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-around;
  }
}