.RoleManagement {
	&__card {
		max-height: calc(100vh - 100px) !important;

		&__body {
			max-height: calc(100vh - 170px) !important;
		}
	}

	&-header-column {
		display: flex;
		align-items: center;
		justify-content: center;
		justify-content: space-between;
		font-size: 18px;
		color: black;
		font-weight: 500;
	}

	&__table {
		&__userName {
			color: #6f7a8b;
			font-size: 14px;
			letter-spacing: 0.1px;
			font-weight: 600;
			margin-left: 10px;
		}

		&__email {
			color: #9da9bc;
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 0.1px;
		}

		&__level2 {
			padding-left: 10px;
		}

		&__level3 {
			padding-left: 20px;
		}

		&__level4 {
			padding-left: 30px;
		}

		&__level5 {
			padding-left: 35px;
		}

		&__level6 {
			padding-left: 40px;
		}

		&__level7 {
			padding-left: 40px;
		}

		&__collapseIcon {
			margin-left: -15px;
			margin-right: 3px;
			width: 12px !important;
			height: 12px !important;
		}

		&__row.hidden-row {
			display: none;
		}

		&__teamSize {
			color: grey;
			font-size: 10px;
			margin: 10px;
		}

		&__teamIcon {
			vertical-align: middle;
		}
	}
}

.InviteUserModal {
	&__row {
		margin: 10px 0px;
	}

	&__bigColumn {
		width: 60%;
		margin: 10px;
	}

	&__smallColumn {
		width: 30%;
		margin: 10px;
	}
}
