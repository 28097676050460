.DateDividedUpcomingCalls {
    &__noUpcomingCalls {
        margin: 30px;
        text-align: center;
    }
    &__date {
        text-transform: capitalize;
        color: #304156;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
    }
    &__call {
        display: flex;
        flex-direction: row;
        margin: 15px 0;
        padding: 10px 0px;
        border-bottom: 1px solid rgba(224, 224, 224, 1);
    }
    &__callTimeContainer {
        width: 55px;
        flex-grow: 0;
        flex-shrink: 0;
    }
    &__time {
        font-size: 12px;
        color: #828d99;
    }
    &__titleContainer {
        flex-grow: 1;
        margin-left: 8px;
    }
    &__title {
        font-weight: 600;
        font-size: 14px;
        color: #304156;
        margin-bottom: 5px;
    }
    &__notRecordingReason {
        font-size: 12px;
        font-weight: 500px;
        color: #828d99;
    }
    &__agentContainer {
        margin-left: 10px;
    }
    &__agent {
        border: white solid 2px;
        margin-left: -7px;
    }
}
