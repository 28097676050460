.Share {
  &__formContainerOld {
    width: 300px;
    height: 65px;
  }

  &__row {
    display: grid;
    grid-template-columns: 75% 25%;
    align-items: center;
  }

  &__linkInput {
    width: 295px;
    text-overflow: ellipsis;
    border-radius: 2px;
    background-color: #ffffff;
  }

  &__copyLink {
    height: 15px;
    width: 15px;
  }

  &__disclaimer {
    font-size: 12px;
    color: grey;
  }

  &__title {
    color: #454545;
    font-size: 14px;
    font-weight: 500;
  }

  &CallForm {

    &__Screen {
      height: 90vh;
      width: 60vw;
      max-width: 100%;
      max-height: 100%;
      display: flex;
      padding: 10px 20px;
      flex-direction: column;
      overflow: auto;
      background-color: white;
      border-radius: 4px;
      border: 0px solid white !important;
      outline: none !important;
      animation-name: appear;
      animation-duration: 1s;
    }

    &__Trimmer {
      position: fixed;
      top: calc(calc(10vh) / 2);
      left: calc(calc(40vw)/2);

    }


    &:focus {
      outline: none !important;
      border: 0px solid white !important;
    }

    &__trimCopy {
      position: absolute;
      top: calc(50% + 20px);
      font-size: 20px;
      font-weight: 700;
      color: $primary;
    }

    &__header {
      display: flex;
      width: 100%;
      align-items: center;
      margin: 5px 0px;
      height: 40px;

      &__close {
        height: 36px;
        width: 36px;
        border-radius: 18px;
        display: flex;
        margin-left: auto;
        cursor: pointer;
      }

      &__text {
        font-size: 20px !important;
        font-weight: bold !important;
        color: #304156;
        font-size: 18px;
        font-weight: 600;
        line-height: 30px;
      }
    }

    &__linkContainer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      margin-top: auto;
      margin: 5px;


      &__link {
        color: #117dce;
        text-overflow: clip;
        width: 90%;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        margin-left: 20px;
      }

      &__text {
        color: #304156;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 30px;
      }

      &__shareLinkCopy {
        color: #828d99;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 24px;
      }

      &__subtext {
        color: #8d98a9;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.13px;
        line-height: 16px;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: calc(100% - 170px);
      width: calc(100% - 20px);
      margin: 10px;
    }
  }

  &__formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  &__row {
    display: grid;
    grid-template-columns: 75% 25%;
    align-items: center;
  }

  &__linkInput {
    width: 295px;
    text-overflow: ellipsis;
    border-radius: 2px;
    background-color: #ffffff;
  }

  &__copyLink {
    height: 15px;
    width: 15px;
  }

  &__disclaimer {
    font-size: 12px;
    color: grey;
  }

  &__title {
    color: #454545;
    font-size: 14px;
    font-weight: 500;
  }
}

.Trimmer {
  &__timelineRect {
    position: absolute;
    top: 39px;
    height: 6.14px;
    width: 100%;
    border-radius: 3px;
    background-color: #7db3f3;
    z-index: 2;

    &_currentTime {
      position: absolute;
      border: 2px solid $primary;
      border-radius: 3px;
      height: 15px;
      top: -4px;
      z-index: 4;
    }
  }

  &__container {
    width: 100%;
    position: relative;
    margin: 10px 0px;
  }

  &__window {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 30px;
    top: 3px;
    left: 0px;
  }

  &__leftTrimmer {
    position: absolute;
    height: 22px;
    top: 32px;
    left: 0px;
    cursor: pointer;
    z-index: 3;
  }

  &__rightTrimmer {
    position: absolute;
    height: 22px;
    right: 0px;
    top: 32px;
    display: flex;
    cursor: pointer;
    z-index: 3;
  }

  &__body {
    display: flex;
    flex-direction: row;
    height: 23px;
    border-radius: 4px;
    border: 1px solid #2680EB;
    margin-top: 1.5px;
    background-color: #E5f0fd;
    z-index: 1;
  }
}

.Shared {
  &__VideoPlayerContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px;
  }

  &__VideoContainer {
    height: 100%;
    max-height: 100%;
    flex-grow: 1;
    border: 1px solid #e1e8ff;
    background-color: #f8f9ff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;

    &__fullScreen {
      position: absolute;
      cursor: pointer;
      bottom: 20px;
      right: 20px;
      height: 25px;
      width: 25px;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.SharedCall {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    border: 1px solid #e1e8ff;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
    width: 60%;
    height: calc(100% - 50px);
    margin: 0px 20%;
  }

  &__titleDiv {
    display: flex;
    flex-direction: column;
    margin-top: 10px !important;
    padding-top: 10px !important;
  }

  &__copyDiv {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  &__infoIcon {
    margin: 0px 5px;
    height: 15px;
  }

  &__body {
    display: flex;
    height: calc(100% - 200px);
  }

  &__header {
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 2px;
    background-color: #fafbfb;
    margin: 10px 20px;

    &__title {
      color: #304156;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 26px;
    }

    &__subtitle {
      color: #8d98a9;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1.13px;
      line-height: 15px;
    }

    &__copy {
      color: #8d98a9;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 17px;
    }
  }

  &__player {
    height: 123px;
    border: 1px solid #e1e8ff;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 -10px 20px 0 rgba(46, 91, 255, 0.07);
  }
}