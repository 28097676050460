.CallTable {
  &__container {
    height: 100%;
    overflow-y: auto;
    padding: 0px;
  }

  &__Pageheader {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin: 10px 0px;

    .rightContents {
      display: flex;
      margin-left: auto;
      min-width: 510px;
      justify-content: flex-end;
    }

    .leftContents {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &__privateCallIcon {
    height: 20px;
    width: 20px;
    margin: 0px 5px;
  }

  &__numComments {
    display: flex;
    align-items: center;
    right: 60px;
    margin: 5px;

    &__icon {
      height: 18px;
      width: 18px;
      opacity: 0.8;
      z-index: 0;
    }

    &__text {
      font-size: 8px;
      font-weight: bold;
      background-color: $primary;
      color: white;
      border-radius: 7px;
      padding: 2px 4px;
      margin-bottom: 14px;
      margin-left: -5px;
      z-index: 1;
    }
  }

  &__reasonColumn {
    color: #828d99;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
  }

  &__Footer {
    &__rightContents {
      margin: 5px;
      margin-right: 30px;
      display: flex;
      align-items: center;
      margin-left: auto;
      justify-content: flex-end;
    }
  }

  &__PaginationWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    margin: 0px 2px;
  }

  &__paginationButton {
    &Disabled {
      opacity: 0.5;
      cursor: auto;
    }
    border: 1px solid #c6cbd4;
    height: 32px;
    padding: 7px;
    width: 32px;
    cursor: pointer;
  }

  &__paginationIcon {
    height: 16px;
    width: 16px;
  }

  &__paginationContainer {
    margin-right: 10;
    width: 62px;
    display: flex;
    background-color: #fff;
  }

  &__simpleRow {
    position: relative;
    width: 100%;
    background-color: #fcfcfc;
    .CallTable__meetingTitle,
    .CallTable__durationContainer_text,
    .CallTable__meetingDateTime_timeText {
      font-weight: 400;
      color: #617896;
    }

    .CallTable__meetingDateTime_dateText {
      color: #617896;
    }

    .CallTable__tlRatioText {
      font-weight: 400;
    }

    &:hover {
      box-shadow: 4px 3px 5px 1px $secondary;
    }
  }

  &__simpleRowUnread {
    position: relative;
    border-left: 5px solid $primary;

    .CallTable__meetingTitle {
      font-weight: 500;
    }

    &:hover {
      box-shadow: 4px 3px 5px 1px $secondary;
    }
  }

  &__simpleRowNotConnected {
    position: relative;
    .CallTable__meetingTitle {
      color: #617896;
    }
    .Call__meetingIcon {
      filter: grayscale(0.3);
    }
  }

  &__searchRow {
    border-bottom: 2px solid rgb(187, 209, 236);
  }

  &__meetingDetails {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__meetingTitle {
    color: #304156;
    font-size: 13px;
    font-weight: 400;

    &Hover {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__dividerDot {
    width: 4px;
    height: 4px;
    background: #c4c4c4;
    border-radius: 2px;
    margin: 0px 5px;
  }

  &__detailsText {
    font-weight: 500;
    font-size: 10px;
    line-height: 13px;
    color: #8d98a9;
    text-transform: capitalize;
  }

  &__meetingDateTime {
    display: flex;
    flex-direction: column;
    min-width: 90px;

    &_timeText {
      color: #6f7a8b;
      font-size: 12px;
    }

    &_dateText {
      color: #6f7a8b;
      font-size: 12px;
      font-weight: bold;
    }
  }

  &__durationContainer {
    display: flex;
    align-items: center;

    &_text {
      font-size: 12px;
      padding: 5px 10px;
      margin-left: -5px;
      white-space: nowrap;
      border-radius: 2px;
    }
    &_green {
      color: #00ba88 !important;
      background-color: #d5fff5;
      font-weight: 700;
      min-width: 80px;
    }
    &_red {
      color: #c30052 !important;
      background-color: #fff2f7;
      font-weight: 700;
    }
    &_orange {
      color: #c68200 !important;
      background-color: #fff0d3;
      font-weight: 700;
    }
    &_gray {
      color: #6b778c !important;
      background-color: #f5f5f5;
      font-weight: 700;
    }
  }

  &__agentContainer {
    display: flex;
    align-items: center;
    flex-direction: row;

    &__image {
      height: 30px;
    }

    &Avatar {
      background-color: #26324f !important;
      color: white !important;
      font-weight: 500 !important;
      font-size: 10px !important;
      width: 30px !important;
      height: 30px !important;
      margin: 0px 1px !important;
      text-transform: capitalize !important;
    }
  }

  &__timeIcon {
    height: 19px;
    width: 19px;
    margin-right: 10px;
  }

  &__activeFilter {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    &Container {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
    }

    &Field {
      color: #284572;
      font-size: 12px;
      font-weight: 500;
      margin-right: 5px;
    }

    &Tile {
      display: flex;
      align-items: center;
      flex-direction: row;
      border: 0.5px #d6dfff solid;
      border-radius: 10px;
      background-color: #fff;
      padding: 0px 5px;
      margin: 0px 2px;

      &_Value {
        color: #284572;
        font-size: 10px;
        margin: 2px;
      }

      &_Icon {
        padding: 5px !important;
      }
    }
  }

  &__transcript {
    width: 100%;
    display: flex;
    align-items: center;

    &__textContainer {
      margin-left: 10px;
    }

    &_text {
      font-size: 14px;
      text-align: center;
      margin: 0px;
      font-weight: 500;
      font-style: italic;
      color: #597092;
    }

    &_bold {
      font-size: 14px;
      font-weight: bold;
      font-style: italic;
      color: #607696;
    }

    &Column {
      background-color: #f9f9f9;
      padding: 5px 10px !important;
      align-items: center;
      display: flex;
    }

    &Label {
      width: 100px;
      color: #6f7a8b;

      font-size: 14px;
      font-weight: 500;
      border-right: 2px solid #798eaf;
    }

    &MatchChip {
      padding: 10px;
      color: #6f7a8b;
      font-size: 12px;
      font-weight: 500;
      border-left: 2px solid #798eaf;
      margin-left: auto;
    }
  }

  &__tlRatio {
    display: flex;
    align-items: center;
    min-width: 100px;

    &Text {
      font-size: 14px;
      font-weight: 500;
      line-height: 19px;
      text-align: right;
      margin-left: 5px;
    }
  }
}
