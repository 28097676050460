@keyframes spin {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@keyframes load {
  0% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 50% 0%;
  }

  100% {
    background-position: 0% 0%;
  }
}

@keyframes grow {
  0% {
    transform: scale(0) translate3d(0, 0, 0);
  }

  100% {
    transform: scale(1) translate3d(0, 0, 0);
  }
}

@keyframes growTwice {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(2);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes translucent {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

@keyframes announcementAnimation {
  0% {
    transform: translate(0px, 0px);
  }

  100% {
    transform: translate(0px, calc(-50% + 60px));
  }
}

@keyframes scaleDown {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.3);
  }
}

@keyframes shrinkWidthHeight {
  0% {
    width: 10rem;
    height: 10rem;
  }

  100% {
    width: 3.33rem;
    height: 3.33rem;
  }
}

@keyframes shrinkMargin {
  0% {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  100% {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
}