.Sync-Background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #f4f6fc;
}

.DetailedOnboarding {
  &__outerContainerTransparent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 10px auto;
    width: 70%;
  }

  &__outerContainerTransparentFull {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 0px auto;
    width: calc(100% - 10px);
  }

  &__imageContainer {
    margin: 10px 0px;
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
  }

  &__tipsContainer {
    display: flex;
    flex-direction: column;
  }

  &__textContainer {
    padding-top: 90px;
    padding-left: 50px;
    display: flex;
    justify-content: flex-start;
    width: 50%;
    height: 100%;
    flex-direction: column;
  }

  &__iconContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
  }

  &__buttonContainer {
    margin: 10px 0px;
    display: flex;
  }

  &__heading {
    color: #304156;
    font-size: 20px;
    margin: 2px 0px;
    font-weight: 600;
    letter-spacing: 0.02em;
  }

  &__subheading {
    font-weight: 400;
    color: #304156;
    font-size: 13px;
    margin-bottom: 15px;
  }

  &__skipContainer {
    margin: 10px 0px;
  }

  &__supportLink {
    cursor: pointer;
    text-decoration: underline;
    line-height: 20px;
    font-size: 13px;
    margin-left: 15px;
    color: #3150d2;
    font-weight: 400;
  }

  &__tip {
    font-size: 16px;
    font-weight: bold;
    color: #304156;
    margin: 2px 2px;
    margin-bottom: 5px;

    &Copy {
      font-size: 12px;
      font-weight: 500;
      color: #546579;
      margin-left: 20px;
      margin-bottom: 15px;
    }
  }
}

.SyncFlowContents {
  &__outerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 400px;
    width: 300px;
    border: 1px solid #e1e8ff;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
    margin: 5px;
  }

  &__outerContainerTransparent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 400px;
    width: 300px;
    margin: 5px 0px;
  }

  &__textContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__iconContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
  }

  &__buttonContainer {
    margin: 10px 0px;
  }

  &__heading {
    color: black;
    font-size: 16px;
    margin: 10px 0px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.02em;
  }

  &__subheading {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    margin: 2px 0px;
  }

  &__skipContainer {
    margin: 10px 0px;
  }
}

.calendar_icon {
  height: 120px;
  width: 112px;
}

.hubspot_icon {
  height: 120px;
  width: 112px;
}

.freshsales_icon {
  height: 120px;
  width: 112px;
}

.leadsquared_icon {
  height: 120px;
  width: 112px;
}

.outreach_icon {
  height: 120px;
  width: 112px;
}

.wingman_icon {
  width: 100%;
  height: calc(100% - 200px);
}

.pipedrive_icon {
  height: 120px;
  width: 112px;
}

.salesforce_icon {
  height: 120px;
  width: 120px;
}

.ringcentral_icon {
  height: 120px;
  width: 112px;
}

.VoiceFingerprint {
  &__card {
    @include scaled("height", 600px);
    @include scaled("width", 800px);
    background-color: #ffffff;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    box-shadow: -1px 1px 29px 1px rgba(0, 0, 0, 0.21);
    overflow-y: auto;
  }

  &__copy {
    color: #304156;
    font-weight: 600;
    @include scaled("font-size", 16px);
    @include scaled("line-height", 18px);
    text-align: center;
    @include scaled("margin-bottom", 15px);
    display: flex;
  }

  &__copyIcon {
    height: 200px;
    width: 200px;
  }

  &__voiceicon {
    height: 250px;
    width: 250px;
  }

  &__passage {
    color: #304156;
    @include scaled("font-size", 16px);
    @include scaled("line-height", 23px);
    letter-spacing: 0.2px;
    margin: 0px 5%;
    text-align: left;
    display: flex;
    flex-direction: column;
    white-space: pre-wrap;
  }
}

.voice {
  position: relative;

  &__spectrum {
    height: 92px;
    background: URL("app/styles/assets/images/spectrum.png");
  }

  &__line {
    width: 540px;
    height: 1px;
    background-color: #cccccc;
    position: absolute;
    top: 50px;
  }

  &__duration {
    color: #333333;
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
  }

  &__play {
    position: absolute;
    top: -130px;
    margin-left: -25px;
    width: 50px;
    height: 50px;
  }

  &__button {
    color: #4a1058;
    border-radius: 15px;
    border: 1px solid #4a1058;
    margin: 1px;
    text-transform: uppercase;
    padding: 8px 16px;
    font-weight: 500;
    line-height: 1.4em;
  }
}

.Freshsales,
.Leadsquared {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  overflow: hidden;

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
    width: 500px;
  }

  &__centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__top-centered {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &__right {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }

  &__textField {
    width: 320px;
  }

  &__textFieldShort {
    width: 240px;
  }

  &__textFieldSuperShort {
    width: 180px;
  }

  &__textFieldBlack {
    width: 80px;
    color: black;
  }

  &__error {
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__infoDiv {
    padding: 10px;
    margin: 10px;
  }

  &__info {
    color: grey;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__infoLink {
    color: $primary;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.RoleCard {
  &__outerContainer {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    height: 242px;
    margin: 0px 50px;
    width: 220px;
    border: 1px solid #e8ebf2;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5), -8px 6px 7px 0 rgba(128, 152, 213, 0.19);

    &:hover {
      box-shadow: 2px 5px 15px 2px #3150d2, -8px 6px 7px 0 rgba(128, 152, 213, 0.19);
    }
  }

  &__activeContainer {
    display: flex;
    flex-direction: column;
    height: 242px;
    margin: 0px 50px;
    width: 220px;
    border: 1px solid #3150d2;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 5px 15px 0 #3150d2, -8px 6px 7px 0 rgba(128, 152, 213, 0.19);
  }

  &__header {
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {}

  &__body {
    height: 60%;
    width: calc(100% - 2px);
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 0 0 10px 10px;
    background-color: #f3f5f8;

    &_role {
      color: #3150d2;
      margin: 20px 0px;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
    }

    &_explain {
      color: #070707;
      font-size: 12px;
      margin: 0px 5px;
      text-align: center;
    }
  }

  &__footer {
    height: 12%;
    display: flex;
    margin-top: auto;
    margin-bottom: -2px;

    &_button {
      width: 240px;
    }
  }
}

.RecordingSelection {
  &__outerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__buttonContainer {
    display: flex;
    align-items: center;
    margin: 10px 0px;
    justify-content: space-between;
  }

  &__button {
    margin: 0px 10px;

    &_icon {
      margin: 0px 5px;
    }
  }

  &__title {
    color: #3150d2;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 10px 0px;
  }

  &__copy {
    color: #070707;
    font-size: 14px;
    text-align: center;
  }
}