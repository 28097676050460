#Active {
  background-color: orange;
  color: black;
}

.Call {
  &__body {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: row;
    background-color: #fff;
  }

  .Card__body {
    overflow-x: hidden;
    overflow-y: auto;
    height: 350px;
  }

  &__MeetingAttendees {
    &Container {
      display: flex;
      position: relative;
      align-items: flex-end;
      justify-content: flex-end;
      flex-direction: row;
      margin: 0px 10px;
    }

    &Values {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &Value {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: calc(100% - 45px);
    }

    &Badge {
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: center;
      height: 25px;
      width: 25px;
      margin: 0px 10px;
      color: #484848;
      font-size: 13px;
      font-weight: 500;

      &:hover {
        border-bottom: 1px solid #484848;
      }
    }

    &Label {
      margin-left: 10px;
      color: #808080;
      font-size: 11px;
      font-weight: bold;
    }

    &__Tooltip {
      position: absolute;
      background-color: white;
      width: 200px;
      height: 150px;
      overflow-y: auto;
      z-index: 1000;
      padding: 10px;
      border: 1px solid #c4cdd5;
    }
  }

  &__Statistics {
    &Container {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-end;
      width: 100%;
      border-left: 1px solid rgba(147, 168, 247, 0.3);
    }

    &Tile {
      display: flex;
      flex-grow: 1;
      padding: 0px 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-right: 1px solid rgba(147, 168, 247, 0.3);
    }

    &InnerContainer {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
    }

    &Icon {
      height: 25px;
      width: 25px;
      margin: 3px;
    }

    &TLText {
      color: #646464;
      font-size: 20px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
    }

    &Label {
      display: flex;
      align-items: center;
      color: #818181;
      font-size: 10px;
      font-weight: 450;
      letter-spacing: 0.75px;
      line-height: 20px;
      text-transform: uppercase;
      text-align: center;
    }
  }

  &__processing {
    &Container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 24px;
    }

    &Icon {
      height: 300px;
      width: 300px;
      margin: 10px;
    }

    &Copy {
      font-size: 14px;
      color: black;
      font-weight: 600;
      text-align: center;
    }

    &SubCopy {
      font-size: 13px;
      color: gray;
      text-align: center;
    }
  }

  &__HighlightContainer {
    height: 100%;
    background-color: white;
    position: relative;
    width: 100%;

    &__listContainer {
      overflow-y: auto;
      height: calc(100% - 80px);
      margin: 0px 4px;
    }

    .Highlight {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 5px 0px;
      padding: 5px 1px;
      cursor: pointer;

      &__category {
        border-radius: 2px;
        background-color: #f0ebff;
        display: flex;
        text-transform: uppercase;
        color: #5f2eea;
        @include scaled("font-size", 10px);
        @include scaled("line-height", 12px);
        padding: 4px 8px;
        font-weight: 500;
        margin: 0px 0px 0px 5px;
      }

      &:hover {
        /*.Highlight__time {
          border: 1px grey solid !important;
          border-radius: 2px !important;
        }*/
      }

      &__header {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin: 2px 0px;
      }

      &__speaker {
        color: #2680EB;
        font-weight: 600;
        letter-spacing: 0;
        margin-right: 5px;
        @include scaled("font-size", 13px);
        @include scaled("line-height", 15px);

        &Customer {
          color: #f2752e;
        }
      }

      &__time {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 2px 0px;

        &Text {
          @include scaled("font-size", 11px);
          @include scaled("line-height", 13px);
          color: #117dce;
          vertical-align: middle;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }

        &PlayIcon {
          height: 14px;
          width: 14px;
        }
      }

      &__text {
        color: #8798ad;
        @include scaled("font-size", 14px);
        @include scaled("line-height", 20px);
        letter-spacing: 0.1px;
      }
    }

    &__tabs {
      display: flex;
      flex-direction: row;
      align-items: center;
      @include scaled("height", 36px);
      background-color: #fff;
      margin: 5px 0px;
    }

    &__tab {
      display: flex;
      align-items: center;
      justify-content: center;
      @include scaled("height", 36px);
      color: #8798ad;
      @include scaled("font-size", 13px);
      flex-grow: 1;
      cursor: pointer;

      &Active {
        background: #f7f9ff;
        font-weight: 600;
        color: #2680EB;
        font-weight: bold;
        border-bottom: 3px solid #2680EB;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }

    &__tabs1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 5px -10px;
      background: #f3f6ff;
      border-radius: 60px;
      padding: 5px;
    }

    &__tab1 {
      display: flex;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      height: 36px;
      color: #2d3958;
      font-size: 14px;
      cursor: pointer;

      &Active {
        background: #2e5bff;
        border-radius: 60px;
        color: #fff;
        font-weight: bold;
      }
    }

    .HighlightsPanel {
      box-shadow: none !important;
      margin: 0 !important;
      width: 100%;

      &__fullHeight {
        height: calc(100vh - 175px);
      }

      &__notExpanded {
        position: absolute;
        top: calc(100% - 23px);
      }

      &:last-child {
        border: 0;
      }

      &__head {
        padding: 0px 10px 0px 0px !important;
        margin: 0px !important;
        max-height: 45px !important;
        min-height: 45px !important;
        width: 100%;

        &content {
          padding: 0px !important;
          margin: 0px !important;
        }

        &:expanded {
          padding: 0px !important;
        }
      }

      &__head-title {
        color: #3150d2 !important;
        font-size: 12px !important;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
      }

      &__content {
        padding: 0 !important;
        display: block !important;
        overflow-y: auto;
        height: calc(100vh - 280px);
      }

      &__contentFullHeight {
        padding: 0 !important;
        display: block !important;
        overflow-y: auto;
        height: calc(100vh - 220px);
      }

      &__row {
        padding: 10px;
        text-align: right;
        display: flex;
        align-items: flex-start;

        &:hover {
          cursor: pointer;
        }

        &:last-child {
          border: 0;
        }
      }

      &__text {
        text-align: left;
        opacity: 0.55;
        color: #3d3d4f;
        font-size: 14px;
        font-weight: 500;
        margin: 0px 15px;
      }

      &__time {
        color: #8798ad;
        font-size: 12px;
        margin-top: 2px;
      }
    }

    .CategoryTitleSelected {
      background: #fff;

      .text {
        color: #4e56ab;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 1.13px;
        line-height: 20px;
      }
    }

    .CategoryTitleInActive {
      background-color: #f0f0f0;
      border: 0.5px solid rgba(178, 194, 253, 0.3);

      .text {
        color: #8b94a7;
      }
    }

    .CategoryTitle {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      width: 100%;
      height: 48px;
      margin: -12px 0px;

      .text {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
        margin: 0px 10px;
      }
    }
  }

  &__ContextContainer {
    background-color: white;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;

    .rightContainer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-left: 10px;
      margin-right: 20px;
    }

    &__TitleContainer {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      margin: 5px 10px;
      align-items: center;

      .title {
        color: #534f4f;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  &__NotesAndComments {
    display: flex;
    height: calc(100vh - 70px);
    flex-direction: column;
    overflow: hidden;
    background-color: white;
    padding: 0px 10px;
    flex-shrink: 0;
    width: 30%;
    min-width: 400px;

    .activeTab {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 13px;
      background: #fafaff;
      color: $primary  !important;
      font-weight: bold;
      line-height: 20px;
      min-width: calc(33.3% - 20px) !important;
      text-transform: capitalize;
      letter-spacing: 0.3px;
    }

    .inactiveTab {
      background-color: #ffffff;
      font-size: 13px;
      min-width: calc(33.3% - 20px) !important;
      border-radius: 3px;
      color: #8b94a7;
      text-transform: capitalize;
      letter-spacing: 0.3px;
    }
  }

  &__transcriptVideoPlayerContainer {
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    // width: 55%;
    flex-grow: 1;
    max-width: calc(100% - 400px);
    animation-name: appear;
    animation-duration: 500ms;
    border-right: 1px solid #e0e6ef;
  }

  &__transcriptVideoPlayerContainerShared {
    height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e0e6ef;
  }

  &__videoContainerCorner {
    &.embed {
      display: none;
    }

    bottom: 0px;
    right: calc(30vw - calc(20vh * 16 / 9));
    position: fixed;
    width: calc(20vh * 16 / 9);
    height: 20vh;
    border-radius: 15px;
    z-index: 10;
    animation-name: appear;
    animation-duration: 500ms;

    &:hover {
      .Call__videoContainer__minimize {
        display: flex;
      }
    }
  }

  &__videoContainer {
    min-height: 200px;
    max-height: 600px;
    height: 100%;
    padding: 20px 40px 5px 40px;
    flex-grow: 1;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    animation-name: appear;
    animation-duration: 300ms;

    &__minimize {
      position: absolute;
      @include scaled("top", -10px);
      @include scaled("right", -10px);
      cursor: pointer;
      z-index: 3;
      background-color: #fff;
      display: none;
      @include scaled("border-radius", 10px);
      border: 2px solid white;
      @include scaled("height", 20px);
      @include scaled("width", 20px);
    }

    &__expand {
      @include scaled("height", 16px);
      @include scaled("width", 16px);
      margin-right: 10px;

      &Text {
        font-weight: 600;
        @include scaled("font-size", 14px);
        @include scaled("line-height", 17px);
        color: #ffffff;
      }
    }

    &__minimizedVideo {
      display: flex;
      align-items: center;
      flex-direction: row;
      bottom: 0px;
      right: calc(30vw - calc(20vh * 16 / 9));
      position: fixed;
      width: calc(20vh * 16 / 9);
      height: 40px;
      border-radius: 15px;
      z-index: 10;
      padding: 10px 10px;
      cursor: pointer;
      background: #2e5bff;
      box-shadow: 8px -12px 18px rgba(29, 42, 68, 0.05);
      border-radius: 5px;
      animation-name: videoMinimize;
      animation-duration: 400ms;
      transition: height 500ms;
    }

    &__fullScreen {
      position: absolute;
      cursor: pointer;
      bottom: 20px;
      right: 60px;
      height: 25px;
      width: 25px;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  &__videoContainerFullscreen {
    height: calc(100vh - 70px);
    max-height: calc(100vh - 70px);
  }

  &__TranscriptScrollContainer {
    overflow-y: auto;
    min-height: 200px;
    max-height: 600px;
    background-color: white;
    padding: 0px 8% 0% calc(8% - 40px);
    position: relative;
  }

  &__TranscriptSearchContainer {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid #e1e8ff;
    padding: 5px 10px 10px 10px;
    margin: 0px -10px;

    &__searchBar {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 36px;
      width: 90%;
      border: 0.5px solid #939393;
      border-radius: 24px;
      background-color: #f7f7f7;
      margin: 5px 0px;
      margin-left: -15px;

      &__input {
        width: 100%;
        padding: 0px 7px;
        padding-left: 15px;
      }

      &__buttons {
        margin-left: auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &-icon {
        margin: 0px 5px;
        cursor: pointer;
      }
    }

    &__resultBar {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 2px;

      &-icon {
        margin: 0px 5px;
        cursor: pointer;
      }

      &-text {
        color: #6170dc;
        font-size: 12px;
        margin: 0px 5px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 17px;
      }
    }
  }

  &__TranscriptContainer {
    height: calc(100vh - 200px);
    min-height: 150px;
    background-color: #fff;
    position: relative;

    &__Highlight {
      color: black;
      background-color: yellow;
    }

    &__autoScrollTooltip {
      display: flex;
      flex-direction: row;
      background: #2680EB;
      border-radius: 0px 0px 10px 10px;
      align-items: center;
      justify-content: center;
      position: fixed;
      top: 70px;
      cursor: pointer;
      padding: 10px;
      z-index: 10;
      width: 200px;
      left: calc(50vw - 200px);

      &Text {
        color: white;
        display: flex;
        justify-content: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #ffffff;
      }

      &Icon {
        height: 12px;
        margin: 0px 10px;
      }
    }
  }

  &__TranscriptContainerWithVideo {
    min-height: 150px;
    flex-grow: 1;
    background-color: #fff;
    overflow-y: auto;
  }

  &__CommentContainer {
    height: calc(100vh - 175px);
  }

  &__meetingIcon {
    height: 20px;
    min-width: 20px;
    margin: 0px 5px;
  }

  &__meetingIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
    border-radius: 17.5px;
    background-color: #f2f2f2;
    margin: 0px 5px;
  }

  &__transcriptMessageContainer {
    margin-left: calc(40px - 9%);
    margin-right: calc(0px - 9%);
  }
}

.Disconnect {
  &__CallContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__InfoContainer {
    outline: none !important;
    border-radius: 5px;
    background: white;
    @include scaled2("height", 442px);
    @include scaled2("width", 412px);
    min-width: 290px;
    min-height: 315px;

    &:focus {
      outline: none !important;
      border: 0px solid white !important;
    }
  }

  &__IconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ff1744;
    @include scaled2("height", 304px);
    @include scaled2("width", 412px);
    min-width: 290px;
    min-height: 216px;
    padding: 20px;
    border-radius: 5px;
  }

  &__ButtonsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: #FFFFFF; */
    /* border-radius: 5px; */
    /* margin-top: -20px; */
    padding-top: 20px;
    padding-bottom: 20px;
    /* z-index: -1; */
    position: relative;
  }

  &__Icon {
    @include scaled2("height", 120px);
    @include scaled2("width", 120px);
  }

  &__Info {
    color: white;
    margin-top: 35px;
    @include scaled2("font-size", 18px);
    @include scaled2("line-height", 20px);
    font-weight: 600;
    text-align: center;
  }

  &__Note {
    color: white;
    margin-top: 5px;
    @include scaled2("font-size", 12px);
    @include scaled2("line-height", 16px);
    font-weight: 500;
    text-align: center;
  }

  &__CloseIcon {
    @include scaled("height", 24px);
    @include scaled("width", 24px);
    cursor: pointer;
  }

  &__HeaderRight {
    display: block;
    text-align: right;
  }

  &__DisconnectPlugIcon {
    @include scaled("height", 154px);
    @include scaled("width", 154px);
    margin: 30px;
  }

  &__IntermediateContainer {
    padding: 15px;
  }

  &__CenterBody {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__IntermediateInfo {
    @include scaled2("font-size", 18px);
    font-weight: 600;
    margin-top: 15px;
  }
}

.Transcript {
  &__messageActions {
    background: #f7f7f7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    position: absolute;
    display: none;
    align-items: center;
    padding: 0px 10px;
    flex-direction: row;
    top: 10px;
    left: 100px;
    transition: top 500ms;
    @include scaled("height", 38px);
    z-index: 10;
    animation-name: appear;
    animation-duration: 600ms;

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 10px;
      cursor: pointer;

      &Icon {
        @include scaled("height", 22px);
        @include scaled("width", 22px);
      }
    }
  }

  &__correctionModal {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    display: none;
    flex-direction: column;
    top: 10px;
    left: 100px;
    transition: top 500ms;
    width: 20rem;
    height: 17.5rem;
    z-index: 10;
    animation-name: appear;
    animation-duration: 600ms;
    border-radius: 12px;
  }

}

/////////////////////
// Components
/////////////////////

.Message_selected {
  .Message__inner {
    //border: 1px solid #2E5BFF;
    transition: 1000ms all;
    box-shadow: 0 0 4px 2px #fff,
      /* inner white */
      0 0 4px 2px #3150d2;
    /* middle magenta */
  }
}

.Message {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  text-align: left;
  @include scaled("padding-top", 10px);
  @include scaled("padding-bottom", 10px);
  @include scaled("padding-left", 12px);
  @include scaled("padding-right", 12px);
  position: relative;

  &__sentence {}

  .Message__contentOld {
    width: 99%;
    color: #6c6c6c;
    @include scaled("font-size", 15px);
    @include scaled("line-height", 25px);
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    margin-left: 2px;
  }

  .Message__content {
    width: 99%;
    color: #6c6c6c;
    @include scaled("font-size", 15px);
    @include scaled("line-height", 25px);
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    margin-left: 1px;
    position: relative;

    ::selection {
      background: #eaeeff;
      color: #4971ff;
    }
  }

  .Message__active {
    background-color: #1E66BC;
    color: #fff;
    padding: 2px;
    border-radius: 3px;
    position: relative;
  }

  &[data-position="left"] {
    text-align: left;

    .Message__title {
      color: #f2752e;
    }
  }

  &__inner {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    width: 100%;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__badgesContainer {
    margin-left: auto;
    display: flex;
  }

  &__badge {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 3px;

    &-content {
      border-radius: 2px;
      background-color: #f0ebff;
      display: flex;
      text-transform: uppercase;
      color: #5f2eea;
      @include scaled("font-size", 10px);
      @include scaled("line-height", 13px);
      padding: 4px 8px;
      font-weight: 600;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &__actions {
    position: absolute;
    @include position(absolute, null 50px -10px null);
  }

  &__actionIcon {
    height: 15px;
    width: 15px;
  }

  p {
    text-align: left;
    margin-bottom: 0;
  }

  &__time {
    font-weight: 600;
    @include scaled("font-size", 11px);
    @include scaled("line-height", 21px);
    letter-spacing: 0.916667px;
    color: #5c5c5c;
    margin-right: 10px;
  }

  &__title {
    color: #2680EB;
    font-weight: 700;
    @include scaled("font-size", 12px);
    @include scaled("line-height", 21px);
  }

  &__jobTitle {
    font-weight: 500;
    @include scaled("font-size", 11.5px);
    @include scaled("line-height", 21px);
    color: #8798ad;
    margin: 0px 7px;
  }
}

.Reply {
  padding: 6px 6px;
  padding-left: 12px;
  border-bottom: 1px solid #cccccc;
  position: relative;
  background-color: #f2f2f2;

  &__text {
    margin: 0;
    font-size: 11px;
    color: #323c47;

    span {
      color: #2f86e6;
    }
  }

  &__viewReplyButtonContainer {
    justify-content: flex-end;
    display: flex;
  }

  &__viewReplyButton {
    margin: 5px;
    font-size: 10px;
    color: #2f86e6;
  }

  &__icon {
    border: 1px solid #979797;
    font-size: 10px;
    height: 20px;
    width: 20px;
    color: $primary;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    display: inline-flex;
    background-color: #e7e7e8;
  }

  &__time {
    float: right;
    color: #aaabac;
    font-size: 9px;
    line-height: 18px;
  }

  &__commenter {
    color: #566371;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 18px;
  }

  &__addReplyContainer {
    background-color: #f2f2f2;
  }
}

.Expansion {
  box-shadow: none !important;
  margin: 0 !important;

  &:last-child {
    border: 0;
  }

  &__head {
    padding: 0px 12px !important;
    min-height: 40px !important;
  }

  &__head-title {
    color: #3150d2 !important;
    font-size: 12px !important;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  &__content {
    margin-left: 5px;
    padding: 0 !important;
    display: block !important;
  }

  &__row {
    padding: 10px;
    text-align: right;
    display: flex;
    align-items: flex-start;

    &:hover {
      cursor: pointer;
    }

    &:last-child {
      border: 0;
    }
  }

  &__text {
    text-align: left;
    opacity: 0.9;
    color: #3d3d4f;
    font-size: 13px;
    font-weight: 400;
    margin: 0px 15px;
  }

  &__time {
    min-width: 50px;
    text-align: left;
    color: #8798ad;
    font-size: 12px;
    margin-top: 2px;
  }
}

.CallsDashboard {
  &__sectionHeader {
    display: flex;
    flex-direction: row;
    padding: 15px;
    border-bottom: 0.5px solid $secondary;
    height: 65px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__justifyRight {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    align-items: center;
  }

  &__pageInfo {
    margin: 0px 0px;
  }

  &__paginationButtons {
    margin: 0px 20px;
  }

  &__sectionTitle {
    margin-top: 5px;
    color: #304156;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
  }

  &__sectionPaper {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 140px);
  }

  &__completedCallsTable {
    flex-grow: 1;
    min-height: 0;

    .CallTable__container .Card__body {
      height: inherit;
    }
  }

  &__upcomingCalls {
    min-width: 300px;
    overflow-y: auto;
  }

  &__tabsContainer {
    display: flex;
    width: 320px;
    color: #121212;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    margin: 5px 0px;

    .TabContainer {
      display: flex;
      flex-direction: row;
      align-items: center;

      .inactiveTab {
        opacity: 0.5;
        color: #121212;
        font-size: 15px;
        cursor: pointer;
        padding: 10px;
      }

      .activeTab {
        color: #121212;
        font-size: 15px;
        font-weight: 500;
        border-bottom: 2px solid #3455cf;
        cursor: pointer;
        padding: 10px;
      }
    }
  }

  &__CallsContainer {
    margin: 0px;
  }
}

.AdhocCall__success {
  &Container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &Image {
    width: 200px;
    height: 200px;
  }

  &Copy {
    color: #3150d2;
    font-size: 18px;
    text-align: center;
  }
}

.CallCardWithBar {
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 5px !important;

  &__outerContainer {
    display: flex;
    flex-direction: row;
    margin: 0px 0px 0px 200px;
  }

  &__container {
    height: 75px;
    max-width: 200px;
    min-width: 200px;
    background-color: #ffffff;
    padding: 10px !important;
  }

  &__timeText {
    color: #808080;

    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
  }

  &__title {
    color: #333333;

    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
  }

  &__opportunity {
    color: #808080;

    font-size: 13px;
    line-height: 15px;
  }

  &__colorBar {
    margin-left: 10px;
    border-left: 1px solid #d1d2d4;
    display: flex;
    align-items: center;
  }

  &__tagLeft {
    height: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    &__bar {
      color: white;
      border-radius: 0 15px 15px 0;
      height: 100%;
    }

    &__barFilled {
      color: white;
      height: 100%;
    }

    &__value {
      margin-left: 10px;
    }
  }
}

.Loading {
  @include itemsCenter();
}

.TLRatioTooltip {
  display: flex;
  flex-direction: column;
  width: 300px;
  justify-content: center;
  padding: 5px;
  opacity: 1 !important;
  border: 1px solid #e1e8ff;
  border-radius: 4px;
  background-color: #ffffff !important;
  box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07) !important;

  &::after {
    border-bottom-color: #fff !important;
    border: 6px solid #e1e8ff;
    transform: rotate(180deg);
    top: -12px !important;
  }

  &__title {
    color: #8798ad;
    font-size: 13px;
    letter-spacing: 1.21px;
    line-height: 15px;
    margin: 10px 5px;
  }

  &__error {
    color: #d63649;
    padding: 5px;
  }

  &__errorDiv {
    background-color: #f6e8ea;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    padding: 5px;
  }

  &__name {
    color: #616161;
    font-size: 14px;
  }

  &__nameDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px 10px;
    width: 100%;
  }

  &__tlRow {
    display: flex;
    align-items: center;
    margin: 10px 5px;
  }

  &__percentageBar {
    background-color: #00c1d4;
    height: 3px;
  }

  &__value {
    color: #00c1d4;
    font-size: 22px;
    font-weight: 500;
    line-height: 22px;
    min-width: 50px;
  }
}

.VideoMaximize {
  height: 100px !important;
  align-items: flex-end;
  opacity: 0.2;
}

@keyframes videoMinimize {
  0% {
    height: 70px;
    opacity: 0;
    align-items: flex-end;
    animation-name: disappear;
    animation-duration: 200ms;
  }

  100% {
    height: 40px;
    opacity: 1;
    align-items: center;
  }
}

@keyframes disapper {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.PrivateCall {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  justify-content: center;

  &__Graphic {
    height: 40vh;
    margin-bottom: 20px;
  }

  &__header {
    font-weight: 600;
    font-size: 22px;
    line-height: 39px;
    text-align: center;
    color: #2680EB;
    margin-bottom: 5px;
  }

  &__subheader {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #8d98a9;
    margin-bottom: 15px;
  }
}


.CallsDashboard-Container {
  .CallTable__table__container {
    height: calc(100vh - 60px - 6rem);
    overflow-y: auto;
  }
}