.Graph-Detail {
  .Chart {
    width: 100%;
    &__body {
      width: 95% !important;
      margin-left: 10px;
    }

    &__head {
      margin-left: 10px;
    }
  }
  
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    &-time {
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 300;
    }
    
    &-action {
      & > * {
        margin-left: 10px !important;
      }
    }
  }

  &__padding {
    display: block;
    width: 100%;
    padding: 23px 0;
  }

  &__statistic {

    &-head {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-text {
        display: inline-flex;
        margin-left: 24px;
        font-size: 23px;
        color: #333333;
        text-transform: uppercase;
        font-weight: 500;
      }

      &-legend  {
        display: inline-flex;
        align-items: center;

        &-item {
          @include itemsCenter();
          margin-left: 32px;

          & > * {
            margin-left: 6px;
          }

          div {
            display: inline-block;
            width: 14px;
            height: 14px;
            border-radius: 50%;
          }

          span {
            font-size: 19px;
          }

          .win {
            background-color: #29B545;
          }

          .close {
            background-color: #FF7F7F;
          }

          .followup {
            background-color: #00BECD
          }
        }
      }
    }
  }

  &__details {
    &-container {
      @include itemsCenter();
      position: relative;
      justify-content: space-between;
    }

    .Card {
      box-shadow: 0 0 15px 0px rgba(0,0,0,0.12),;
    }

    &-progress {
      display: inline-flex;
      position: relative;
      width: 70%;
      height: fit-content;

      &-cont {
        width: 100%;
        height: 19px;

        span {
          position: absolute;
          right: 10px;
          bottom: -28px;
          color: #333333;
          font-size: 19.5px;
          font-weight: 500;
        }
      }

      .close-bar {
        background-color: #FF7F7F;
      }

      .win-bar {
        background-color: #29B545;
      }

      .followup-bar {
        background-color: #00BECD;
      }

      &-background {
        width: 100%;
        position: absolute;
        height: 100%;
        border-radius: 0 9.5px 9.5px 0;
        background: #E7E7E8;
      }
      &-bar {
        position: absolute;
        border-radius: 0 9.5px 9.5px 0;
        height: 100%;
      }
    }

    &-badge {
      &-content {
        padding: 6px 10px;
        border-radius: 20px;
        background-color: #ECE4EC;
        color: #4A1058;
        font-size: 16px;
      }
    }

    &-text {
      width: 25%;
      padding: 8px 14px;
      display: inline-flex;
  
      &-title {
        display: table;
        height: 15px;
        color: #333333;
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        margin-bottom: 6px;
      }
  
      &-time {
        display: table;
        height: 15px;
        color: #808080;
        font-size: 15px;
        line-height: 15px;
        margin-bottom: 6px;
      }
  
      &-opportunity {
        display: table;
        height: 12px;
        color: #808080;
        font-size: 13px;
        line-height: 15px;
      }
    }
  }
}

.line {
  width: 1px;
  height: 100%;
  position: absolute;
  background: #D1D2D4;
  left: calc(30% + 7px);
}
