@keyframes expand {
  0% {
    width: 10%;
  }

  100% {
    width: max-content;
  }
}

@keyframes bgActive {
  from {
    background-color: #f3f6ff;
    color: #2680EB;
  }

  to {
    background-color: #2680EB;
    color: #ffffff;
  }
}

@keyframes bgInactive {
  from {
    background-color: #2680EB;
    color: #ffffff;
  }

  to {
    background-color: #f3f6ff;
    color: #2680EB;
  }
}

.RightPanel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 10px;

  &__allTabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 60%;
    cursor: pointer;

    transition: all 500ms;
    @include scaled("height", 30px);
    margin: 0px 5px;
    padding: 0px 10px;
    @include scaled("border-radius", 60px);

    &Icon {
      @include scaled("height", 15px);
      @include scaled("width", 15px);
      margin: 0px 5px;
      margin-top: -2px;
    }

    &Text {
      font-weight: 500;
      @include scaled("font-size", 14px);
      @include scaled("line-height", 17px);
    }
  }

  &__activeTab {
    animation-name: bgActive;
    animation-duration: 0.5s;
    background: #2680EB;

    &Text {
      color: #ffffff;
    }

    &Number {
      margin-left: 5px;
      padding: 0px 3px;
      font-size: 9px;
      font-weight: 600;
      border-radius: 10px;
      background-color: #f3f6ff;
      color: #2680EB;
    }
  }

  &__inactiveTab {
    animation-name: bgInactive;
    animation-duration: 0.5s;
    background: #f3f6ff;

    &Text {
      color: #2680EB;
    }

    &Number {
      margin-left: 5px;
      padding: 0px 3px;
      font-size: 9px;
      font-weight: 600;
      border-radius: 10px;
      background-color: #5e81ff;
      color: #ffffff;
    }
  }

  &__tab {
    transition: all 500ms;

    &Icon {
      @include scaled("height", 34px);
      @include scaled("width", 34px);
      margin: 0px 0px;
      cursor: pointer;
    }
  }

  &__searchResults {
    display: flex;
    z-index: 3;
    left: 0;
    top: 100%;
    width: 100%;
    position: absolute;
    flex-direction: column;
    background: #f0f3ff;
    border-radius: 0px 0px 15px 15px;
    padding: 0px 10px 3px 20px;

    &Container {
      display: flex;
      flex-direction: column;
      padding: 10px;
      height: calc(100% - 90px);
      overflow-y: auto;
    }

    &__borderTop {
      height: 1px;
      margin-top: 2px;
      width: 100%;
      background-color: #2e5bff;
    }

    &Text {
      font-weight: 500;
      @include scaled("font-size", 15px);
      @include scaled("line-height", 15px);
      text-overflow: ellipsis;
      overflow-x: hidden;
      width: 90%;
      padding: 5px 0px;
      white-space: nowrap;
      color: #2680EB;
    }

    &Row {
      display: flex;
      flex-direction: column;
      padding: 8px 0px;
      cursor: pointer;

      &:hover {
        filter: hue-rotate(20deg);
      }
    }
  }

  &__searchBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding: 5px 0px !important;
    border-radius: 10px;
    border: 1px solid #d5deff;

    &:focus-within {
      border: 1px solid #2680EB  !important;
    }
  }

  &__searchTab {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 95%;
    @include scaled("height", 50px);
    margin: 10px 10px;
    animation-name: appear;
    animation-duration: 500ms;
    border-radius: 15px !important;

    &__expanded {
      border-radius: 15px 15px 0px 0px !important;
    }

    &Input {
      width: 100%;

      input {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 600;
        width: 100%;
        @include scaled("font-size", 15px);
        @include scaled("line-height", 15px);

        &::placeholder {
          font-weight: 500;
          flex-direction: row;
          align-items: center;
          @include scaled("font-size", 15px);
          @include scaled("line-height", 15px);
          color: rgba(46, 91, 255, 1) !important;
        }
      }

      margin-left: 2%;
      display: flex;
      flex-direction: row;
      align-items: center;

      &__endAdornment {
        display: flex;
        margin-left: auto !important;
      }
    }
  }
}