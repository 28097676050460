$activityHeightDealView: 65px;
$activityWidthDealView: 400px;
$repActivityColor: linear-gradient(180deg, #3131d8 0%, #316ad8 100%);
$customerActivityColor: linear-gradient(180deg, #fe3c3c 0%, #fe693c 100%);
$callsColor: #ed2e7e;
$outgoingEmailsColor: #3131d8;
$incomingEmailsColor: #ff6c2d;

.DealCard {
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(46, 91, 255, 0.07);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;

    &__columnEdit {
        &Div {
            position: absolute;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0px;
            padding: 16px 4px;
        }

        &Icon {
            @include scaled2("height", 24px);
            @include scaled2("width", 24px);
            opacity: 0;
        }
    }

    &__infoIcon {
        @include scaled("height", 13px);
        @include scaled("width", 13px);
        margin-left: 4px;
        filter: invert(0.9) grayscale(1);
    }

    &__competitorTag {
        margin: 2px 0px;
        display: flex;
        align-items: center;

        &-name {
            text-transform: capitalize;
            text-align: center;
        }
    }

    &__columnEditor {
        position: absolute;
        display: flex;
        flex-direction: column;
        z-index: 1000;
        padding: 4px;
        background: #ffffff;
        border: 1px solid #e5e7eb;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 15px 25px 35px rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        min-height: 60px;
        min-width: 200px;

        &-calendarTop {
            .react-date-picker__calendar--open {
                top: 100% !important;
                bottom: unset !important;
            }
        }

        &-calendarBottom {
            .react-date-picker__calendar--open {
                top: unset !important;
                bottom: -100% !important;
            }
        }

        &Copy {
            @include scaled2("font-size", 13px);
            @include scaled2("line-height", 16px);
            color: #6b778c;
            margin: 8px 2px 2px 2px;
        }
    }

    &Table {
        margin: 0px 20px;
        border-left: 1px solid #e5e7eb;
        border-right: 1px solid #e5e7eb;
        margin-bottom: 30px;
        overflow-x: auto;
        overflow-y: hidden;
        background-color: white;

        &__header {
            display: flex;
            align-items: center;
            flex-direction: row;
            font-weight: 600;
            @include scaled2("font-size", 15px);
            @include scaled2("line-height", 17px);
            color: #6b778c;
            z-index: 3;

            &SortIcon {
                @include scaled2("height", 16px);
                @include scaled2("width", 16px);
                margin-left: 10px;
            }

            &Column {
                cursor: pointer;
                display: flex;
                text-align: center;
                flex-direction: row !important;
                align-items: center;
                border-top: 1px solid #e0e6ef;
                border-bottom: 1px solid #e0e6ef;
                height: 55px !important;
                background-color: #fbfcff !important;
            }
        }
    }

    &__column {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 0px 10px;
        @include scaled2("min-width", 170px);
        @include scaled2("max-width", 170px);
        border-bottom: 1px solid #e0e6ef;
        height: 65px;
        background-color: #fff;
        transition: opacity 2s;

        &:hover {
            .DealCard__columnEditIcon {
                opacity: 1;
            }
        }

        &Saving {
            opacity: 0.7;
        }
    }

    &__label {
        font-weight: 600;
        @include scaled("font-size", 11px);
        @include scaled("line-height", 13px);
        color: #585e76;
        margin-bottom: 5px;
    }

    &__nameHeader {
        display: flex;
        position: sticky;
        left: 0px;
        z-index: 5;
        background-color: #fff;
        padding: 12px 25px;
        min-width: 20%;
        max-width: 20%;
        height: 65px;
        box-shadow: 3px 0px 5px 0px $secondary;
    }

    &__warning {
        display: flex;
        align-items: center;
        background-color: #ffe9e9;
        border-radius: 4px;
        height: fit-content;
        padding: 2px 4px;
        margin-left: 10px;
        position: relative;

        &Icon {
            @include scaled2("height", 16px);
            @include scaled2("width", 16px);
            margin-right: 6px;
        }

        &Number {
            color: #ff3a29;
            @include scaled2("font-size", 14px);
            @include scaled2("line-height", 24px);
            font-weight: 600;
        }

        &Popup {
            position: absolute;
            background: #fff;
            width: max-content;
            border-radius: 4px;
            box-shadow: 1px 2px 4px 1px $secondary;
            left: 50px;
            max-height: 50vh;
            overflow-y: auto;
            z-index: 1000;

            &Container {
                margin-bottom: 5px;
            }

            &Row {
                display: flex;
                padding: 5px 15px;
                align-items: flex-end;
            }

            &Header {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                width: 100%;
                border-radius: 4px;
                background-color: #ffe9e9;
                padding: 5px 15px;
                border-top: 1px solid #efefef;
                border-bottom: 1px solid #efefef;
                margin-bottom: 5px;
            }
        }
    }

    &__nameColumn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        left: 0px;
        z-index: 5;
        background-color: #fff;
        padding: 12px 12px 12px 24px;
        min-height: 65px;
        min-width: 20%;
        max-width: 20%;
        word-wrap: break-word;
        box-shadow: 3px 0px 5px 0px $secondary;
        border-bottom: 1px solid #e0e6ef;

        &-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &-name {
            font-weight: 700;
            @include scaled("font-size", 14px);
            @include scaled("line-height", 20px);
            color: #304156;
            overflow-wrap: anywhere;
        }
    }

    &__activity {
        &Column {
            display: flex;
            position: relative;
            justify-content: center;
            left: 0px;
            background-color: #fff;
            padding: 12px 20px;
            height: $activityHeightDealView;
            @include scaled2("min-width", $activityWidthDealView);
            @include scaled2("max-width", $activityWidthDealView);
            word-wrap: break-word;
            align-items: center;
            border-bottom: 1px solid #e0e6ef;
        }

        &Header {
            display: flex;
            position: relative;
            justify-content: center;
            left: 0px;
            background-color: #fff;
            padding: 8px 20px;
            height: $activityHeightDealView;
            @include scaled2("min-width", $activityWidthDealView);
            @include scaled2("max-width", $activityWidthDealView);
            word-wrap: break-word;
            align-items: center;
            border-bottom: 1px solid #e0e6ef;
        }
    }

    &__originLine {
        position: absolute;
        margin-top: ($activityHeightDealView - 21)/2;
        background-color: rgba(224, 230, 239, 0.5);
        width: 100%;
        height: 1px;
    }

    &__company {
        &Div {
            display: flex;
            flex-direction: column;
            background: #fff3f8;
            border-radius: 2px;
            width: fit-content;
            padding: 7px 0px;
            margin-top: 5px;
            border-left: 2px solid #ed2e7e;
            overflow: hidden;
            cursor: pointer;
        }

        &Row {
            display: flex;
            align-items: center;
            transition: margin-top 400ms ease;
            margin: 0px 7px;
        }

        &Arrow {
            margin-left: 5px;
        }

        &Name {
            font-weight: 700;
            @include scaled("font-size", 12px);
            @include scaled("line-height", 13px);
            color: #ed2e7e;
            margin: 0px 10px;

            &Dull {
                font-weight: 500;
                @include scaled("font-size", 12px);
                @include scaled("line-height", 15px);
                color: #8798ad;
                margin-top: 2px;
            }
        }

        &Icon {
            @include scaled("height", 16px);
            @include scaled("width", 16px);
        }
    }

    &__amount {
        font-weight: 600;
        @include scaled("font-size", 14px);
        @include scaled("line-height", 17px);
        color: #2eb844;
        text-align: center;
    }

    &__stage {
        font-weight: 600;
        background: #f0f3ff;
        border-radius: 2px;
        padding: 5px 10px;
        width: fit-content;
        font-weight: 600;
        text-align: center;
        color: #2680EB;
        @include scaled("font-size", 12px);
        @include scaled("line-height", 15px);
        text-transform: capitalize;

        &Lost {
            color: #e10606;
            background: #ffe6e6;
        }

        &Won {
            background: #e1ffe6;
            color: #2eb844;
        }
    }

    &__date {
        font-weight: 600;
        @include scaled("font-size", 14px);
        @include scaled("line-height", 17px);
        color: #304156;
        word-wrap: none;
        white-space: nowrap;
    }

    &__custom {
        font-weight: 600;
        @include scaled("font-size", 14px);
        @include scaled("line-height", 17px);
        color: #304156;
        word-wrap: none;
    }

    &__ownerDiv {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__activityDates {
        display: flex;
        position: absolute;
        width: 260px;
        justify-content: space-between;
        bottom: 0;
    }

    &__timelineDates {
        @include scaled2("font-size", 10px);
        width: 20px;
        margin: 0px 6px;
        white-space: nowrap;
        color: #8798ad;

        &Today {
            margin: 0px 18px !important;
        }
    }

    &__dateMark {
        @include scaled2("font-size", 10px);
        color: #c9d5ff;
        text-align: center;
        margin-right: -35px;

        &Today {
            margin-right: -12px !important;
        }
    }

    &__dateItems {
        color: #8798ad;
        @include scaled("font-size", 10px);
        font-weight: 500;
    }

    &__whiteBackground {
        position: absolute;
        height: calc(100% + 40px);
        width: calc(100% + 20px);
        background-color: inherit;
        z-index: -1;
        margin-left: -60px;
    }

    &__activityBar {
        background: #f2f4fa;
        border-radius: 6px;
        width: 6px;
        height: 45px;
        margin: 0px 6px;

        &__Big {
            height: 77px !important;
            width: 20px !important;
            border-radius: 26px;
            padding: 6px 0px;
        }

        &__segment {
            height: 33%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__icon {
            width: 12px;
        }
    }

    &__ActivityBarDealView {
        border-radius: 6px;
        width: 6px;
        height: 45px;
        margin: 0px 6px;
        background: #edf0ff;

        &__segmentUp {
            height: 50%;
            margin-bottom: -1%;
            transform: rotateZ(180deg);
        }

        &__segmentDown {
            height: 50%;
        }

        &__repActivity {
            background: $repActivityColor;
            border-radius: 0px 0px 10px 10px;
        }

        &__customerActivity {
            border-radius: 0px 0px 10px 10px;
            background: $customerActivityColor;
        }
    }
}