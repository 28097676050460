.hidden {
  display: none !important;
}

.PlayerFullScreen {
  flex-direction: row !important;
  justify-content: space-evenly !important;
  height: 60px !important;

  .Player__row {
    width: 100%;
  }

  .Timeline {
    margin-left: 40px;
    @include scaled("margin-bottom", 20px);
  }
}

.Player {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  z-index: 2;
  padding: 5px 10px;
  position: relative;
  height: min-content;
  max-height: calc(45%);

  &__iconButton {
    cursor: pointer;
    height: 25px;
    width: 25px;
    margin: 0px 10px;

    &:hover {
      transform: scale(1.1);
    }
  }

  &__row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__mainControls {
    width: 100%;
    margin: 0px auto;
    padding: 5px 0px;
    @include scaled("min-height", 60px);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
    justify-content: center !important;
    background: #F8FAFC;
    border-radius: 1rem;
  }

  &__mainControlsFullscreen {
    width: 100%;
    margin: 0px auto;
    padding: 5px 30px;
    @include scaled("min-height", 60px);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0px;
    justify-content: center !important;
    background: #F8FAFC;
    border-radius: 1rem;
  }

  &__titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__group {
    display: flex;
    position: relative;
  }

  &__center {
    display: flex;
    margin: 0px 30px;
  }

  &__info {
    width: 250px;
    margin: 0px 5px;
  }

  &__speakerTimelines {
    display: flex;
    min-height: 100px;
    height: fit-content;
    max-height: calc(100% - 75px);
    overflow-y: auto;
    flex-direction: column;
    margin: 5px 0px;
    margin-top: -5px;
  }

  &__iconContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__textContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  &__videoIcon {
    height: 20px;
    width: 20px;
    margin-right: 10px;

    &_light {
      filter: brightness(0) invert(1);
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }
  }

  &__speedMenu {
    position: absolute;
    background: #fff;
    bottom: 30px;
    right: 0px;
    border: 1px solid #e1e8ff;
    border-radius: 4px;

    &Row {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #1c48e2;
      border-top: 1px solid #e1e8ff;
      padding: 8px 16px;
      cursor: pointer;

      &:hover {
        background-color: $secondary;
      }
      &:first-child {
        border-top: none;
      }
    }
  }

  &__text {
    margin-left: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    &-title {
      display: flex;
      color: #2e384d;
      font-size: 12px;
      font-weight: 500;
      width: 100%;
      @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 3, $bgColor: white);
    }

    &-time {
      font-weight: normal;
      @include scaled("font-size", 13px);
      @include scaled("line-height", 15px);
      color: #8d98a9;
      margin-left: 2px;
      text-transform: uppercase;
    }

    &-opportunity {
      display: table;
      height: 12px;
      color: #808080;
      font-size: 13px;
      line-height: 15px;
    }
  }

  &__control {
    @include itemsCenter();

    &_playDiv {
      position: relative;
    }
    &_playIcon {
      max-width: none;
      display: inline;
      vertical-align: baseline;
      @include scaled("height", 40px);
      @include scaled("width", 40px);
      @include scaled("border-radius", 20px);
      margin: 0px 5px;
      
      &:hover {
        filter: brightness(90%);
      }

      &:active {
        filter: brightness(80%);
      }
    }

    &_dropdownIcon {
      max-width: none;
      display: inline;
      vertical-align: baseline;
      cursor: pointer;
      position: absolute;
      bottom: 0px;
      right: 0px;
      @include scaled("height", 17px);
      @include scaled("width", 17px);
    }

    &_dropdown {
      position: absolute;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      border: 1px solid #e1e8ff;
      box-sizing: border-box;
      box-shadow: -8px 12px 18px rgba(29, 42, 68, 0.128567);
      border-radius: 6px;
      z-index: 10;
      padding: 5px 0px;
      padding-right: 10px;

      &Item {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-height: 28px;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        padding: 2px;
        color: #1c48e2;
        white-space: nowrap;
        cursor: pointer;

        &:hover {
          color: #043afa;
        }
      }
    }

    &_skipIcon {
      max-width: none;
      display: inline;
      vertical-align: baseline;      
      height: 15px;
      width: 15px;
      margin: 0px 5px;
      margin-top: 5px;

      &:hover {
        filter: brightness(90%);
      }

      &:active {
        filter: brightness(80%);
      }
    }
  }

  &__volume {
    width: 30px;
    @include itemsCenter();
    margin: 0px 15px;
    position: relative;

    &-sliderContainer {
      height: 100px;
      width: 25px;
      position: absolute;
      bottom: 33px;
      border: 1px solid #fafafa;
      box-shadow: 0 2px 2px 0 rgba(114, 114, 114, 0.5);
      padding: 10px 5px;
      left: 2px;
      background-color: white;
    }

    &-icon {
      height: 25px;
      width: 25px;

      &Container {
        height: 30px;
        width: 30px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f5f7ff;
      }
    }

    &-slider {
      -webkit-appearance: none;
      width: 100%;
      height: 5px;
      border-radius: 5px;
      background: #d3d3d3;
      outline: none;
      -webkit-transition: 0.2s;
      transition: opacity 0.2s;
    }

    &-slider::-webkit-slider-thumb {
      appearance: none;
      @include size(13px);
      border-radius: 50%;
      background: #4a2458;
      cursor: pointer;
    }

    &-slider::-moz-range-thumb {
      @include size(13px);
      border-radius: 50%;
      background: #4a2458;
      cursor: pointer;
    }
  }

  &playIcon {
    width: 20px;
    height: 20px;
    border-radius: 10px;
  }

  &forwardIcon {
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }

  &reverseIcon {
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
}

.ToggleShowVideo {
  background: #F3F4F6;
  border: 0.5px solid #E5E7EB;
  width: 75%;
  border-radius: 8px;
  padding: 0px 1px;
  margin: 0px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: relative;

  &__activeTabBackground {
    background: white;
    padding: 4px 10px;
    min-width: 60px;
    width: 50%;
    display: flex;
    color: #2680EB;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    position: absolute;
    transition: 500ms left;
    height: 100%;
  }

  &__tab {
    padding: 4px 7px;
    width: calc(50% - 10px);
    display: flex;
    margin: 0px 2px;
    justify-content: space-around;
    align-items: center;
    border-radius: 7px;
    cursor: pointer;
    z-index: 2;
    background-color: transparent;

    &Icon {
      height: 14px;
      width: 14px;
      margin-right: 2px;
    }

    &Label {
      width: 75%;
      font-weight: 500;
      font-size: 11px;
      text-align: center;
      line-height: 15px;
      color: #6B7280;
      z-index: 2;
    }

    &Active {
      &Label {
        color: #2680EB;
      }

      &Icon {
        filter:  invert(100%) sepia(0%) saturate(7500%) hue-rotate(228deg) brightness(99%) contrast(104%);
      }
    }

    &Deactive {
      &Icon {
        filter:  invert(36%) sepia(95%) saturate(2055%) hue-rotate(198deg) brightness(99%) contrast(86%);
      }
    }
  }
}

.Timeline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  padding: 5px 10px;
  @include scaled("margin-bottom", 5px);

  &__firstRow {
    margin-top: 18px !important;
    margin-bottom: 8px !important;
  }

  &__pointerLine {
    height: 20px;
    width: 2px;
    position: absolute;
    left: calc(50% - 1px);
    top: 0px;
  }

  &__thumbnail {
    position: fixed;
    transition: left 100ms;
    bottom: 20px;
    z-index: 10;

    &CatchmentArea {
      position: absolute;
      height: 20px;
      width: 100%;
      z-index: 5;
      top: -6.5px;
      background-color: transparent;
    }

    &CatchmentAreaBookmark {
      height: 30px;
      top: -15.5px;
    }

    &Inner {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 99999999;
    }

    &Icon {
      width: 220px;
      border-radius: 10px;
      height: 124px;
      object-fit: none;
      object-position: 0px 0px;
    }

    &Time {
      position: absolute;
      bottom: 5px;
      text-align: center;
      font-size: 10px;
      font-weight: bold;
      color: #fff;
      background-color: #1E66BC;
      padding: 2px 5px;
      border-radius: 5px;
    }
  }

  &__speaker {
    font-weight: 600;
    @include scaled("font-size", 14px);
    @include scaled("line-height", 14px);
    color: #2d3958;
    margin-right: 10px;

    &Row {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 5px;
      margin-top: 7px;

      &__unselected {
        opacity: 0.5;
      }
    }

    &SelectIcon {
      height: 12px;
      margin: 0px 5px;
    }

    &Title {
      font-weight: 500;
      @include scaled("font-size", 12px);
      @include scaled("line-height", 14px);
      color: #8798ad;

      &Account {
        margin-left: 0px;
      }
    }

    &Percentage {
      font-weight: 500;
      @include scaled("font-size", 12px);
      @include scaled("line-height", 14px);
      color: #8798ad;
      display: flex;
      margin-left: auto;
    }
  }

  &__progressMain {
    margin-top: 2px;
    width: 100%;
    position: relative;
  }

  &__progress {
    margin-top: 2px;
    width: 100%;
    position: relative;

    &-highlightsIcon {
      @include scaled("height", 11px);
      @include scaled("width", 11px);
      position: absolute;
      top: -14px;
    }

    &-spectrum {
      bottom: calc(50% + 10px);
      width: 100%;
      height: 7px;
      width: 100%;
      background: #f6f6f6;
      border-radius: 5px;

      &-opportunity {
        position: absolute;
        background-color: #f2752e;
        top: 0%;
      }

      &-agent {
        position: absolute;
        background-color: #2680EB;
        top: 0%;
      }

      &-waveform {
        height: 7px;
        border-radius: 5px;
        width: 100px;
        display: inline-flex;
      }
    }

    &__CardsShown {
      position: absolute;
      bottom: -10px;
      width: calc(90vw);
      left: calc(-15vw - 20px);
      z-index: 10;

      &__cardIconDiv {
        position: absolute;

        .cardDetails {
          position: absolute;
          z-index: 2147483638;
          padding: 5px;
          border: 1px solid #e1e8ff;
          border-radius: 4px;
          background-color: #ffffff;
          height: 60px;
          width: 200px;
          box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
          top: calc(-60px);
          left: -100px;
        }

        .battlecardIcon {
          border: 1px solid #e1e8ff;
          color: $primary;
          background-color: $primary;
          color: $primary;
          line-height: 10px;
          font-size: 10px;
          height: 10px;
          cursor: pointer;
        }

        .behaviourCardIcon {
          border: 1px solid #e1e8ff;
          color: red;
          background-color: red;
          line-height: 10px;
          font-size: 10px;
          height: 10px;
          cursor: pointer;
        }
      }
    }

    &-background {
      @include absoluteCenter("");
      width: 100%;
      height: 6.67px;
      background: #ececec;
      border-radius: 5px;
    }

    &-cont {
      width: 100%;
      height: 100%;
      @include absoluteCenter("y");

      &-bar {
        width: 100%;
        height: 6.67px;
        border-radius: 3px;
        background-color: #2680EB;
        @include absoluteCenter("y");
      }
    }

    &-handle {
      margin-left: -6px;
      height: 13px;
      width: 13px;
      border: 2px solid #ffffff;
      border-radius: 6.5px;
      background-color: #2680EB;
      box-shadow: 0 2px 5px 0 rgba(46, 91, 255, 0.4);
      @include absoluteCenter("y");
      transition: opacity 150ms;
    }

    &-line {
      position: absolute;
      opacity: 0.4;
      width: 1px;
      top: 10px;
      left: 0px;
      height: 100px;
      z-index: 10;
      background-color: #6171dc;
      transform: rotate(180deg);
    }
  }

  &__playing-time {
    display: flex;
    flex-direction: row;
    align-items: center;

    &-text {
      color: #2e384d;
      font-size: 12px;
      font-weight: 500;
      margin-top: 8px;
    }
  }
}
