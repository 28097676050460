.Chart {
  width: 98%;

  &__valueIcon {
    height: 20px;
    width: 20px;
  }

  &__head {
    display: flex;
    justify-content: flex-start;
    padding: 5px 20px;
    align-items: flex-start;

    &-avg {
      width: 90px;
      @include itemsCenter();
      flex-direction: column;

      &-number {
        font-size: 31px;
        font-weight: 700;
        line-height: 36px;
      }

      &-string {
        font-size: 14px;
        font-weight: 500;
        line-height: 10px;
      }
    }

    &-change {
      margin-left: 20px;
      height: 24px;
      @include itemsCenter();
      border-radius: 10px;
      border: 1px solid #cccccc;

      &-percent {
        margin: 2px 2px 2px 8px;
        color: #333333;
        font-size: 15px;
        font-weight: 500;
      }

      img {
        margin: 2px 8px 2px 2px;
      }
    }
  }

  &__hidden {
    position: relative;

    &-top-line {
      height: 10px;
      width: 100%;
      position: absolute;
      background-color: #fff;
      z-index: 1;
    }
  }
}
