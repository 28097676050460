.AddCallScriptModal {
  padding: 0;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &__card {
    width: 500px;
    height: auto;
    border-color: black;
    padding: 20px;
  }

  &__questionField {

  }

  &__error {
    color: red
  }

  &__questionList {
    color: black;
  }
}