.InfoTooltip {
	width: 180px;
	text-align: left !important;
	padding: 10px;
}

.WhiteTooltip {
	background-color: white !important;
	border-radius: 5px !important;
	padding: 5px 10px !important;
	box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
	border: 0.2px solid #e1e8ff;
	display: flex !important;
	flex-direction: column;
	max-width: 250px;
	opacity: 1 !important;

	&__noPadding{
		padding: 0px !important;
	}
	&__mainCopy {
		font-size: 12px;
		color: #301456;
		font-weight: 600;
		margin-bottom: 5px;
	}

	&__subCopy {
		font-size: 11px;
		color: #301456;
		font-weight: 500;
		margin-bottom: 5px;
	}
}
