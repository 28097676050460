$DealViewHeaderHeight: 60px;
$MarginVertical: 130px;

$dealStageViolet: #750fa5;
$dealStageYellow: #ffbc01;
$dealStageBlue: #078cd7;
$dealStageGreen: #07d79c;
$dealStageRed: #f6576e;
$dealStagePurple: #5925c7;

.DealViewTimeline {
    display: flex;
    flex-direction: row;
    width: 6%;
    height: calc(100vh - 80px);
    border-right: 1px solid #e4e8eb;
    background-color: #fff;
    padding: 0px 0px;

    &__heading {
        font-weight: 600;
        @include scaled2("font-size", 11px);
        @include scaled2("line-height", 13px);
        letter-spacing: 0.9px;
        text-transform: uppercase;
        color: #828d99;
        padding: 5px 0px;
        border-bottom: 1px solid #f0f1f2;
        text-align: center;
        margin-bottom: 5px;
    }

    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    &__stageChangeDiv {
        display: flex;
        max-width: 2px;
        height: 100%;
        flex-direction: column;
    }

    &__stageChange {
        display: flex;
        max-width: 2px;
    }

    &__timePeriods {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }

    &__period {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        flex-grow: 1;
        flex-wrap: wrap;
        width: 100%;
        height: calc(calc(100vh - 200px) / 8);
        transition: background 500ms;

        &:hover {
            .DealViewTimeline__periodStartDate {
                display: flex;
            }

            .DealViewTimeline__periodEndDate {
                display: flex;
            }

            .DealViewTimeline__period__dates {
                display: flex;
            }
        }

        &__dates {
            display: none;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 100%;
            background: #ffffff;
            border: 1px solid #e1e8ff;
            box-shadow: 0px 10px 20px rgba(46, 91, 255, 0.07);
            border-radius: 4px;
            z-index: 6;
            width: 200px;
            height: 40px;

            &Value {
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: #304156;
            }
        }

        &StartDate {
            display: none;
            position: absolute;
            font-size: 10px;
            right: 10px;
            top: 0px;
        }

        &EndDate {
            display: none;
            position: absolute;
            font-size: 10px;
            right: 10px;
            bottom: 0px;
        }

        &Active {
            background-color: #e9edff;

            &:hover {
                background-color: #e9edff;
            }
        }

        &IconDiv {
            display: flex;
            flex-direction: row;
            position: relative;
            cursor: pointer;
        }

        &IconNum {
            position: absolute;
            @include scaled2("height", 12px);
            @include scaled2("width", 12px);
            padding: 2px;
            @include scaled2("font-size", 8px);
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            background-color: red;
            border-radius: 7px;
            top: 0px;
            right: 0px;
        }
    }

    &__dateInfo {
        @include scaled2("font-size", 10px);
        @include scaled2("line-height", 12px);
        font-style: normal;
        font-weight: bold;
        padding: 5px 0px;
        display: flex;
        justify-content: center;
        text-align: center;
        color: #b9d4e7;

        &Active {
            background-color: #e9edff;
            color: #91c2e5;
        }
    }

    &__Icon {
        margin: 5px 3px;
        @include scaled2("height", 20px);
        @include scaled2("width", 20px);

        &Left {
            margin: 5px 3px;
            @include scaled2("height", 22px);
            @include scaled2("width", 22px);
            // margin-left: -20px;
        }

        &Right {
            margin: 5px 3px;
            @include scaled2("height", 22px);
            @include scaled2("width", 22px);
            //margin-left: 20px;
        }
    }
}

.DealView {

    &__LoadMoreButton {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 16px;
        width: 250px;
        height: 32px;
        background: #2680EB;
        border-radius: 5px;
        margin: 10px auto;
        color: white;
    }

    &__HeadingBig {
        background: #faf9f8;
        width: 100%;
        padding: 10px 15px;
        font-weight: 600;
        @include scaled2("font-size", 16px);
        @include scaled2("line-height", 19px);
        border-right: 0.3px solid $secondary;
    }

    &__interactionsContainerViolet {
        &::after {
            content: "";
            background-color: $dealStageViolet  !important;
        }
    }

    &__interactionsContainerYellow {
        &::after {
            content: "";
            background-color: $dealStageYellow  !important;
        }
    }

    &__interactionsContainerRed {
        &::after {
            content: "";
            background-color: $dealStageRed  !important;
        }
    }

    &__interactionsContainerBlue {
        &::after {
            content: "";
            background-color: $dealStageBlue  !important;
        }
    }

    &__interactionsContainerGreen {
        &::after {
            content: "";
            background-color: $dealStageGreen  !important;
        }
    }

    &__interactionsContainerPurple {
        &::after {
            content: "";
            background-color: $dealStagePurple  !important;
        }
    }

    &__interactionsContainer {
        display: flex;
        position: relative;
        flex-direction: column;
        margin-top: 10px;

        &::after {
            content: "";
            background-color: #7c44f2;
            transform: rotate(0deg);
            height: calc(100% - 17px);
            @include scaled2("width", 1.5px);
            @include scaled2("top", -5px);
            left: 7px;
            position: absolute;
            margin: 0px 2px;
            z-index: 1;
        }
    }

    &__EmailAttendeePopup {
        right: 0px;
        top: 15px !important;

        // override min-width to make popup look neat in email to address preview alone
        .DealView__AttendeePopup__headingSmall {
            padding: 10px;
        }

        .DealView__AttendeePopup__contactContainer {
            max-width: unset;
        }
    }

    &__AttendeePopupSmall {
        left: 0px;
        top: 15px !important;

        &__contact {
            &Container {
                position: relative;
                display: flex;
                flex-direction: column;
                margin: 0px 10px;
                @include scaled2("height", 40px);
            }

            &Icon {
                @include scaled2("height", 20px);
                @include scaled2("width", 20px);
                margin-right: 5px;
            }

            &NameContainer {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            &Name {
                font-weight: bold;
                @include scaled2("font-size", 11px);
                @include scaled2("line-height", 14px);
                color: #304156;
            }

            &Job {
                @include scaled2("font-size", 10px);
                @include scaled2("line-height", 11px);
                color: #304156;
            }
        }

        &__heading {
            font-weight: 600;
            @include scaled2("font-size", 12px);
            @include scaled2("line-height", 14px);
            color: #304156;
            margin-bottom: 2px;
            padding: 5px;
            border-bottom: 1px solid #f0f1f2;
        }

        &__headingSmall {
            font-weight: normal;
            @include scaled2("font-size", 10px);
            @include scaled2("line-height", 12px);
            color: #828d99;
            padding: 5px 0px 0px 5px;
        }

        &__contactContainer {
            margin: 3px 0px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px 7px;
        }

        &__repsContainer {
            margin: 3px 0px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px 7px;
        }
    }

    &__AttendeePopup {
        display: flex;
        flex-direction: column;
        position: fixed;
        max-height: 32vh;
        overflow-y: auto;
        border: 1px solid #e1e8ff;
        border-radius: 4px;
        background-color: #ffffff;
        box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
        padding-bottom: 10px;
        min-width: 30vw;
        margin-top: 4rem;
        z-index: 20;

        &__heading {
            font-weight: 600;
            @include scaled2("font-size", 16px);
            @include scaled2("line-height", 19px);
            color: #304156;
            margin-bottom: 2px;
            padding: 10px;
            border-bottom: 1px solid #f0f1f2;
        }

        &__headingSmall {
            font-weight: normal;
            @include scaled2("font-size", 13px);
            @include scaled2("line-height", 16px);
            color: #828d99;
            padding: 5px 10px;
        }

        &__contact {
            &Container {
                position: relative;
                display: flex;
                align-items: center;
                flex-direction: row;
                margin: 5px 10px;
            }

            &Icon {
                @include scaled2("height", 30px);
                @include scaled2("width", 30px);
                margin-right: 5px;
            }

            &NameContainer {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            &Name {
                font-weight: bold;
                @include scaled2("font-size", 13px);
                @include scaled2("line-height", 16px);
                color: #304156;
            }

            &Label {
                @include scaled2("font-size", 13px);
                @include scaled2("line-height", 20px);
                color: #828d99;
            }

            &Job {
                font-size: 14px;
                color: #304156;
            }
        }

        &__repsContainer {
            margin: 5px 0px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px 15px;
        }
    }

    &__stageChange {
        &Div {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: fit-content;
            min-height: 20px;
            max-height: 20px;
            position: relative;

            &:after {
                content: "";
                position: absolute;
                left: calc(100% - 7px);
                height: 14.1px;
                width: 14.1px;
                transform: rotate(45deg);
                background-color: inherit;
            }

            &Outer {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                min-height: 20px;
                max-height: 20px;
            }
        }

        &Icon {
            @include scaled2("width", 23px);
            @include scaled2("height", 23px);
        }

        &Date {
            display: flex;
            font-weight: 400;
            letter-spacing: 0.2px;
            color: #ffffff;
            @include scaled2("font-size", 10px);
            @include scaled2("line-height", 12px);
            margin-left: 10px;
            margin-right: 10px;
        }

        &Text {
            display: flex;
            font-weight: 600;
            letter-spacing: 0.2px;
            color: #ffffff;
            @include scaled2("font-size", 11px);
            @include scaled2("line-height", 12px);
            margin-left: 5px;
        }
    }

    &__Header {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: $DealViewHeaderHeight;
        background: $white;
        border-bottom: 1px solid #e4e8f0;
        z-index: 10;

        .BackButton {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            @include scaled2("height", 40px);
            @include scaled2("width", 50px);
            border-radius: 0px 4px 4px 0px;
            background-color: #E5F0FD;
            margin-left: -30px;
            cursor: pointer;

            .label {
                color: $primary;
                @include scaled2("font-size", 16px);
                font-weight: 500;
                @include scaled2("line-height", 32px);
            }
        }

        &__dealName {
            font-style: normal;
            font-weight: 600;
            @include scaled2("font-size", 18px);
            @include scaled2("line-height", 22px);
            color: #304156;
            margin-left: 5px;
        }

        &__dealIcon {
            @include scaled2("width", 32px);
            @include scaled2("height", 32px);
            margin-left: 20px;
        }

        &__metaContainer {
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin: 0px 20px;
        }

        &__fieldsContainer {
            display: flex;
            align-items: center;
            flex-direction: row;
            padding: 10px 0px;
            padding-left: 16px;
            @include scaled2("height", 50px);
            justify-content: space-evenly;
            border-left: 1px solid #e1e6ed;
        }

        &__nameContainer {
            display: flex;
            align-items: center;
            flex-direction: row;
            padding: 10px 10px;
        }

        &__company {
            &Div {
                display: flex;
                align-items: center;
                margin-left: 30px;
            }

            &Name {
                font-weight: 600;
                @include scaled2("font-size", 20px);
                @include scaled2("line-height", 29px);
                color: #304156;
                margin-left: 10px;
            }

            &Icon {
                @include scaled2("height", 22px);
                @include scaled2("width", 22px);
            }
        }

        &__dealField {
            &Div {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                margin: 5px 0px;
            }

            &Icon {
                @include scaled2("width", 26px);
                @include scaled2("height", 26px);
                margin-right: 5px;
            }

            &Value {
                font-weight: 600;
                @include scaled2("font-size", 12px);
                @include scaled2("line-height", 15px);
                color: #2d3958;
            }

            &Label {
                @include scaled2("font-size", 11px);
                @include scaled2("line-height", 13px);
                color: #8798ad;
            }

            &LabelValueDiv {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
            }
        }

        &__owner {
            &Container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 0px 20px;
                padding: 0px 10px;
                @include scaled2("height", 50px);
                border-left: 1px solid #e1e6ed;
            }

            &NameIconDiv {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            &Name {
                font-weight: 600;
                @include scaled2("font-size", 13px);
                @include scaled2("line-height", 16px);
                color: #304156;
                margin-left: 5px;
            }

            &Label {
                @include scaled2("font-size", 13px);
                @include scaled2("line-height", 20px);
                color: #828d99;
            }
        }

        &__contact {
            &Container {
                position: relative;
                display: flex;
                flex-direction: column;
                margin: 0px 20px;
                padding: 0px 10px;
                @include scaled2("height", 50px);
                border-left: 1px solid #e1e6ed;
                border-right: 1px solid #e1e6ed;
                justify-content: center;
            }

            &AllNameContainer {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            &Icon {
                margin-right: 5px;
            }

            &NameContainer {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            &ViewAllContainer {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 0px 10px;
                min-height: 30px;
            }

            &ViewAll {
                @include scaled2("font-size", 14px);
                font-weight: 600;
                @include scaled2("line-height", 16px);
                color: #2680EB;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }

            &Plus {
                font-weight: 500;
                @include scaled2("font-size", 11px);
                @include scaled2("line-height", 18px);
                color: #828d99;
            }

            &Name {
                font-weight: bold;
                font-size: 14px;
                color: #304156;
            }

            &Label {
                @include scaled2("font-size", 13px);
                @include scaled2("line-height", 20px);
                color: #828d99;
            }

            &Job {
                @include scaled2("font-size", 11px);
                @include scaled2("line-height", 13px);
                color: #304156;
            }
        }

        &__rightContainer {
            display: flex;
            flex-direction: row;
            margin: 0px 10px;
            margin-left: auto;
        }

        &__button {
            &Container {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            &Icon {
                display: flex;
                margin-right: 5px;
            }
        }
    }

    &__Interactions {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 25%;
        height: calc(100vh - 60px);

        &Scroll {
            height: calc(100vh - 60px - 160px);
            overflow-y: auto;

            &Big {
                height: calc(100vh - 60px - 52px);
                overflow-y: auto;
            }
        }
    }

    &__blurBackground {
        opacity: 0.3;
        pointer-events: none;
    }

    &__MidSection {
        width: 75%;
        height: calc(100vh - 60px);
    }

    &__BottomMidSection {
        display: flex;
        height: calc(100% - 120px);
    }

    &__AccountActivity {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 120px;
        overflow-y: auto;
        background-color: #fff;
        padding: 0px;
        border-bottom: 1px solid #e4e8eb;

        &__Container {
            display: flex;
            align-items: center;
        }

        &__Timeline {
            display: flex;
            flex-direction: column;
            margin: 0px 16px;
            width: 95%;
            overflow: hidden;
        }

        &__Dates {
            display: flex;
            margin-bottom: 8px;
            margin-left: -18px;
        }

        &__Bars {
            display: flex;
        }

        &__ScrollArrows {
            margin-top: 30px;
            cursor: pointer;
            width: 22px;
        }

        &__LegendsContainer {
            margin: 3% 0% 0% 5%;
        }
    }

    &__InteractionView {
        display: flex;
        flex-direction: column;
        width: 70%;
        height: 100%;
        background-color: #fff;
        padding: 35px 35px 0px 35px;
    }

    &__InfoPanel {
        display: flex;
        flex-direction: column;
        width: 35%;
        height: 100%;
        border-left: 1px solid #e4e8eb;
        background-color: #fff;
        padding-bottom: 80px;

        &__SectionContainer {
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
        }

        &__Header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            width: 100%;
            background-color: #fafbfb;
            padding: 10px 15px;
            border-top: 1px solid #efefef;
            border-bottom: 1px solid #efefef;
            margin-bottom: 5px;

            &Title {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &Icon {
                @include scaled2("height", 28px);
                @include scaled2("width", 28px);
                margin-right: 10px;
            }

            &Text {
                font-weight: 600;
                @include scaled2("font-size", 16px);
                @include scaled2("line-height", 22px);
                color: #2d3958;

                &Warning {
                    color: #ff3a29;
                }
            }

            &Arrow {
                @include scaled2("height", 22px);
                @include scaled2("width", 22px);
                margin-right: 0px;
            }
        }

        &__Section {
            display: flex;
            flex-direction: column;

            &Row {
                display: flex;
                padding: 10px 15px;
                align-items: flex-end;
            }

            &Text {
                font-weight: 400;
                @include scaled2("font-size", 14px);
                @include scaled2("line-height", 18px);
                color: #304156;

                &Warning {
                    @include scaled2("width", 16px);
                    @include scaled2("height", 16px);
                    background-color: #ff3a29;
                    margin-right: 10px;
                    border-radius: 50%;
                    display: inline-block;
                    transform: scale(0.5, 0.5);
                }
            }

            &Item {
                display: flex;
                align-items: center;
                margin-right: auto;
                overflow-wrap: anywhere;
            }

            &NameContainer {
                display: flex;
                flex-direction: column;
            }

            &Icon {
                @include scaled2("height", 26px);
                @include scaled2("width", 26px);
                margin-right: 10px;
            }

            &Name {
                font-weight: 500;
                @include scaled2("font-size", 14px);
                @include scaled2("line-height", 17px);
                color: #304156;
            }

            &Job {
                @include scaled2("font-size", 12px);
                @include scaled2("line-height", 15px);
                color: #828d99;
                margin-top: 4px;
            }

            &Activity {
                &Container {
                    display: flex;
                    margin-top: 4px;
                }

                &Row {
                    display: flex;
                    align-items: center;
                    @include scaled2("width", 35px);
                    margin: 0px 4px;
                }

                &Type {
                    @include scaled2("font-size", 12px);
                    @include scaled2("line-height", 15px);
                    color: #828d99;
                    margin-right: 4px;
                }

                &Number {
                    @include scaled2("font-size", 14px);
                    @include scaled2("line-height", 18px);
                }

                &Icon {
                    @include scaled2("width", 18px);
                    @include scaled2("height", 18px);
                    margin-right: 2px;
                }
            }
        }
    }
}

.InteractionCard {
    display: flex;
    flex-direction: row;
    background-color: #f7f9fc;
    padding: 30px 10px 10px 10px;
    width: 100%;
    border: 1px solid #e1e6ed;
    cursor: pointer;

    @include scaled2("max-height", 200px);

    &__iconColumn {
        display: flex;
        margin-left: 0px;
        margin-right: 10px;

        &Icon {
            margin: 10px;
            @include scaled2("height", 30px);
            @include scaled2("width", 30px);
        }
    }

    &Active {
        border: 0.5px solid $primary;
        background-color: #fff;
        border-right: 0px;
        border-left: 3px solid $primary;
    }

    &__contentColumn {
        display: flex;
        width: calc(100% - 35px);
        flex-direction: column;
        margin-left: 5px;
    }

    &__stats {
        &Container {
            display: flex;
            flex-direction: row;
            align-items: center;
            border-top: 1px solid #f0f1f2;
            margin-top: 10px;
            padding-top: 5px;
        }

        &Label {
            @include scaled2("font-size", 13px);
            @include scaled2("line-height", 15px);
            letter-spacing: 0.2px;
            color: #686871;
        }

        &Value {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            color: #304156;
            margin: 0px 5px;
            margin-right: 20px;
        }
    }

    &__subject {
        font-weight: 600;
        @include scaled2("font-size", 16px);
        @include scaled2("line-height", 18px);
        color: #25252b;
        margin-top: 4px;
        width: 80%;
    }

    &__bodyPreview {
        @include scaled2("font-size", 14px);
        color: #686871;
        overflow: hidden;
        @include scaled2("line-height", 20px);
        @include scaled2("max-height", 40px);
        margin: 10px 0px 10px 0px;
        width: 95%;
    }

    &__namesContainer {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-top: 10px;
    }

    &__fromNameContainer {
        display: block;
        margin-top: -14px;
        margin-bottom: 5px;
        margin-left: -2px;
        max-width: 80%;
    }

    &__fromNameIcon {
        @include scaled2("height", 16px);
        @include scaled2("width", 16px);
        margin-right: 2px;
    }

    &__fromNameIconContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__toNameContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__fromToLabel {
        @include scaled2("font-size", 10px);
        @include scaled2("line-height", 16px);
        letter-spacing: 0.2px;
        color: #686871;
        margin-right: 8px;
        margin-bottom: 2px;
    }

    &__viewAllLink {
        @include scaled2("font-size", 10px);
        @include scaled2("line-height", 12px);
        letter-spacing: 0.2px;
        font-weight: bold;
        cursor: pointer;
        margin: 3px;

        &:hover {
            text-decoration: underline;
        }

        &Div {
            position: relative;
            margin-right: 5px !important;
            margin-left: 5px !important;
            margin-top: -2px;
            float: left;
        }
    }

    &__contact {
        &Name {
            font-weight: 500;
            @include scaled2("font-size", 12px);
            @include scaled2("line-height", 15px);
            letter-spacing: 0.2px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            color: #686871;
        }

        &Title {
            @include scaled2("font-size", 10px);
            @include scaled2("line-height", 15px);
            letter-spacing: 0.2px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            color: #686871;
            margin-left: 21px;
            margin-top: -2px;
        }
    }

    &__fromName {
        font-weight: 500;
        @include scaled2("font-size", 12px);
        @include scaled2("line-height", 15px);
        letter-spacing: 0.2px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: #686871;
        margin-left: 2px;
        margin-bottom: 2px;
        width: fit-content;
        float: left;
    }

    &__date {
        @include scaled2("font-size", 12px);
        @include scaled2("line-height", 16px);
        letter-spacing: 0.2px;
        color: #686871;
        margin-top: -18px;
        margin-right: 5px;
        margin-left: auto;
    }
}

.CallPreview {
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e6ef;
    box-shadow: 0px 30px 60px rgba(92, 99, 105, 0.15);
    border-radius: 6px;
    background: #ffffff;
    margin-bottom: 15px;
    padding-bottom: 5px;

    &__header {
        padding: 15px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e0e6ef;
        border-radius: 10px 10px 0px 0px;

        &Orange {
            background-color: #fffaf8;
        }

        &Green {
            background-color: #f7fffe;
        }

        &Blue {
            background-color: #f9fcff;
        }

        &Purple {
            background-color: #fbfbff;
        }

        &Right {
            display: flex;
            align-items: center;
            width: 35%;
            justify-content: space-between;
        }
    }

    &__subjectContainer {
        display: flex;
        align-items: center;
        max-width: 50%;
    }

    &__date {
        @include scaled2("font-size", 14px);
        @include scaled2("line-height", 17px);
        color: #686871;
    }

    &__subject {
        @include scaled2("font-size", 18px);
        @include scaled2("line-height", 22px);
        font-weight: 600;
        margin-left: 10px;
        width: 100%;
        color: #304156;
    }

    &__callIcon {
        @include scaled2("height", 34px);
        @include scaled2("width", 34px);
    }

    &__transcriptVideoPlayerContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: calc(100% - 70px);
    }

    &__videoContainer {
        flex-grow: 1;
        height: 40%;
        border: 1px solid #e1e8ff;
        background-color: #f8f9ff;
        display: flex;
        justify-content: center;
        z-index: 2;
        position: relative;
        min-height: 150px;

        &__fullScreen {
            position: absolute;
            cursor: pointer;
            bottom: 20px;
            right: 20px;
            height: 25px;
            width: 25px;

            &:hover {
                transform: scale(1.1);
            }
        }
    }

    &__videoContainerFullscreen {
        height: calc(100vh - 70px);
        max-height: calc(100vh - 70px);
    }

    &__TranscriptContainerWithVideo {
        flex-grow: 1;
        height: 60%;
        min-height: 100px;
    }

    &__TranscriptContainer {
        height: calc(100% - 80px);
        height: 60%;
        min-height: 100px;
        flex-grow: 1;
    }

    &__TranscriptScrollContainer {
        height: 100%;
        overflow-y: auto;
    }

    &__loadingDiv {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    &__emptyContainer {
        padding: 0px 20px;
    }

    &__summaryContainer {
        display: flex;
        flex-direction: column;
        padding-bottom: 12px;

        &__top {
            display: flex;
        }

        &__bottom {
            display: flex;
            flex-direction: column;
            padding: 20px 0px;
        }

        &__rowItem {
            width: 50%;
            border-bottom: 1px solid #e0e6ef;
            padding: 10px 20px;

            &First {
                border-right: 1px solid #e0e6ef;
            }
        }

        &__rep {
            &Details {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            &Name {
                font-weight: 600;
                @include scaled2("font-size", 14px);
                @include scaled2("line-height", 19px);
                color: #2d3958;
            }

            &Label {
                font-weight: 500;
                @include scaled2("font-size", 12px);
                @include scaled2("line-height", 15px);
                color: #8798ad;
            }
        }

        &__clientTlRatio {
            &Div {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 5px;
            }

            &Additionals {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #1E66BC;
                cursor: pointer;
            }

            &Value {
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                @include scaled2("padding", 5px);
                background: #f0f3ff;
                border-radius: 3px;
                margin-right: 5px;
            }

            &Text {
                @include scaled2("font-size", 12px);
                @include scaled2("line-height", 15px);
                text-align: center;
                font-weight: 600;
                color: #f27844;
            }

            &Line {
                @include scaled2("height", 7px);
                background: #f27844;
                border-radius: 1px;
                position: absolute;
                top: 0px;
                left: 0px;
                z-index: 3;

                &Background {
                    position: relative;
                    @include scaled2("height", 7px);
                    background: #e5e5e5;
                    width: 100%;
                    border-radius: 1px;
                    z-index: 2;
                }
            }

            &ContactDetails {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            &ContactContainer {
                display: flex;
                flex-direction: column;
            }

            &ContactName {
                font-weight: 600;
                @include scaled2("font-size", 14px);
                @include scaled2("line-height", 18px);
                color: #2d3958;
                text-transform: capitalize;
            }

            &JobTitle {
                @include scaled2("font-size", 12px);
                @include scaled2("line-height", 15px);
                width: 130px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #8798ad;
            }
        }

        &__header {
            font-weight: 600;
            @include scaled2("font-size", 14px);
            @include scaled2("line-height", 17px);
            letter-spacing: 0.7px;
            color: #828d99;
            @include scaled2("margin-bottom", 12px);

            &Caps {
                padding: 0px 20px;
                text-transform: uppercase;
            }
        }

        &__contactIcon {
            @include scaled2("height", 34px);
            @include scaled2("width", 34px);
            margin-right: 5px;
        }

        &__section {
            margin-top: 4px;
            margin-bottom: 8px;

            &Row {
                display: flex;
                align-items: center;
                flex-direction: row;
            }

            &Row>* {
                flex: 1 1 0px;
            }

            &Stat {
                display: flex;
                flex-direction: column;
                padding: 0px 2px;
            }

            &Header {
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                width: 100%;
                background-color: #fafbfb;
                padding: 5px 20px;

                &Icon {
                    @include scaled2("height", 20px);
                    @include scaled2("width", 20px);
                    margin-right: 0px;
                }

                &Text {
                    font-weight: 600;
                    @include scaled2("font-size", 15px);
                    @include scaled2("line-height", 19px);
                    color: #2d3958;
                    margin-left: 2px;
                }
            }

            &List {
                padding-top: 10px;
                height: max-content !important;
            }

            &PaddedList {
                padding: 10px 20px;
                height: max-content !important;
            }
        }
    }
}

.EmailPreview {
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 1px solid #e0e6ef;
    box-shadow: 0px 30px 60px rgba(92, 99, 105, 0.15);
    border-radius: 6px;

    &__header {
        padding: 15px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e0e6ef;

        &Orange {
            background-color: #fffaf8;
        }

        &Purple {
            background-color: #fbfbff;
        }
    }

    &__fromName {
        @include scaled2("font-size", 14px);
        @include scaled2("line-height", 22px);
        letter-spacing: 0.233333px;
        color: #25252b;
        border-bottom: 1px solid #e1e6ed;
    }

    &__morePeople {
        font-weight: 500;
        @include scaled2("font-size", 13px);
        @include scaled2("line-height", 19px);
        letter-spacing: 0.233333px;
        color: #25252b;
        margin-left: 5px;
        cursor: pointer;
    }

    &__showQuotedTextDiv {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 29px;
        background: #ffffff;
        border: 1px solid #2680EB;
        box-sizing: border-box;
        border-radius: 90px;
        width: 200px;
        margin: 10px auto;
        margin-top: auto;
        position: relative;

        &::after {
            content: "";
            height: 0.5px;
            background-color: #d8d8d8;
            position: absolute;
            right: -101px;
            width: 100px;
        }

        &::before {
            content: "";
            height: 0.5px;
            background-color: #d8d8d8;
            position: absolute;
            left: -101px;
            width: 100px;
        }
    }

    &__showQuotedText {
        display: flex;
        font-weight: normal;
        font-size: 12px;
        line-height: 22px;
        color: #2680EB;
        text-align: center;
    }

    &__repIcon {
        margin-right: 5px;
    }

    &__fromIcon {
        @include scaled2("height", 34px);
        @include scaled2("width", 34px);
        margin-right: 5px;
    }

    &__toLabel {
        @include scaled2("font-size", 14px);
        @include scaled2("line-height", 19px);
        letter-spacing: 0.233333px;
        color: #686871;
        font-weight: 600;
        margin: 0px 5px;
    }

    &__names {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__namesContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 5px;
        border-bottom: 1px solid #e0e6ef;
        width: 95%;
        margin-left: 2.5%;
    }

    &__date {
        @include scaled2("font-size", 14px);
        @include scaled2("line-height", 17px);
        color: #686871;
        margin-right: 10px;
    }

    &__subjectContainer {
        color: #25252b;
        display: flex;
        align-items: center;
    }

    &__mailIcon {
        @include scaled2("height", 34px);
        @include scaled2("width", 34px);
    }

    &__subject {
        @include scaled2("font-size", 18px);
        @include scaled2("line-height", 22px);
        font-weight: 600;
        margin-left: 10px;
        max-width: 400px;

        &Orange {
            color: #ff6c2d;
        }

        &Purple {
            color: #2680EB;
        }
    }

    &__body {
        @include scaled2("font-size", 14px);
        @include scaled2("line-height", 25px);
        color: #686871;
        white-space: pre-wrap;
        padding: 20px 20px 10px 20px;
        overflow: auto;
    }
}