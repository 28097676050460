.Comment {
  position: relative;

  &__fullContainer {
    display: flex;
    flex-direction: column;
    height: calc(100% - 40px);
  }

  &__outerContainer {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: calc(100%);
    padding-bottom: 70px;
  }

  &__Container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  &__higlightlink {
    border-bottom: 1px solid #2680EB;
    color: #2680EB !important;
    position: relative;
    z-index: 1;
  }

  &__textStyle {
    line-height: 20px;
    color: #6C6C6C;
    font-weight: 400;
    font-size: 14px;
  }

  &__footer {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-bottom: 5px;

    .actions {
      display: flex;
      margin-left: auto;
      margin-right: 5px;
    }

    &Button {
      display: flex;
      align-items: flex-start;
      cursor: pointer;
      margin: 0px 10px;
      height: 12px !important;

      .icon {
        height: 10px;
        width: 10px;
      }

      .label {
        font-size: 10px;
        line-height: 12px;
        margin-left: 3px;
      }
    }
  }

  &__mentionInput {
    &__input {
      color: #000000 !important;
      font-size: 13px !important;
      align-items: center;
      line-height: 20px;
      margin: -3px 0px !important;
    }

    &__control {
      width: 100% !important;
    }

    &__highlighter {
      line-height: 20px;
      color: #3e3e3e !important;
    }
  }

  &__date {
    @include scaled("font-size", 10px);
    @include scaled("line-height", 12px);
    text-align: right;
    margin-top: 5px;
    margin-right: 5px;
    color: #828d99;
    margin-left: auto;
  }

  &__lockIcon {
    @include scaled("height", 14px);
    @include scaled("width", 14px);
    margin: 0px 5px;
  }

  &__TextContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .row {
      display: flex;
      align-items: flex-end;
      width: 100%;
      margin: 5px 0px;
    }
  }

  &:last-child {
    border-bottom: 0;
  }

  &__body {
    cursor: pointer;
  }

  &__text,
  &__textHighlight {
    color: #000000;
    position: relative;
    font-size: 13px;
    line-height: 15px;
    width: 100%;

    &::before {
      content: "I";
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      height: 100%;
      color: white;
      font-weight: bold;
      position: absolute;
      left: calc(-23px);
      top: calc(2px);
    }
  }

  &__textHighlight {
    &::before {
      border-left: 1px solid #2e5bff;
    }

    &::after {
      border-left: 1px solid #2e5bff;
    }
  }

  &__commenter {
    color: #000000;
    @include scaled("font-size", 14px);
    @include scaled("line-height", 15px);
    font-weight: 500;
    margin-top: 10px;
  }

  &__iconContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 10px 10px 5px 5px;
  }

  &__icon {
    border: 1px solid #3150d2;
    @include scaled("height", 25px);
    @include scaled("width", 25px);
    font-size: 13px;
    color: white;
    justify-content: center;
    align-items: center;
    font-weight: 450;
    border-radius: 12.5px;
    display: inline-flex;
    background-color: #3150d2;
  }

  &__time {
    margin-right: 10px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    font-weight: normal;
    @include scaled("font-size", 12px);
    @include scaled("line-height", 15px);
    color: #117dce;
    vertical-align: middle;
    cursor: pointer;

    &Icon {
      @include scaled("height", 15px);
      @include scaled("width", 15px);
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    padding-top: 5px;
  }

  &__addCommentArea {
    transition: all 500ms;
    width: 100%;
    border-bottom: 1px solid #e4e8f0;
    animation-name: showInStyle;
    animation-duration: 1s;
    display: flex;
    flex-direction: column;
  }

  &__expandedSection {
    padding: 0px;
    display: flex;
    align-items: center;

    .time {
      color: #2680EB;
      font-style: italic;
      @include scaled("font-size", 12px);
      @include scaled("line-height", 22px);
    }
  }
}

.AddComment {
  &__control {
    padding: 5px;
    width: 100% !important;
    min-height: 100px;
  }

  &__postIcon {
    @include scaled("height", 20px);
    @include scaled("width", 20px);
    cursor: pointer;
  }

  &__inputContainer {
    width: 100%;
    padding: 2px;
  }

  &__lock {
    cursor: pointer;
    margin-left: 5px;
    display: flex;

    &Icon {
      @include scaled("height", 24px);
      @include scaled("width", 24px);
    }
  }

  &__helperText {
    @include scaled2("font-size", 12px);
    @include scaled2("line-height", 15px);
    color: #6B7280;
    margin: 0px 4px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border: 1px solid #e1e5e9;
    box-sizing: border-box;
    box-shadow: 0px 6px 25px rgba(45, 91, 255, 0.05);
    border-radius: 10px;
    margin-top: 10px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #f9faff;
    border: 1px solid #e1e5e9;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px;
    width: 100%;

    &Right {
      display: flex;
      margin-left: auto;
      align-items: center;
    }
  }

  &__linkIdDiv {
    @include scaled("height", 34px);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 10px;
    background: #f9faff;
    border-right: 1px solid #e1e5e9;
    cursor: pointer;
    box-sizing: border-box;
    max-width: calc(100% - 140px);

    &Text {
      font-weight: 600;
      @include scaled("font-size", 12px);
      @include scaled("line-height", 15px);
      color: #2680EB;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &Icon {
      @include scaled("height", 20px);
      @include scaled("width", 20px);
      margin-left: -7px;
    }
  }

  &__input {
    margin: 5px 6px !important;
    height: calc(100% - 5px) !important;
    overflow-y: auto !important;
    width: calc(100% - 12px) !important;
  }

  &__highlighter {
    margin: 0px 0px 0px 1px !important;
  }

  &__highlight {
    color: $primary;
    border-bottom: 1px solid $primary;
    position: relative;
    z-index: 1;
  }

  &Placeholder {
    display: flex;
    align-items: center;
    margin: 15px;
    height: 46px;
    border: 1px solid #e0e7ff;
    border-radius: 6px;
    background-color: rgba(224, 231, 255, 0.2);
    cursor: pointer;

    .text {
      color: #aeb3be;
      font-size: 13px;
      margin-left: 10px;
    }
  }
}

.UpdateComment {
  &__control {
    height: auto !important;
  }

  &__input {
    color: #000000 !important;
    font-size: 13px !important;
    align-items: center;
    line-height: 20px;
    height: auto;
  }

  &__highlighter {
    margin: 0px 0px 0px 0px !important;
    line-height: 20px;
    font-size: 13px !important;
  }
}

.NoCommentsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px 0px;

  .icon {
    margin: 15px 0px;
    height: 150px;
    width: 150px;
  }

  .title {
    color: #4a4a4a;
    @include scaled("font-size", 18px);
    @include scaled("line-height", 22px);
    font-weight: bold;
    text-align: center;
    @include scaled("margin-bottom", 10px);
  }

  .copy {
    text-align: center;
    color: #6b6b6b;
    @include scaled("font-size", 13px);
    @include scaled("line-height", 15px);
    margin: 0px 10px;
  }
}

@keyframes showInStyle {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}