.wingman {
  &__header {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
  }

  &__floatingContainer {
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .teamFilter {
      margin-right: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .select {
        width: 200px;
        margin: 0px 10px;
      }
    }
  }

  &__appNameContainer {
    margin: 30px 0px;
    color: #868686;
    flex-grow: 1;

    &__text {
      font-size: 22px;
      font-weight: 300;
    }

    &__boldText {
      font-size: 32px;
      font-weight: bold;
      margin: 0px 2px;
    }
  }

  &__category {
    &Name {
      color: #1E66BC;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 1.2px;
      line-height: 22px;
      text-transform: uppercase;
    }

    &Container {
      margin: 15px 0px;
      padding: 20px;
    }

    &Header {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 70px;
      margin-left: 10px;
      padding-bottom: 10px;

      .SelectUsers {
        display: flex;
        height: 100%;
        width: 230px;
        margin-left: auto;
        justify-content: space-evenly;
        flex-direction: column;
        margin-right: 30px;
      }
    }
  }

  &__battlecardGrid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &-sampleCardDiv {
      display: flex;
      flex-direction: row;
    }

    &-copyDiv {
      display: flex;
      flex-direction: column;
      padding: 20px 0px;
    }

    &-heading {
      font-size: 20px;
    }

    &-copy {
      font-size: 16px;
    }
  }

  &__downloadModal {
    display: flex;
    align-items: center;
    justify-content: center;

    &-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 660px;
      height: 150px;
      overflow-y: auto;
      border-radius: 5px;
      background-color: #ffffff;
      box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5),
        -10px 9px 21px 0 rgba(128, 152, 213, 0.19);
    }

    &-bigText {
      font-size: 16px;
      color: black;
      font-weight: 600;
    }

    &-text {
      font-size: 12px;
      color: gray;
      font-weight: 400;
      margin-bottom: 10px;
    }
  }
}

.BattleCard {
  &__container {
    border-radius: 3px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-width: calc(250px);
    max-width: calc(250px);
    transition: margin-left 200ms;
    margin: 12px;
    border: 1px solid #E5F0FD;
  }

  &__header {
    height: 60px; // TODO: use proper height which would be fit-content but transition wouldn't work
    padding: 0px 3px;
    color: #1E66BC;
    align-items: center;
    display: flex;
    flex-direction: row;
    transition: height 500ms;

    &-hover {
      display: none;
    }

    &:hover {
      height: 100px;

      .BattleCard__header-draft-tag {
        display: none;
      }

      .BattleCard__header-hover {
        display: flex;
      }

      .BattleCard__header-nohover {
        display: none;
      }

      .BattleCard__header_menu {
        display: inline;
      }

      .BattleCard__header-info {
        color: #6B7280;
      }
    }

    &-published {
      background-color: #F3F4F6;
      color: #1E66BC;
    }

    &_menu {
      display: none;
    }

    &-draft {
      background-color: #f3f3f3;
      color: #050630;
      border-bottom: 0px solid white;

      &-tag {
        width: 0px !important;

        &::before {
          content: ".";
          position: absolute;
          vertical-align: baseline;
          height: 15px;
          width: 60px;
          background-color: #2680EB;
          transform: rotate(45deg);
          top: 4px;
          right: -14px;
        }

        &::after {
          content: "DRAFT";
          letter-spacing: 1px;
          color: white;
          transform: rotate(45deg);
          font-size: 9px;
          font-weight: bold;
          position: absolute;
          top: 9px;
          right: -1px;
          z-index: 3;
        }
      }
    }

    &-copy {
      margin: 2px;
      margin-left: 7px;
      font-size: 10px;
      font-weight: 400;
      color: #6B7280;
    }

    &-info {
      margin: 4px;
      font-size: 10px;
      font-weight: 400;
      margin-left: 7px;
    }
  }

  &__menuButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 15px 5px;
    height: 20px !important;

    .icon {
      height: 14px;
      width: 14px;
      margin-right: 7px;
    }

    .label {
      font-size: 14px;
      line-height: 14px;
    }
  }

  &__header-title {
    flex-grow: 1;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    margin: 1px 1px 1px 2px;

    &-text {
      margin-top: 5px;
      margin-left: 5px;
      margin-bottom: 2px;
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      text-transform: capitalize;
    }
  }

  &__header-close {
    font-size: 20;
    cursor: pointer;
    -webkit-app-region: no-drag;
  }

  &__body {
    color: black;
    height: calc(240px);
    background-color: white;
    overflow-y: auto;
    overflow-x: hidden;
    border-top: 1px solid #E5F0FD;
  }
}

.BulletPoint__even {
  background-color: #fff;
  padding: 10px 10px;
}

.BulletPoint__odd {
  background-color: #fff;
  padding: 10px 10px;
}

.BulletPoint__text {
  color: #424141;
  font-size: 12px;
}

.battlecardForm {
  &__outerContainer {
    right: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0px white solid;
  }

  &__container {
    position: relative;
    padding: 15px;
    width: 34rem;
    height: 37rem;
    overflow-y: none;
    border-radius: 5px;
    border: 0px white solid;
    background-color: #ffffff;

    &:focus {
      outline: none !important;
    }
  }

  &__header {
    &__container {
      margin: 5px 0px;
    }

    &__boldText {
      color: #543873;
      font-size: 16px;
      font-weight: bold;
    }

    &__text {
      color: #543873;
      font-size: 16px;
    }

    &__cancelContainer {
      display: inline;
    }
  }

  &__label {
    color: #7f8fa4;
    margin: 10px 2px;
    font-size: 14px;
    font-weight: 500;
  }

  &__helper {
    color: #b4b4b4;
    font-size: 10px;
    margin: 5px 0px;
  }

  &__categoryAndTriggerContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }

  &__triggerContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-right: 10px;

    &__triggerInput {}
  }

  &__tpContainer {
    display: flex;
    flex-direction: row;

    &__inputRow {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__input {
      display: flex;
      flex-grow: 1;
      flex-direction: column;

      &__warningContainer {
        display: flex;
        float: right;
      }
    }
  }

  &__tpInputContainer {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    width: 100%;

    &__triggerInput {}
  }

  &__tpAddContainer {
    width: 25%;
    display: flex;
    align-items: center;
  }

  &__category {
    &__newCategory {
      display: flex;
      background-color: white;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-radius: 7px;
      padding: 5px;
    }

    &__container {
      display: flex;
      position: relative;
      flex-direction: column;
      width: 50%;
    }

    &__menu {
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      z-index: 2;
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
        0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    }

    &__menuLabel {
      width: 100%;
    }

    &__menuItem {
      display: flex;
      padding: 15px;
      cursor: pointer;

      &:hover {
        background-color: #efefef;
      }
    }

    &__menuItemSelected {
      display: flex;
      padding: 15px;
      cursor: pointer;
      background-color: #dbdbdb;
    }
  }

  &__tpBox {
    width: 100%;
    background-color: #ffffff;
    margin: 5px 0px;
    padding: 10px 0px;
    overflow-y: auto;
    display: flex;
    align-items: center;

    .RichTextEditor {
      width: 100%;
      height: 50%;
    }
  }

  &__footerContainer {
    position: absolute;
    bottom: 0;
    width: calc(100% - 30px);
    margin: 5px 0px 20px 0px;
    display: flex;

    .leftButton {
      justify-content: flex-start;
    }

    .rightButton {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-left: auto;
    }
  }

  &__tp {
    display: flex;
    padding: 7px 10px;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    transition: height 200ms;

    &__bulletPoint {
      margin-right: 10px;
      height: 10px !important;
      width: 10px !important;
    }

    &__text {
      color: #8b8a8a;
      font-size: 12px;
      font-weight: 500;
      flex-grow: 1;
      margin: 0px 5px;
    }

    &__controls {
      display: flex;
      justify-self: flex-end;
    }
  }

  &__inputContainer {
    height: 36px;
    border: 1px solid #dfe3e9;
    border-radius: 4px;
    padding-left: 10px;
    margin-left: 12px;
  }

  &__errorInputContainer {
    height: 36px;
    border: 1px solid #ee4266;
    border-radius: 4px;
    padding-left: 10px;
  }

  &__input {
    width: 90%;
  }

  &__warning {
    display: inline-block;
    width: 100px;
    height: 13px;
    text-align: right;
    flex-grow: 1;
    color: #ee4266;
    font-size: 10px;

    &-icon {
      height: 9px;
      width: 9px;
    }
  }
}

.TagFilled {
  &__textField {
    margin-left: 5px;
    border-bottom: 1px solid lightgray;
  }
}