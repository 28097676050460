.dashboard {
  @include size(100%);
  display: flex;
  background: #f4f6fc;

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    // width: 100vw;
  }

  &__contentFull {
    flex: auto;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    width: 100vw;
    height: 100vh;
  }

  &__stacked {
    margin-left: 0px;
  }

  &__withPlayer {
    margin-bottom: #{$player_height};
  }

  &__content-inner {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    padding: 1rem 0rem;
    overflow: auto;

    // padding-bottom: 90px;

    &_header {
      @include pageHeader();
      display: flex;
      align-items: center;
      min-height: 50px;
    }
  }

  &__content-inner-stacked {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    // width: calc(100%);
    // padding: 1rem 2rem;
    overflow: auto;
  }

  &__content-inner-white {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background-color: white;

    overflow: auto;
  }

  &__content-inner-full {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: calc(100%);
    padding: 0px !important;
    overflow: auto;

    &_header {
      @include pageHeader();
      display: flex;
      align-items: center;
      min-height: 50px;
    }
  }
}